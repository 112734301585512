// 埋点事件类型
export const EVENT_TYPE = {
  // 主页曝光
  HOME_PAGE_EXPOSURE: 'imp_home_page',
  // 主页曝光（有nf参数的时候）
  HOME_PAGE_EXPOSURE_NF: 'nf_imp_enter_page',
  // 主页信息流接口耗时（有nf参数的时候）
  HOME_NEWS_TIME_NF: 'nf_time',
  // 名站曝光
  // TOP_SITE_EXPOSURE: 'c_imp_top_site',
  // 名站点击
  TOP_SITE_CLICK: 'c_click_top_site',
  // 搜索
  SEARCH: 'c_search',
  // 点击首页搜索框
  SEARCH_CLICK: 'c_click_search',
  // 搜索页面曝光
  // SEARCH_PAGE_EXPOSURE: 'c_imp_search_page',
  // 信息流曝光
  NEWS_ITEM_EXPOSURE: 'c_imp_list_item',
  // 信息流点击
  NEWS_ITEM_CLICK: 'c_click_list_item',
  // 信息流曝光 url中有nf参数
  NF_NEWS_ITEM_EXPOSURE: 'nf_imp_list_item',
  // 信息流点击 url中有nf参数
  NF_NEWS_ITEM_CLICK: 'nf_click_list_item',
  // 网页加载时长
  // LOAD_TIME: 'c_loading_time',
  // 页面停留时长
  DURATION_TIME: 'c_duration_time',
  // 天气入口曝光
  // WEATHER_EXPOSURE: 'c_imp_wea_card',
  // 天气入口点击
  WEATHER_CLICK: 'c_click_wea_card',
  //天气详情页曝光
  // WEATHER_DETAILS_EXPOSURE: 'c_imp_wea_detail',
  //申请地理位置弹窗曝光
  // REGION_EXPORSURE: 'popups_region_imp',
  //申请地理位置成功
  // REGION_SUCCESS: 'region_get_suc',
  //申请地理位置失败
  // REGION_ERROR: 'region_get_err',
  // 首页穿插广告加载成功
  // VIGNETTE_LOAD_SUCCESS: 'vignette_load_success',
  //获取ip时长
  // GET_IP_TIME: 'get_ip_time',
  //搜索sug点击
  SEARCH_SUG_CLICK: 'c_search_sug_click',
  //PSE成功返回搜索结果
  PSE_SEARCH_SUCCESS: 'c_search_success',
  //搜索结果展示
  PSE_SEARCH_RESULT_SHOW: 'c_search_result_show',
  //PSE搜索结果点击
  PSE_SEARCH_RESULT_CLICK: 'c_search_result_click',
  //搜索页的搜索相关推荐曝光
  PSE_SEARCH_RELATED_SHOW: 'c_related_search_show',
  //搜索结果页的底部曝光
  PSE_SEARCH_BOTTOM_SHOW: 'c_search_result_bottom',
  // pwa安装成功
  // PWA_INSTALLED: 'add_pwa_success',
  //退出时打点
  // EXIT_NAV: 'exit_nav',
  //通知权限申请成功
  // NOTIFICATION_GRANTED: 'push_success',
  //点击设置入口
  SETTING_CLICK: 'c_click_setting',
  //设置页面点击
  // SETTING_ITEM_CLICK: 'c_click_setting_page',
  //气泡展示
  // SETTING_TIPS_SHOW: 'c_imp_reminder',
  //点击气泡按钮【知道了】
  // SETTING_TIPS_GET: 'c_click_reminder',
  //点击搜索历史记录
  // CLICK_SEARCH_HISTORY_ITEM: 'c_click_history',
  //cp中间页展示
  CP_MIDDLE_EXPOSURE: 'c_imp_summary',
  //cp中间页里面的点击
  CP_MIDDLE_CLICK: 'c_click_summary',
  AD_SHOW_TIME: 'c_ad_show_time',
  RS_PAGE_SHOW: 'rs_page_show',
  RS_PAGE_CLICK: 'rs_page_click',
  RS_PAGE_ADS_SHOW: 'rs_page_ads_show',
  RS_PAGE_ADS_CLICK: 'rs_page_ads_click',
  RS_PAGE_ALL_CLICK: 'rs_page_all_click',
  RS_PAGE_LOADING_SHOW: 'rs_page_loading_show',
  RS_PAGE_NO_ADS: 'rs_page_noads_show',
  // url参数有c时的搜索页面曝光
  SEARCH_C_PAGE_EXPOSURE: 'c_search_page_show',
  SEARCH_RESULT_CLICK: 'search_result_click',
  // afs外投版 相关打点
  AFS_PAGE_SHOW: 'H5game_load_start',
  AFS_PAGE_LOADED: 'H5game_load_finish',
  AFS_RELATED_ITEM_CLICK: 'keyword_page_click',
  AFS_RESULT_PAGE_FINISH: 'result_page_finish',
  AFS_RESULT_AD_CLICK: 'result_ad_click',
}

// tiktok打点事件与ga打点对应关系
export const TT_EVENT_TYPE = {
  // 搜索广告展示
  SEARCH_ADS_SHOW: 'Search',
  // 搜索广告点击
  SEARCH_ADS_CLICK: 'ClickButton',
  // 搜索广告点击facebook
  SEARCH_ADS_CLICK_FACEBOOK: 'Purchase',
  // 搜索结果点击（包含广告和搜索结果）
  SEARCH_RESULT_CLICK: 'Subscribe',
  // 配置词相关搜索页面
  RS_SEARCH_ADS_CLICK: 'ClickButton',
  RS_SEARCH_ALL_CLICK: 'ViewContent',
}
