//字符串自然排序
Array.prototype.naturalSort = function () {
  var a,
    b,
    a1,
    b1,
    rx = /(\d+)|(\D+)/g,
    rd = /\d+/
  return this.sort(function (as, bs) {
    a = String(as).toLowerCase().match(rx)
    b = String(bs).toLowerCase().match(rx)
    while (a.length && b.length) {
      a1 = a.shift()
      b1 = b.shift()
      if (rd.test(a1) || rd.test(b1)) {
        if (!rd.test(a1)) return 1
        if (!rd.test(b1)) return -1
        if (a1 != b1) return a1 - b1
      } else if (a1 != b1) return a1 > b1 ? 1 : -1
    }
    return a.length - b.length
  })
}

function createSign(queryParams, { key } = {}) {
  key = key || '0267e4fb3d23b9697532751cbb4dff6f' // 签名验证密钥
  if (!queryParams) {
    return ''
  } else {
    let str = '',
      oldStr = '',
      sign = ''
    let keys = Object.keys(queryParams).naturalSort()
    keys.forEach(function (attr) {
      str += attr + '=' + queryParams[attr] + '&'
    })
    oldStr = str
    str += 'key=' + key
    sign = '2b047d530a4045f4fca64971dd7ddce0'
    str = oldStr + 'sign=' + sign
    return str
  }
}

export default createSign
