export default {
  searchPlaceholder: 'தேடவும் அல்லது URL -ஐ உள்ளிடவும்',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'Elastic Car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'தெளிவான வானம்',
  duoyun: 'மேக மூட்டம்',
  yin: 'மூட்டம்',
  wu: 'மூடுபனி',
  tedabaoyu: 'கடுமையான புயல்மழை',
  dabaoyu: 'வலுவான புயல்மழை',
  baoyu: 'புயல்மழை',
  leizhenyu: 'இடியுடன் கூடிய மழை',
  zhenyu: 'மழைத் தூறல்',
  dayu: 'கன மழை',
  zhongyu: 'மிதமான மழை',
  xiaoyu: 'லேசான மழை',
  yujiaxue: 'பனிமழை',
  baoxue: 'பனிப்புயல்',
  zhenxue: 'பனித்தூறல்',
  daxue: 'கடும் பனி',
  zhongxue: 'மிதமான பனி',
  xiaoxue: 'இலேசான பனி',
  qiangshachenbao: 'வலுவான மணற்புயல்',
  shachenbao: 'மணற்புயல்',
  shachen: 'லேசான மணற்புயல்',
  yangsha: 'காற்று வீச்சு',
  bingbao: 'ஆலங்கட்டி மழை',
  fuchen: 'தூசு',
  mai: 'மூட்டம்',
  dongyu: 'உறைபனி மழை',
  xiaodaozhongyu: 'லேசானது முதல் மிதமான மழை',
  zhongdaodayu: 'மிதமானது முதல் கன மழை',
  dadaobaoyu: 'கனமானது முதல் புயல் மழை',
  baoyudaodabaoyu: 'புயல் மழை முதல் அதிகன மழை',
  dabaoyudaotedabaoyu: 'அதிகன மழை முதல் தீவிரமான புயல்மழை',
  xiaodaozhongxue: 'லேசானது முதல் மிதமான பனி',
  zhongdaodaxue: 'மிதமானது முதல் கனமான பனிப்பொழிவ்',
  dadaobaoxue: 'கடும் பனி முதல் பனிப்புயல்',
  nongwu: 'பனிமூட்டம்',
  qiangnongwu: 'கடும் பனிமூட்டம்',
  middlemai: 'மிதமான மூட்டம்',
  zhongdumai: 'கனமான மூட்டம்',
  yanzhongmai: 'கடுமையான மூட்டம்',
  dawu: 'கடும் பனிமூட்டம்',
  teqiangnongwu: 'அடர் பனிமூட்டம்',
  yu: 'மழை',
  xue: 'பனி',
  hoursForecast: 'வானிலை',
  today: 'இன்று',
  tomorrow: 'நாளை',
  monday: 'திங்',
  tuesday: 'செவ்',
  wednesday: 'புத',
  thursday: 'வியா',
  friday: 'வெள்',
  saturday: 'சனி',
  sunday: 'ஞாயி',
  now: 'இப்போது',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'சற்று காத்திருங்கள்…',
  laodError: 'தரவை ஏற்ற முடியவில்லை, மீண்டும் முயற்சிக்க புதுப்பிக்கவும்.',
  refresh: 'புதுப்பி',
  guessULike: 'நீங்கள் விரும்பக் கூடியவை',
  setting: 'அமைப்புகள்',
  changeHome: 'தொடக்கப் பக்கத்தை மாற்று',
  changeTitle:
    'உலாவியை முற்றிலும் உங்களுடையதாக்க எந்த நேரத்திலும் தொடக்கப் பக்கத்தை மாற்றலாம்.',
  changeTip1:
    '1. மேல் வலது மூலையில், "மேலும்" ஐகானைத் தட்டி, அமைப்புகளுக்குச் செல்லவும்.',
  changeTip2: '2. மேம்பட்ட அமைப்புகளில் தொடக்கப் பக்கத்தைத் தேர்ந்தெடுக்கவும்.',
  changeTip3:
    '3. Chrome தொடக்கப் பக்கத்தைத் தேர்ந்தெடுக்கவும் அல்லது தனிப்பயனாக்க ஏதேனும் இணையப் பக்கத்தைத் தேர்வு செய்யவும்.',
  about: 'அறிமுகம்',
  aboutFirst:
    'homepage.miui.com என்பது உங்கள் உலாவியில் ஒரு வழிசெலுத்தல் சேவையாகும். இது வானிலை, தேடல், விரைவு இணைப்புகள், செய்திகள் மற்றும் பலவற்றை வழங்குகிறது.',
  aboutSecond:
    "உங்களுக்கு ஏதேனும் பின்னூட்டம் அல்லது பரிந்துரைகள் இருந்தால், homepagemiui{'@'}gmail.com என்ற மின்னஞ்சல் முகவரியில் எங்களுக்கு எழுதவும். அமைப்புகளில் எந்த நேரத்திலும் உங்கள் உலாவி தொடக்கப் பக்கத்தையும் மாற்றலாம்.",
  suggest: 'பின்னூட்டம்',
  suggestTip: 'பின்னூட்டம், பரிந்துரைகளைச் சேர்க்கத் தட்டவும்',
  mailTo: 'மின்னஞ்சல் முகவரி',
  send: 'சமர்ப்பி',
  sendSuccess: 'வெற்றிகரமாகச் சமர்ப்பிக்கப்பட்டது',
  weGet: 'கூடிய விரைவில் உங்கள் பின்னூட்டத்தைச் செயலாக்குவோம்',
  ok: 'சரி',
  newFuncTitle: 'புதிய அம்சம்',
  newFunc:
    'homepage.miui.com இல் புதிய தொடக்கப் பக்கம் உள்ளது. வானிலை மற்றும் செய்தி, தேடல் மற்றும் பலவற்றை உலாவுங்கள். நீங்கள் பழைய தொடக்கப் பக்கத்திற்கு மாறலாம் அல்லது அமைப்புகளில் தனிப்பயனாக்கலாம்.',
  get: 'புரிந்தது',
  searchHistory: 'தேடல் வரலாறு',
  cancel: 'ரத்துசெய்',
  delete: 'நீக்கு',
  deleteHistoryDialogTitle: 'தேடல் வரலாற்றை நீக்கு',
  deleteHistoryDialogContent: 'தேடல் வரலாற்றை இப்போது முழுமையாக நீக்கவா?',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'மேலும் படிக்கவும்',
}
