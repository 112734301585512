export default {
  searchPlaceholder: 'Search or type web address',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'MSN',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'Clear',
  duoyun: 'Cloudy',
  yin: 'Overcast',
  wu: 'Mist',
  tedabaoyu: 'Severe rainstorm',
  dabaoyu: 'Heavy rainstorm',
  baoyu: 'Rainstorm',
  leizhenyu: 'Thunderstorm',
  zhenyu: 'Showers',
  dayu: 'Heavy rain',
  zhongyu: 'Moderate rain',
  xiaoyu: 'Light rain',
  yujiaxue: 'Sleet',
  baoxue: 'Blizzard',
  zhenxue: 'Snow showers',
  daxue: 'Heavy snow',
  zhongxue: 'Moderate snow',
  xiaoxue: 'Light snow',
  qiangshachenbao: 'Strong sandstorm',
  shachenbao: 'Sandstorm',
  shachen: 'Light sandstorm',
  yangsha: 'Windy',
  bingbao: 'Hail',
  fuchen: 'Dust',
  mai: 'Haze',
  dongyu: 'Freezing rain',
  xiaodaozhongyu: 'Light to moderate rain',
  zhongdaodayu: 'Moderate to heavy rain',
  dadaobaoyu: 'Heavy to torrential rain',
  baoyudaodabaoyu: 'Torrential rain to downpour',
  dabaoyudaotedabaoyu: 'Downpour to extreme rainstorm',
  xiaodaozhongxue: 'Light to moderate snow',
  zhongdaodaxue: 'Moderate to heavy snow',
  dadaobaoxue: 'Heavy snow to blizzard',
  nongwu: 'Fog',
  qiangnongwu: 'Thick fog',
  middlemai: 'Moderate haze',
  zhongdumai: 'Heavy haze',
  yanzhongmai: 'Severe haze',
  dawu: 'Dense fog',
  teqiangnongwu: 'Severe fog',
  yu: 'Rain',
  xue: 'Snow',
  hoursForecast: 'Weather',
  today: 'Today',
  tomorrow: 'Tomorrow',
  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thu',
  friday: 'Fri',
  saturday: 'Sat',
  sunday: 'Sun',
  now: 'Now',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'Just a sec…',
  laodError: "Couldn't load data. Refresh to try again.",
  refresh: 'Refresh',
  guessULike: 'You might like',
  setting: 'Settings',
  changeHome: 'Change Start page',
  changeTitle:
    'Change your Start page any time to make your browser all yours.',
  changeTip1:
    "1. In the top right corner, tap the 'More' icon and go to Settings.",
  changeTip2: '2. Select Start page in Advanced settings.',
  changeTip3:
    '3. Select the Chrome Start page, or choose any web page to customize it.',
  about: 'About',
  aboutFirst:
    'homepage.miui.com is a navigation service in your browser. It provides weather, search, quick links, news, and more.',
  aboutSecond:
    "If you have any feedback or suggestions, feel free to drop us a line at homepagemiui{'@'}gmail.com You can also change your browser Start page at any time in Settings.",
  suggest: 'Feedback',
  suggestTip: 'Tap to add feedback and suggestions',
  mailTo: 'Email address',
  send: 'Submit',
  sendSuccess: 'Submitted successfully',
  weGet: "We'll process your feedback as soon as possible",
  ok: 'OK',
  newFuncTitle: 'New feature',
  newFunc:
    'homepage.miui.com has a brand new Start page. Browse weather and news, search, and more. You can switch back to the old Start page or personalize it in Settings.',
  get: 'Got it',
  searchHistory: 'Search history',
  cancel: 'Cancel',
  delete: 'Delete',
  deleteHistoryDialogTitle: 'Delete search history',
  deleteHistoryDialogContent: 'Permanently delete search history now?',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'Read more',
}
