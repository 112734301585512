export function supportWebp() {
  const inBrowser = typeof window !== 'undefined' && window !== null
  if (!inBrowser) return false
  let support = true
  try {
    const elem = document.createElement('canvas')
    if (elem.getContext && elem.getContext('2d')) {
      support = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
    }
  } catch (err) {
    support = false
  }
  return support
}

// const isPng = (url) => url && url.indexOf('.png') > -1
// const isJpg = (url) => url && url.indexOf('.jpg') > -1
const isFDSImg = (url) => url && url.indexOf('.fds.') > -1

export function addWebpSuffix(url) {
  if (!supportWebp()) {
    return url
  }

  //   const currentHost = window.location.host

  //   if (typeof url === 'object') {
  //     const { href, protocol } = url || {}
  //     // base64
  //     if (href.indexOf(';base64,') > -1 && protocol === 'data:') {
  //       return url
  //     }
  //     if ((isPng(href) || isJpg(href)) && url.includes(currentHost)) {
  //       return new URL(`${href}.webp`)
  //     }
  //   }
  if (typeof url === 'string') {
    if (url.indexOf(';base64,') > -1) {
      return url
    }
    if (isFDSImg(url)) {
      return `${url}?f=webp&q=30`
    }
    // if ((isPng(url) || isJpg(url)) && url.includes(currentHost)) {
    //   return `${url}.webp`
    // }
  }
  return url
}
