import { createRouter, createWebHistory } from 'vue-router'
// import { homeType } from '@/consts/abTest'
// const HomeNews = () => import('../views/home/index.vue')
// const HomeVideos = () => import('../views/home/index-video.vue')
// const Search = () => import('../views/search/index.vue')
// const SearchConfig = () => import('../views/searchConfig/index.vue')
const SearchIndex = () => import('../views/searchRelated/index.vue')
const SearchResult = () => import('../views/searchRelated/result.vue')
const SearchRelatedPolicy = () => import('../views/searchRelated/policy.vue')
// const SearchConfigAds = () => import('../views/searchConfig/adsResult.vue')
// const WeatherDetail = () => import('../views/weatherDetail/index.vue')
// const WeatherCard = () => import('../views/weatherCard/index.vue')
// const Game = () => import('../views/game/index.vue')
// const News = () => import('../views/news/index.vue')
// const Setting = () => import('../views/setting/index.vue')
// const SettingHome = () => import('../views/setting/home.vue')
// const SettingAbout = () => import('../views/setting/about.vue')
// const SettingFeedback = () => import('../views/setting/feedback.vue')
// const Videos = () => import('../views/videos/index.vue')
// const NewsMiddle = () => import('../views/news/middle.vue')
const Article = () => import('../views/article/index.vue')
const ArticleRelated = () => import('../views/article/related.vue')
const ArticleResult = () => import('../views/article/result.vue')

const router = createRouter({
  history: createWebHistory(),
  routes: [
    // {
    //   path: '/',
    //   name: 'home',
    //   // component: homeType === 'news' || checkRegion.isRU() ? HomeNews : HomeVideos,
    //   component: HomeNews,
    // },
    // {
    //   path: '/s',
    //   name: 'search',
    //   component: Search,
    // },
    /*
    {
      path: '/game',
      name: 'game',
      component: Game,
    },
    {
      path: '/news',
      name: 'news',
      component: News,
    },
    */
    // {
    //   path: '/newsmiddle',
    //   name: 'newsmiddle',
    //   component: NewsMiddle,
    // },
    // {
    //   path: '/videos',
    //   name: 'videos',
    //   component: Videos,
    // },
    // {
    //   path: '/weather',
    //   name: 'weather',
    //   component: WeatherDetail,
    //   props: true,
    // },
    // {
    //   path: '/weathercard',
    //   name: 'weatherCard',
    //   component: WeatherCard,
    //   props: true,
    // },
    // {
    //   path: '/setting',
    //   name: 'setting',
    //   component: Setting,
    //   props: true,
    // },
    // {
    //   path: '/setting/homepage',
    //   name: 'settingHome',
    //   component: SettingHome,
    //   props: true,
    // },
    // {
    //   path: '/setting/about',
    //   name: 'settingAbout',
    //   component: SettingAbout,
    //   props: true,
    // },
    // {
    //   path: '/setting/feedback',
    //   name: 'settingFeedback',
    //   component: SettingFeedback,
    //   props: true,
    // },
    {
      path: '/searchIndex',
      name: 'searchIndex',
      component: SearchIndex,
    },
    {
      path: '/config/rs',
      name: 'searchResult',
      component: SearchResult,
    },
    {
      path: '/config/rs/policy',
      name: 'searchRelatedPolicy',
      component: SearchRelatedPolicy,
    },
    // {
    //   path: '/config/s', // 暂时弃用
    //   name: 'searchConfig',
    //   component: SearchConfig,
    // },
    // {
    //   path: '/config/ads', // 暂时弃用
    //   name: 'searchConfigAds',
    //   component: SearchConfigAds,
    // },
    {
      path: '/',
      name: 'article',
      component: Article,
    },
    // {
    //   path: '/article',
    //   name: 'article',
    //   component: Article,
    // },
    {
      path: '/article/related',
      name: 'articleRelated',
      component: ArticleRelated,
    },
    {
      path: '/article/result',
      name: 'articleResult',
      component: ArticleResult,
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      redirect: '/',
    },
  ],
})

export default router
