export default {
  searchPlaceholder: 'ابحث أو ادخل رابط',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'MSN',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'صافٍ',
  duoyun: 'غائم',
  yin: 'غائم جزئيا',
  wu: 'ضباب',
  tedabaoyu: 'عاصفة مطرية شديدة',
  dabaoyu: 'عاصفة ممطرة',
  baoyu: 'عاصفة مطرية',
  leizhenyu: 'عاصفة رعدية',
  zhenyu: 'أمطار خفيفة',
  dayu: 'أمطار غزيرة',
  zhongyu: 'أمطار متوسطة',
  xiaoyu: 'أمطار خفيفة',
  yujiaxue: 'مطر متجمد',
  baoxue: 'عاصفة ثلجية عنيفة',
  zhenxue: 'ثلوج خفيفة',
  daxue: 'ثلوج كثيفة',
  zhongxue: 'ثلوج متوسطة',
  xiaoxue: 'ثلوج خفيفة',
  qiangshachenbao: 'عاصفة رملية قوية',
  shachenbao: 'عاصفة رملية',
  shachen: 'عاصفة رملية خفيفة',
  yangsha: 'رياح عاصفة',
  bingbao: 'حبات برد',
  fuchen: 'غبار',
  mai: 'ضباب',
  dongyu: 'أمطار متجمدة',
  xiaodaozhongyu: 'أمطار خفيفة إلى معتدلة',
  zhongdaodayu: 'أمطار معتدلة إلى غزيرة',
  dadaobaoyu: 'أمطار كثيفة إلى غريزة',
  baoyudaodabaoyu: 'أمطار غزيرة إلى كثيفة',
  dabaoyudaotedabaoyu: 'مطر غزير إلى عاصفة مطرية شديدة',
  xiaodaozhongxue: 'ثلوج خفيفة إلى معتدلة',
  zhongdaodaxue: 'ثلوج معتدلة إلى غزيرة',
  dadaobaoxue: 'ثلوج كثيفة إلى عاصفة ثلجية',
  nongwu: 'ضباب',
  qiangnongwu: 'ضباب كثيف',
  middlemai: 'ضباب معتدل',
  zhongdumai: 'ضباب كثيف',
  yanzhongmai: 'ضباب شديد',
  dawu: 'ضباب كثيف',
  teqiangnongwu: 'ضباب شديد جدا',
  yu: 'مطر',
  xue: 'ثلج',
  hoursForecast: 'الطقس',
  today: 'اليوم',
  tomorrow: 'غدًا',
  monday: 'الإثنين',
  tuesday: 'الثلاثاء',
  wednesday: 'الأربعاء',
  thursday: 'الخميس',
  friday: 'الجمعة',
  saturday: 'السبت',
  sunday: 'الأحد',
  now: 'الآن',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'لحظة من فضلك…',
  laodError: 'تعذر تحميل البيانات، قم بالتحديث للمحاولة مجددا.',
  refresh: 'تحديث',
  guessULike: 'قد يعجبك أيضا',
  setting: 'الإعدادات',
  changeHome: 'تغيير صفحة البداية',
  changeTitle: 'غيّر صفحة البداية في أي وقت لجعل متصفحك لك لوحدك.',
  changeTip1:
    '1. في الزاوية العليا اليمنى، انقر على رمز "المزيد" وانتقل إلى الإعدادات.',
  changeTip2: '2. حدد صفحة البداية من الإعدادات المتقدمة.',
  changeTip3: '3. حدد صفحة البداية لـ Chrome، أو اختر أي صفحة ويب لتخصيصها.',
  about: 'حول',
  aboutFirst:
    'homepage.miui.com هي خدمة ملاحة في متصفحك. حيث تزودك بأحوال الطقس وتوفر لك ميزة البحث والروابط السريعة والأخبار والمزيد.',
  aboutSecond:
    "إذا كان لديك أي ملاحظات أو اقتراحات، فلا تتردد في مراسلتنا على homepagemiui{'@'}gmail.com. يمكنك أيضًا تغيير صفحة بداية المتصفح في أي وقت من الإعدادات.",
  suggest: 'التعليقات',
  suggestTip: 'انقر لإبلاغ عن خلل أو إضافة اقتراح',
  mailTo: 'عنوان البريد الإلكتروني',
  send: 'ارسال',
  sendSuccess: 'تم الارسال بنجاح',
  weGet: 'سنعالج طلبك في أسرع وقت',
  ok: 'موافق',
  newFuncTitle: 'ميزة جديدة',
  newFunc:
    'يحتوي موقع homepage.miui.com على صفحة بداية جديدة تمامًا. حيث يمكنك من خلالها تصفح أحوال الطقس والأخبار والبحث والمزيد. يمكنك الرجوع إلى صفحة البداية القديمة أو تخصيصها من الإعدادات.',
  get: 'حسنا',
  searchHistory: 'سجل البحث',
  cancel: 'إلغاء',
  delete: 'حذف',
  deleteHistoryDialogTitle: 'حذف سجل البحث',
  deleteHistoryDialogContent: 'هل ترغب في حذف سجل البخث نهائيا؟',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'قراءة المزيد',
}
