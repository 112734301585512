export default {
  searchPlaceholder: 'অনুসন্ধান কৰক বা URL প্ৰৱিষ্ট কৰক',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'Elastic Car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'ফৰকাল',
  duoyun: 'ডাৱৰীয়া',
  yin: 'ডাৱৰময়',
  wu: 'কুহেলিকা',
  tedabaoyu: 'অত্যাধিক ধুমুহা-বৰষুণ',
  dabaoyu: 'প্ৰচণ্ড ধুমুহা-বৰষুণ',
  baoyu: 'ধুমুহা-বৰষুণ',
  leizhenyu: 'বিজুলী-ঢেৰেকনি',
  zhenyu: 'বৃষ্টিপাত',
  dayu: 'প্ৰচণ্ড বৰষুণ',
  zhongyu: 'মধ্যমীয়া বৰষুণ',
  xiaoyu: 'পাতলীয়া বৰষুণ',
  yujiaxue: 'শিলাবৃষ্টি',
  baoxue: 'ব্লিজাৰ্ড',
  zhenxue: 'প্ৰৱল তুষাৰপাত',
  daxue: 'প্ৰচণ্ড তুষাৰপাত',
  zhongxue: 'মধ্যমীয়া তুষাৰপাত',
  xiaoxue: 'পাতলীয়া তুষাৰপাত',
  qiangshachenbao: 'শক্তিশালী ধুলি-ধুমুহা',
  shachenbao: 'বালি-ধুমুহা',
  shachen: 'পাতলীয়া বালি-ধুমুহা',
  yangsha: 'বতাহময়',
  bingbao: 'শিলাবৃষ্টি',
  fuchen: 'ধূলি',
  mai: 'কুঁৱলী',
  dongyu: 'হিম-শীতল বৰষুণ',
  xiaodaozhongyu: 'পাতলীয়াৰ পৰা মধ্যমীয়া বৰষুণ',
  zhongdaodayu: 'মধ্যমীয়াৰ পৰা প্ৰচণ্ড বৰষুণ',
  dadaobaoyu: 'প্ৰচণ্ডৰ পৰা মুষলধাৰ বৰষুণ',
  baoyudaodabaoyu: 'মুষলধাৰৰ পৰা প্ৰবল বৰষুণ',
  dabaoyudaotedabaoyu: 'প্ৰবলৰ পৰা অত্যাধিক বৰষুণ',
  xiaodaozhongxue: 'পাতলীয়াৰ পৰা মধ্যমীয়া তুষাৰপাত',
  zhongdaodaxue: 'মধ্যমীয়াৰ পৰা প্ৰচণ্ড তুষাৰপাত',
  dadaobaoxue: 'প্ৰচণ্ড তুষাৰপাতৰ পৰা ব্লিজাৰ্ড',
  nongwu: 'কুঁৱলী',
  qiangnongwu: 'ডাঠ কুঁৱলী',
  middlemai: 'মধ্যমীয়া কুহেলিকা',
  zhongdumai: 'প্ৰচণ্ড কুহেলিকা',
  yanzhongmai: 'অত্যাধিক কুহেলিকা',
  dawu: 'ডাঠ কুঁৱলী',
  teqiangnongwu: 'অত্যাধিক কুঁৱলী',
  yu: 'বৰষুণ',
  xue: 'তুষাৰ',
  hoursForecast: 'বতৰ',
  today: 'আজি',
  tomorrow: 'অহাকালি',
  monday: 'সোম',
  tuesday: 'মংগল',
  wednesday: 'বুধ',
  thursday: 'বৃহ',
  friday: 'শুক্ৰ',
  saturday: 'শনি',
  sunday: 'দেও',
  now: 'এতিয়া',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: `এক ছেকেণ্ড ৰ'ব…`,
  laodError: `ডাটা লোড কৰিব পৰা নগ\\'ল। পুনৰ চেষ্টা কৰিবলৈ সতেজ কৰক।`,
  refresh: 'সতেজ',
  guessULike: 'আপুনি ভাল পাব পাৰে',
  setting: 'ছেটিংছ',
  changeHome: 'আৰম্ভণি পৃষ্ঠা পৰিৱৰ্তন কৰক',
  changeTitle:
    'ব্ৰাউজাৰ নিজা কৰি তুলিবলৈ যিকোনো সময়তে আৰম্ভণি পৃষ্ঠা পৰিৱৰ্তন কৰক।',
  changeTip1: '1. শীৰ্ষৰ সোঁ চুকত, "অধিক" আইকনটো টেপ কৰক আৰু ছেটিংছলৈ যাওক।',
  changeTip2: '2. অগ্ৰণী ছেটিংছত আৰম্ভণি পৃষ্ঠা চয়ন কৰক।',
  changeTip3:
    '3. Chrome আৰম্ভণি পৃষ্ঠাটো চয়ন কৰক, বা অনুকূলিত কৰিবলৈ যিকোনো ৱেব পৃষ্ঠা পচন্দ কৰক।',
  about: 'বিষয়ে',
  aboutFirst:
    "homepage.miui.com হ\\'ল আপোনাৰ ব্ৰাউজাৰৰ এটা নেভিগেশ্বন সেৱা। ই বতৰ, অনুসন্ধান, ক্ষিপ্ৰ লিংক, বাতৰি, আৰু তাতোধিক প্ৰদান কৰে।",
  aboutSecond:
    "আপোনাৰ কোনো ফীডবেক বা পৰামৰ্শ থাকিলে, homepagemiui{'@'}gmail.comত আমালৈ লিখিবলৈ সংকোচ নকৰিব। আপুনি ছেটিংছত যিকোনো সময়তে আপোনাৰ ব্ৰাউজাৰৰ আৰম্ভণি পৃষ্ঠা পৰিৱৰ্তন কৰিব পাৰে।",
  suggest: 'ফীডবেক',
  suggestTip: 'ফীডবেক আৰু পৰামৰ্শ যোগ কৰিবলৈ টেপ কৰক',
  mailTo: 'ইমেইল ঠিকনা',
  send: 'জমা',
  sendSuccess: 'সফলভাৱে জমা দিয়া হৈছে',
  weGet: 'আমি সোনকালেই আপোনাৰ ফীডবেক সংসাধন কৰিম',
  ok: 'ঠিক',
  newFuncTitle: 'নতুন বৈশিষ্ট্য',
  newFunc:
    'homepage.miui.comত এক নতুন আৰম্ভণি পৃষ্ঠা আছে। বতৰ আৰু বাতৰি, অনুসন্ধান, আৰু তাতোধিক ব্ৰাউজ কৰক। আপুনি পুৰণি আৰম্ভণি পৃষ্ঠালৈ সলনি কৰিব বা ছেটিংছত ইয়াক অনুকূলিত কৰিব পাৰে।',
  get: 'বুজি পালো',
  searchHistory: 'অনুসন্ধান ইতিহাস',
  cancel: 'বাতিল',
  delete: 'বিলোপ',
  deleteHistoryDialogTitle: 'অনুসন্ধান ইতিহাস বিলোপ কৰক',
  deleteHistoryDialogContent: 'এতিয়া অনুসন্ধান ইতিহাস স্থায়ীভাৱে বিলোপ কৰিবনে?',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'অধিক পঢ়ক',
}
