import { getQueryParam } from '@/utils/utils'
import googleIcons from '@/assets/images/siteIcons/google.png'
import miWeatherIcon from '@/assets/images/siteIcons/mi_weather.png'
import wikiIcons from '@/assets/images/siteIcons/wikipedia.png'
import gamesIcons from '@/assets/images/siteIcons/games.png'
import gmailIcons from '@/assets/images/siteIcons/gmail.png'
// pkg等于feednews_web的时候，接口返回的数据不加密
export const REQUEST_PKG = 'feednews_web'

// 标识是否显示搜索框
export const START_INPUT_QUERY = 'si'

// 信息流样式
export const NEWS_STYLE = {
  BIG_IMG: 12,
  SMALL_IMG: 1,
}

export const DEFAULT_SITE = [
  {
    icon: googleIcons,
    title: 'Google',
    url: `https://www.google.com/webhp?client=${getQueryParam('clientId')}`,
  },
  {
    icon: gmailIcons,
    title: 'Gmail',
    url: 'https://mail.google.com',
  },
  {
    icon: wikiIcons,
    title: 'Wiki',
    url: 'https://www.wikipedia.org',
  },
  {
    icon: gamesIcons,
    title: 'Games',
    url: 'https://hippofunnygame.com/games/Color-Fill-text/index.html',
  },
  {
    icon: miWeatherIcon,
    title: 'Weather',
    url: 'https://weather.com',
  },
]

export const REGION_CHANNEL = {
  outbrain: [],
  taboola_navigation: ['BR', 'VN', 'GB', 'DE', 'IT', 'IN', 'GR'],
  opera: [
    'ID',
    'IR',
    'TR',
    'MX',
    'PH',
    'CO',
    'BD',
    'EG',
    'PE',
    'DZ',
    'VE',
    'MY',
    'CL',
    'EC',
    'MA',
    'MM',
    'ES',
  ],
  mail_ru_navigation: ['RU'],
  squid: ['FR'],
}
