import request from '@/utils/request'
import {
  getApiDefaultParams,
  checkRegion,
  getUUID,
  getQueryParam,
} from '@/utils/utils'
import createSign from '@/utils/requestSign'
import { REGION_CHANNEL } from '@/consts'

import eventTrackManager from '@/utils/eventTrackManager'
import { getValue } from 'firebase/remote-config'
import { fetchAndActivate } from 'firebase/remote-config'
/**
 * 获取推荐站点
 * */
export const fetchRecommendSites = function () {
  // TODO 数据暂时写死，后续修改为从接口获取
  // let sites = recommendSites.default
  // checkRegion.isBR() && (sites = recommendSites.pt_BR)
  // checkRegion.isRU() && (sites = recommendSites.ru_RU)
  // checkRegion.isES() && (sites = recommendSites.es_ES)
  // checkRegion.isDE() && (sites = recommendSites.de_DE)
  // checkRegion.isGB() && (sites = recommendSites.en_GB)
  // checkRegion.isFR() && (sites = recommendSites.fr_FR)
  // checkRegion.isIT() && (sites = recommendSites.it_IT)
  // checkRegion.isIN() && (sites = recommendSites.in_IN)
  // checkRegion.isIR() && (sites = recommendSites.fa_IR)
  // checkRegion.isTR() && (sites = recommendSites.tr_TR)
  // checkRegion.isMX() && (sites = recommendSites.es_rUS)
  // checkRegion.isPH() && (sites = recommendSites.es_rUS)

  // return Promise.resolve({
  //   head: {
  //     code: 200,
  //   },
  //   data: { sites },
  // })

  const params = {
    ...getApiDefaultParams(),
    l: navigator.language?.split('-')[0] || 'en',
    r: (getQueryParam('re') || 'ES').toUpperCase(),
  }
  const signedQueryStr = createSign(params)
  return request.get(`/browser/navigation/site?${signedQueryStr}`)
}
/**
 * 根据re参数，获取国家的cp供应商
 */
function getChannel() {
  for (const [key, value] of Object.entries(REGION_CHANNEL)) {
    if (value.includes((getQueryParam('re') || 'ES').toUpperCase())) {
      return key
    }
  }
  return 'outbrain'
}
/**
 * 获取新闻信息流
 * */
export const fetchNews = function (
  refresh = 1,
  nextPageUrl = '',
  channel = null,
  version_name = null
) {
  // const defaultID = '8232c69af22b66c10c1873f310a29c67' // 固定UUID
  // 此处接口暂时为复用浏览器接口，地区相关参数写死
  let basicParams = {
    channel: channel || getChannel(),
    sl: navigator.language?.split('-')[0] || 'en',
    l: navigator.language?.split('-')[0] || 'en',
    r: (getQueryParam('re') || 'ES').toUpperCase(),
    refreshInSession: refresh + 1,
    navigation_id: getUUID(),
  }

  let otherParams = {}
  switch (true) {
    case checkRegion.isRU(): {
      otherParams = {
        count: 12,
        version_name: '13.20.0-gn',
      }
      break
    }
  }

  const params = {
    ...getApiDefaultParams(version_name),
    refresh,
    index: refresh - 1,
    nextPageUrl: encodeURIComponent(nextPageUrl),
    action: 'pull',
    doctime: new Date().getTime(),
    newsFeedStatus: '0',
    ...basicParams,
    ...otherParams,
  }
  const signedQueryStr = createSign(params)
  return request.get(`/browser/newsfeed/v2/channel/card?${signedQueryStr}`)
}
/**
 * 获取推荐新闻，目前只针对opera
 * */
export const fetchRelatedNews = function (stockId, navigation_id, refresh = 1) {
  let regionParams = {
    channel: 'opera',
    sl: navigator.language?.split('-')[0] || 'en',
    l: navigator.language?.split('-')[0] || 'en',
    r: (getQueryParam('re') || 'ES').toUpperCase(),
    navigation_id,
  }

  const params = {
    ...getApiDefaultParams(),
    action: 'pull',
    doctime: new Date().getTime(),
    newsFeedStatus: '0',
    ...regionParams,
    stockId,
    navigation_id,
    refresh,
  }
  const signedQueryStr = createSign(params)
  return request.get(`/browser/navigation/newsfeed/related?${signedQueryStr}`)
}

// const fetchRecommendKeyWords = () => {
//   const params = {
//     client_info:
//       'Hep+9ySkPPHMC6spDnQmar161Jd9v/rUldfvJM7r28iM7AdfIbxFmaUridE9K7xq',
//     c: 'IND TELKOMSEL',
//     d: 'whyred',
//     version_code: '20220330',
//     server_code: '100',
//     source: 'Google News',
//     l: 'in',
//     pkg,
//     n: '4g',
//     r: 'ID',
//     version_name: '13.2.2-go',
//     v: 'xiaomi',
//     miui_version: 'V11.0.3.0.PEIMIXM',
//     timestamp: '1657613523603',
//     sign: '72dc32e367328da897bf235ce916f226"',
//     pkg,
//   }
//   const signedQueryStr = createSign(params)
//   return request.get(`/browser/sbox/v4/hotword?${signedQueryStr}`)
// }

/**
 * @description: 获取Firebase的云控信息
 * @param {array} paramArr
 */
export const fetchFireBaseRemoteControl = function (...paramArr) {
  return new Promise((resolve, reject) => {
    fetchAndActivate(eventTrackManager.remoteConfig)
      .then(() => {
        const res = []
        paramArr.forEach((param) => {
          res.push(getValue(eventTrackManager.remoteConfig, param)._value)
        })
        resolve(res)
      })
      .catch(() => {
        reject([])
      })
  }).catch((e) => {
    /* eslint-disable-next-line */
    console.log(e)
  })
}
// 天气相关接口需要的地区参数
function getRegionParamsForecast() {
  const re = (getQueryParam('re') || 'ES').toUpperCase()
  const regionParams = {
    r: re,
    locale: `${navigator.language?.split('-')[0] || 'en'}_${re}`,
  }
  return regionParams
}

//获取多日天气预报
export const fetchDaysForecast = function (locationKey = '') {
  const params = {
    ...getApiDefaultParams(),
    timestamp: new Date().getTime(),
    ...getRegionParamsForecast(),
    locationKey,
  }
  const signedQueryStr = createSign(params)
  return request.get(`/browser/weather/forecast/daily?${signedQueryStr}`)
}

//获取小时预报
export const fetchHoursForecast = function (locationKey = '') {
  const params = {
    ...getApiDefaultParams(),
    timestamp: new Date().getTime(),
    ...getRegionParamsForecast(),
    locationKey,
  }
  const signedQueryStr = createSign(params)
  return request.get(`/browser/weather/forecast/hourly?${signedQueryStr}`)
}

//获取城市定位
export const fetchCityPosition = function () {
  const params = {
    ...getApiDefaultParams(),
    timestamp: new Date().getTime(),
    ...getRegionParamsForecast(),
  }
  const signedQueryStr = createSign(params)
  return request.get(`/browser/weather/location?${signedQueryStr}`)
}

//获取天气概况
export const fetchWeatherCur = function (locationKey = '') {
  const params = {
    ...getApiDefaultParams(),
    timestamp: new Date().getTime(),
    ...getRegionParamsForecast(),
    locationKey,
  }
  const signedQueryStr = createSign(params)
  return request.get(`/browser/weather/current?${signedQueryStr}`)
}

//获取天气数据ALL
export const fetchAllForecast = function (locationKey = '', la = '', lo = '') {
  const params = {
    ...getApiDefaultParams(),
    timestamp: new Date().getTime(),
    ...getRegionParamsForecast(),
    la,
    lo,
    locationKey,
  }
  const signedQueryStr = createSign(params)
  return request.get(`/browser/weather/other/all?${signedQueryStr}`)
}

/**
 * 提交反馈
 */
export const sendFeedback = function (
  region = 'ES',
  content = '',
  contactInfo = ''
) {
  const params = {
    ...getApiDefaultParams(),
    timestamp: new Date().getTime(),
    r: (getQueryParam('re') || 'ES').toUpperCase(),
  }
  const signedQueryStr = createSign(params)
  return request.post(`/browser/navigation/feedback/submit?${signedQueryStr}`, {
    region,
    content,
    contactInfo,
  })
}
