export default {
  searchPlaceholder: 'ಹುಡುಕು ಅಥವಾ URL ಟೈಪ್ ಮಾಡಿ',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'Elastic Car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'ನಿಚ್ಛಳ',
  duoyun: 'ಮೋಡ ಕವಿದಿದೆ',
  yin: 'ಓವರ್‌ಕ್ಯಾಸ್ಟ್‌',
  wu: 'ಮಿಸ್ಟ್‌',
  tedabaoyu: 'ತೀವ್ರ ಮಳೆ-ಗಾಳಿ',
  dabaoyu: 'ತುಂಬಾ ಬಿರುಗಾಳಿ-ಮಳೆ',
  baoyu: 'ಬಿರುಗಾಳಿ-ಮಳೆ',
  leizhenyu: 'ಗುಡುಗು-ಮಳೆ',
  zhenyu: 'ತುಂತುರು ಮಳೆ',
  dayu: 'ಭಾರೀ ಮಳೆ',
  zhongyu: 'ಸಾಧಾರಣ ಮಳೆ',
  xiaoyu: 'ಹಗುರ ಮಳೆ',
  yujiaxue: 'ಆಲಿಕಲ್ಲು ಮಳೆ',
  baoxue: 'ಹಿಮಪಾತ',
  zhenxue: 'ಹಿಮ ತುಂತುರು ಮಳೆ',
  daxue: 'ತುಂಬಾ ಹಿಮ',
  zhongxue: 'ಸಾಧಾರಣ ಹಿಮ',
  xiaoxue: 'ಸ್ವಲ್ಪ ಹಿಮ',
  qiangshachenbao: 'ತೀವ್ರ ಮರಳು-ಗಾಳಿ',
  shachenbao: 'ಮರಳು-ಗಾಳಿ',
  shachen: 'ಹಗುರ ಮರಳು-ಗಾಳಿ',
  yangsha: 'ಗಾಳಿ',
  bingbao: 'ಆಲಿಕಲ್ಲು',
  fuchen: 'ಧೂಳು',
  mai: 'ಮಬ್ಬು',
  dongyu: 'ಚಳಿ-ಮಳೆ',
  xiaodaozhongyu: 'ಹಗುರದಿಂದ ಸಾಧಾರಣ ಮಳೆ',
  zhongdaodayu: 'ಸಾಧಾರಣದಿಂದ ಭಾರಿ ಮಳೆ',
  dadaobaoyu: 'ಭಾರಿಯಿಂದ ಚಂಡಮಾರುತದಂತ ಮಳೆ',
  baoyudaodabaoyu: 'ಚಂಡಮಾರುತದಂತಹ ಮಳೆಯಿಂದ ಕುಂಭದ್ರೋಣ ಮಳೆ',
  dabaoyudaotedabaoyu: 'ಕುಂಭದ್ರೋಣ ಮಳೆಯಿಂದ ವಿಪರೀತ ಬಿರುಗಾಳಿ ಮಳೆ',
  xiaodaozhongxue: 'ಹಗುರದಿಂದ ಸಾಧಾರಣ ಹಿಮ',
  zhongdaodaxue: 'ಸಾಧಾರಣದಿಂದ ಭಾರಿ ಹಿಮ',
  dadaobaoxue: 'ಭಾರಿ ಹಿಮದಿಂದ ಭಾರಿ ಹಿಮಗಾಳಿ',
  nongwu: 'ಫಾಗ್‌',
  qiangnongwu: 'ಗಾಢ ಮಂಜು',
  middlemai: 'ಸಾಧಾರಣ ಮಬ್ಬು',
  zhongdumai: 'ಭಾರೀ ಮಬ್ಬು',
  yanzhongmai: 'ವಿಪರೀತ ಮಬ್ಬು',
  dawu: 'ದಟ್ಟ ಮಂಜು',
  teqiangnongwu: 'ವಿಪರೀತ ಮಂಜು',
  yu: 'ಮಳೆ',
  xue: 'ಹಿಮ',
  hoursForecast: 'ಹವಾಮಾನ',
  today: 'ಇಂದು',
  tomorrow: 'ನಾಳೆ',
  monday: 'ಸೋಮ',
  tuesday: 'ಮಂಗಳ',
  wednesday: 'ಬುಧ',
  thursday: 'ಗುರು',
  friday: 'ಶುಕ್ರ',
  saturday: 'ಶನಿ',
  sunday: 'ಭಾನು',
  now: 'ಈಗ',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'ಒಂದು ಸೆಕೆಂ…',
  laodError: 'ಡೇಟಾ ಲೋಡ್‌ ಆಗುತ್ತಿಲ್ಲ. ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಲು ಟ್ಯಾಪ್‌ ಮಾಡಿ.',
  refresh: 'ರಿಫ್ರೆಶ್',
  guessULike: 'ನೀವು ಇಷ್ಟಪಡಬಹುದು',
  setting: 'ಸೆಟ್ಟಿಂಗ್‌‌ಗಳು',
  changeHome: 'ಪ್ರಾರಂಭ ಪುಟ ಬದಲಾಯಿಸಿ',
  changeTitle: 'ಬ್ರೌಸರ್ ಅನ್ನು ಸಂಪೂರ್ಣ ನಿಮ್ಮದಾಗಿಸಿಕೊಳ್ಳಲು ಪ್ರಾರಂಭ ಪುಟ ಬದಲಾಯಿಸಿ.',
  changeTip1:
    '1. ಬಲತುದಿಯ ಮೇಲ್ಭಾಗದಲ್ಲಿರುವ "ಇನ್ನಷ್ಟು" ಐಕಾನ್‌ ಟ್ಯಾಪ್‌ ಮಾಡಿ, ಸೆಟ್ಟಿಂಗ್‌ಗಳಿಗೆ ಹೋಗಿ.',
  changeTip2: '2. ಸುಧಾರಿತ ಸೆಟ್ಟಿಂಗ್‌ಗಳು ನಲ್ಲಿ ಪ್ರಾರಂಭ ಪುಟ ಆಯ್ಕೆ ಮಾಡಿ.',
  changeTip3:
    '3. Chrome ಪ್ರಾರಂಭ ಪುಟ ಆಯ್ಕೆ ಮಾಡಿ, ಅಥವಾ ಅದನ್ನು ಕಸ್ಟಮೈಸ್‌ ಮಾಡಲು ಯಾವುದೇ ವೆಬ್‌ ಪುಟ ಆಯ್ಕೆ ಮಾಡಿ.',
  about: 'ಕುರಿತು',
  aboutFirst:
    'homepage.miui.com ನಿಮ್ಮ ಬ್ರೌಸರ್‌ನಲ್ಲಿರುವ ನ್ಯಾವಿಗೇಶನ್‌ ಸೇವೆಯಾಗಿದೆ. ಇದು ಹವಾಮಾನ, ಹುಡುಕಾಟ, ತ್ವರಿತ ಲಿಂಕ್‌ಗಳು, ಸುದ್ದಿ ಮತ್ತು ಇತ್ಯಾದಿಗಳನ್ನು ಒದಗಿಸುತ್ತದೆ.',
  aboutSecond:
    "ಯಾವುದೇ ಪ್ರತಿಕ್ರಿಯೆ ಅಥವಾ ಸಲಹೆಗಳಿದ್ದರೆ, ನಮ್ಮ homepagemiui{'@'}gmail.com ಗೆ ಒಂದು ಸಾಲಿನ ಮೇಲ್‌ ಕಳಿಸಿ. ಸೆಟ್ಟಿಂಗ್‌ಗಳಲ್ಲಿ ಯಾವುದೇ ಕ್ಷಣದಲ್ಲಿಯೂ ನೀವು ನಿಮ್ಮ ಬ್ರೌಸರ್‌ನ ಪ್ರಾರಂಭ ಪುಟವನ್ನು ಬದಲಾಯಿಸಬಹುದು.",
  suggest: 'ಪ್ರತಿಕ್ರಿಯೆ',
  suggestTip: 'ಪ್ರತಿಕ್ರಿಯೆ ಮತ್ತು ಸಲಹೆಗಳನ್ನು ಸೇರಿಸಲು ಟ್ಯಾಪ್‌ ಮಾಡಿ',
  mailTo: 'ಇಮೇಲ್ ವಿಳಾಸ',
  send: 'ಸಲ್ಲಿಸಿ',
  sendSuccess: 'ಯಶಸ್ವಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ',
  weGet: 'ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ನಾವು ಆದಷ್ಟು ಬೇಗ ಪ್ರಕ್ರಿಯೆಗೊಳಿಸುತ್ತೇವೆ',
  ok: 'ಸರಿ',
  newFuncTitle: 'ಹೊಸ ಫೀಚರ್‌',
  newFunc:
    'homepage.miui.com ಹೊಸ ಪ್ರಾರಂಭ ಪುಟವನ್ನು ಹೊಂದಿದೆ. ಹವಾಮಾನ ಮತ್ತು ಸುದ್ದಿ, ಹುಡುಕಾಟ ಮತ್ತು ಇನ್ನಷ್ಟು ಸಂಗತಿಗಳನ್ನು ಬ್ರೌಸ್‌ ಮಾಡಿ. ನೀವು ಸೆಟ್ಟಿಂಗ್‌ಗಳಲ್ಲಿ ಹಳೆಯ ಪ್ರಾರಂಭ ಪುಟಕ್ಕೆ ಬದಲಾಯಿಸಬಹುದು ಅಥವಾ ಅದನ್ನು ವೈಯಕ್ತೀಕರಿಸಬಹುದು.',
  get: 'ಅರ್ಥವಾಯಿತು',
  searchHistory: 'ಹುಡುಕಾಟ ಇತಿಹಾಸ',
  cancel: 'ರದ್ದು',
  delete: 'ಅಳಿಸಿ',
  deleteHistoryDialogTitle: 'ಹುಡುಕಾಟ ಇತಿಹಾಸ ಅಳಿಸಿ',
  deleteHistoryDialogContent: 'ಹುಡುಕಾಟ ಇತಿಹಾಸವನ್ನು ಶಾಶ್ವತವಾಗಿ ಅಳಿಸಬೇಕೇ?',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'ಇನ್ನಷ್ಟು ಓದಿ',
}
