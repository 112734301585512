export default {
  searchPlaceholder: 'వెతకండి లేదా URL నమోదు చేయండి',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'Elastic Car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'నిర్మలంగా ఉంది',
  duoyun: 'మేఘావృతం',
  yin: 'దట్టమైన మబ్బులు',
  wu: 'కొద్దిపాటి పొగమంచు',
  tedabaoyu: 'తీవ్రమైన గాలివాన',
  dabaoyu: 'భారీ వర్షం',
  baoyu: 'గాలివాన',
  leizhenyu: 'ఉరుములతో కూడిన గాలివాన',
  zhenyu: 'వానజల్లులు',
  dayu: 'భారీ వర్షం',
  zhongyu: 'కొద్దిపాటి వర్షం',
  xiaoyu: 'తేలికపాటి వర్షం',
  yujiaxue: 'మంచు వాన',
  baoxue: 'మంచు తుఫాను',
  zhenxue: 'మంచు జల్లులు',
  daxue: 'భారీ మంచు',
  zhongxue: 'కొద్దిపాటి మంచు',
  xiaoxue: 'తేలికపాటి మంచు',
  qiangshachenbao: 'భారీ ఇసుక తుఫాను',
  shachenbao: 'ఇసుక తుఫాను',
  shachen: 'తేలికపాటి ఇసుక తుఫాను',
  yangsha: 'గాలులు',
  bingbao: 'వడగళ్లు',
  fuchen: 'ధూళి',
  mai: 'పొగమబ్బు',
  dongyu: 'గడ్డకట్టించే వర్షం',
  xiaodaozhongyu: 'తేలికపాటి నుండి కొద్దిపాటి వర్షం',
  zhongdaodayu: 'మితమైన నుండి భారీ వర్షం',
  dadaobaoyu: 'భారీ నుండి టోరెంటియల్ వర్షం',
  baoyudaodabaoyu: 'టోరెంటియల్ వర్షం నుండి కుండపోత',
  dabaoyudaotedabaoyu: 'కుండపోత నుండి తీవ్ర పెనుతుఫాను',
  xiaodaozhongxue: 'తేలికపాటి నుండి కొద్దిపాటి మంచు',
  zhongdaodaxue: 'మితమైన నుండి భారీ మంచు',
  dadaobaoxue: 'భారీ మంచు నుండి మంచు తుఫాను',
  nongwu: 'పొగ మంచు',
  qiangnongwu: 'మందమైన పొగమంచు',
  middlemai: 'కొద్దిపాటి పొగమబ్బు',
  zhongdumai: 'భారీ పొగమబ్బు',
  yanzhongmai: 'తీవ్ర పొగమబ్బు',
  dawu: 'దట్టమైన పొగమంచు',
  teqiangnongwu: 'తీవ్ర పొగమంచు',
  yu: 'వర్షం',
  xue: 'మంచు',
  hoursForecast: 'వాతావరణం',
  today: 'ఈరోజు',
  tomorrow: 'రేపు',
  monday: 'సోమ',
  tuesday: 'మంగళ',
  wednesday: 'బుధ',
  thursday: 'గురు',
  friday: 'శుక్ర',
  saturday: 'శని',
  sunday: 'ఆది',
  now: 'ఇప్పుడు',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'ఒక్క క్షణం వేచి ఉండండి…',
  laodError:
    'డేటాని లోడ్ చేయలేకపోయాము. మళ్లీ ప్రయత్నించడానికి రిఫ్రెష్ చేయండి.',
  refresh: 'రిఫ్రెష్ చేయి',
  guessULike: 'మీరు ఇష్టపడే అవకాశం ఉన్నవి',
  setting: 'సెట్టింగ్‍‌లు',
  changeHome: 'ప్రారంభ పేజీని మార్చు',
  changeTitle:
    'మీ బ్రౌజర్‌ను మీకు నచ్చిన విధంగా మార్చడానికి ఏ సమయంలోనైనా మీ ప్రారంభ పేజీని మార్చండి.',
  changeTip1:
    '1. ఎగువ కుడి మూలలోని, "మరిన్ని" చిహ్నాం ట్యాప్ చేసి, సెట్టింగ్‌లకు వెళ్లండి.',
  changeTip2: '2. అధునాతన సెట్టింగ్‌ల్లో ప్రారంభ పేజీని ఎంచుకోండి.',
  changeTip3:
    '3. Chrome ప్రారంభ పేజీని ఎంచుకోండి లేదా దానిని అనుకూలీకరించడానికి ఏదైనా వెబ్ పేజీని ఎంచుకోండి.',
  about: 'వివరాలు',
  aboutFirst:
    'homepage.miui.com అనేది మీ బ్రౌజర్‌లోని నావిగేషన్ సేవ. ఇది వాతావరణం, శోధన, త్వరిత లింక్‌లు, వార్తలు మరియు మరిన్ని అందిస్తుంది.',
  aboutSecond:
    "మీరు ఏవైనా అభిప్రాయం లేదా సూచనలను అందించాలనుకుంటే, homepagemiui{'@'}gmail.comకు మాకు మెయిల్ పంపడానికి సంకోచించవద్దు. మీరు సెట్టింగ్‌లలో ఏ సమయంలోనైనా మీ బ్రౌజర్ ప్రారంభ పేజీని కూడా మార్చవచ్చు.",
  suggest: 'అభిప్రాయం',
  suggestTip: 'అభిప్రాయం మరియు సూచనలను జోడించడానికి ట్యాప్ చేయండి',
  mailTo: 'ఇమెయిల్ చిరునామా',
  send: 'సమర్పించండి',
  sendSuccess: 'విజయవంతంగా సమర్పించారు',
  weGet: 'మేము సాధ్యమైనంత వరకు మీ అభిప్రాయాన్ని ప్రాసెస్ చేస్తాము',
  ok: 'సరే ',
  newFuncTitle: 'కొత్త ఫీచర్',
  newFunc:
    'homepage.miui.comలో సరికొత్త ప్రారంభ పేజీ ఉంది. వాతావరణం మరియు వార్తలు, శోధన మరియు మరిన్ని బ్రౌజ్ చేయండి. మీరు సెట్టింగ్‌ల్లో పాత ప్రారంభ పేజీకి తిరిగి మారవచ్చు లేదా దానిని వ్యక్తిగతీకరించవచ్చు.',
  get: 'అర్థమైంది',
  searchHistory: 'శోధన చరిత్ర',
  cancel: 'రద్దు చేయి ',
  delete: 'తొలగించు',
  deleteHistoryDialogTitle: 'శోధన చరిత్రను తొలగించండి',
  deleteHistoryDialogContent: 'ఇప్పుడే శోధన చరిత్రను శాశ్వతంగా తొలగించాలా?',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'మరింత చదవండి',
}
