export default {
  searchPlaceholder: 'URL ਖੋਜੋ ਜਾਂ ਦਾਖ਼ਲ ਕਰੋ',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'Elastic Car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'ਸਾਫ਼ ਕਰੋ',
  duoyun: 'ਬੱਦਲਵਾਈ ਵਾਲਾ',
  yin: 'ਸੰਘਣੇ ਬੱਦਲ',
  wu: 'ਕੋਹਰ',
  tedabaoyu: 'ਭਾਰੀ ਤੂਫ਼ਾਨੀ ਵਰਖਾ',
  dabaoyu: 'ਭਾਰੀ ਬਾਰਸ਼ ਦੇ ਨਾਲ ਤੁਫ਼ਾਨ',
  baoyu: 'ਤੁਫ਼ਾਨੀ ਵਰਖਾ',
  leizhenyu: 'ਗਰਜਨ ਵਾਲਾ ਤੂਫ਼ਾਨ',
  zhenyu: 'ਵਾਛੜ',
  dayu: 'ਭਾਰੀ ਬਾਰਸ਼',
  zhongyu: 'ਦਰਮਿਆਨੀ ਬਾਰਸ਼',
  xiaoyu: 'ਹਲਕੀ ਬਾਰਸ਼',
  yujiaxue: 'ਗੜਿਆਂ ਦੇ ਨਾਲ ਵਰਖਾ',
  baoxue: 'ਬਰਫ਼ਾਨੀ ਤੁਫ਼ਾਨ',
  zhenxue: 'ਬਰਫ਼ ਦੀ ਵਾਛੜ',
  daxue: 'ਭਾਰੀ ਬਰਫ਼',
  zhongxue: 'ਦਰਮਿਆਨੀ ਬਰਫ਼',
  xiaoxue: 'ਹਲਕੀ ਬਰਫ਼',
  qiangshachenbao: 'ਭਾਰੀ ਰੇਤਲੀ ਹਨੇਰੀ',
  shachenbao: 'ਰੇਤਲੀ ਹਨੇਰੀ',
  shachen: 'ਹਲਕੀ ਰੇਤਲੀ ਹਨੇਰੀ',
  yangsha: 'ਹਵਾ ਵਾਲਾ',
  bingbao: 'ਗੜੇ',
  fuchen: 'ਧੂੜ',
  mai: 'ਧੁੰਦ',
  dongyu: 'ਬਹੁਤ ਠੰਡੀ ਵਰਖਾ',
  xiaodaozhongyu: 'ਹਲਕੀ ਤੋਂ ਦਰਮਿਆਨੀ ਬਾਰਸ਼',
  zhongdaodayu: 'ਦਰਮਿਆਨੀ ਤੋਂ ਭਾਰੀ ਬਾਰਸ਼',
  dadaobaoyu: 'ਭਾਰੀ ਤੋਂ ਮੂਸਲਾਧਾਰ ਬਾਰਸ਼',
  baoyudaodabaoyu: 'ਮੂਸਲਾਧਾਰ ਬਾਰਸ਼ ਤੋਂ ਮੋਹਲੇਧਾਰ ਬਾਰਸ਼',
  dabaoyudaotedabaoyu: 'ਮੋਹਲੇਧਾਰ ਬਾਰਸ਼ ਤੋਂ ਬਹੁਤ ਜ਼ਿਆਦਾ ਤੂਫ਼ਾਨੀ ਬਾਰਸ਼',
  xiaodaozhongxue: 'ਹਲਕੀ ਤੋਂ ਦਰਮਿਆਨੀ ਬਰਫ਼',
  zhongdaodaxue: 'ਦਰਮਿਆਨੀ ਤੋਂ ਭਾਰੀ ਬਰਫ਼',
  dadaobaoxue: 'ਭਾਰੀ ਬਰਫ਼ ਤੋਂ ਬਰਫ਼ਾਨੀ ਤੂਫ਼ਾਨ',
  nongwu: 'ਧੁੰਦ',
  qiangnongwu: 'ਗੂੜ੍ਹੀ ਧੁੰਦ',
  middlemai: 'ਦਰਮਿਆਨੀ ਧੁੰਦ',
  zhongdumai: 'ਭਾਰੀ ਧੁੰਦ',
  yanzhongmai: 'ਜ਼ਿਆਦਾ ਧੁੰਦ',
  dawu: 'ਸੰਘਣੀ ਧੁੰਦ',
  teqiangnongwu: 'ਬੇਹੱਦ ਧੁੰਦ',
  yu: 'ਬਾਰਸ਼',
  xue: 'ਬਰਫ਼',
  hoursForecast: 'ਮੌਸਮ',
  today: 'ਅੱਜ',
  tomorrow: 'ਆਉਣ ਵਾਲਾ ਕੱਲ੍ਹ',
  monday: 'ਸੋਮ',
  tuesday: 'ਮੰਗਲ',
  wednesday: 'ਬੁੱਧ',
  thursday: 'ਵੀਰ',
  friday: 'ਸ਼ੁੱਕਰ',
  saturday: 'ਸ਼ਨੀ',
  sunday: 'ਐਤ',
  now: 'ਹੁਣੇ',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'ਬਸ ਇੱਕ ਸਕਿੰਟ…',
  laodError: 'ਡਾਟਾ ਲੋਡ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਿਆ। ਰਿਫ਼੍ਰੈਸ਼ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।',
  refresh: 'ਰਿਫ਼੍ਰੈਸ਼ ਕਰੋ',
  guessULike: 'ਸ਼ਾਇਦ ਇਹ ਤੁਹਾਨੂੰ ਪਸੰਦ ਆਏ',
  setting: 'ਸੈਟਿੰਗਾਂ',
  changeHome: 'ਅਰੰਭਕ ਪੰਨਾ ਬਦਲੋ',
  changeTitle:
    'ਆਪਣਾ ਅਰੰਭਕ ਪੰਨਾ ਬਦਲੋ ਅਤੇ ਆਪਣੇ ਬ੍ਰਾਊਜ਼ਰ ਨੂੰ ਪੂਰੀ ਤਰ੍ਹਾਂ ਆਪਣਾ ਬਣਾਓ।',
  changeTip1:
    '1. ਸਭ ਤੋਂ ਉੱਪਰ ਸੱਜੇ ਕੋਨੇ ਵਿੱਚ, "ਹੋਰ ਵੀ" ਪ੍ਰਤੀਕ ਉੱਤੇਤ ਟੈਪ ਕਰੋ ਅਤੇ ਸੈਟਿੰਗਾਂ \\\'ਤੇ ਜਾਓ।',
  changeTip2: '2. ਉੱਨਤ ਸੈਟਿੰਗਾਂ ਵਿੱਚ ਜਾ ਕੇ ਅਰੰਭਕ ਪੰਨਾ ਚੁਣੋ।',
  changeTip3:
    '3. Chrome ਦਾ ਅਰੰਭਕ ਪੰਨਾ ਚੁਣੋ ਜਾਂ ਇਸਨੂੰ ਅਨੁਕੂਲਿਤ ਕਰਨ ਲਈ ਕੋਈ ਵੀ ਵੈੱਬ ਪੰਨਾ ਚੁਣੋ।',
  about: 'ਇਸਦੇ ਬਾਰੇ',
  aboutFirst:
    'homepage.miui.com ਤੁਹਾਡੇ ਬ੍ਰਾਊਜ਼ਰ ਵਿੱਚ ਇੱਕ ਨੈਵੀਗੇਸ਼ਨ ਸੇਵਾ ਹੈ। ਇਹ ਮੌਸਮ, ਖੋਜ, ਕਵਿੱਕ ਲਿੰਕ, ਖ਼ਬਰਾਂ ਅਤੇ ਹੋਰ ਬਹੁਤ ਕੁਝ ਮੁਹੱਈਆ ਕਰਵਾਉਂਦੀ ਹੈ।',
  aboutSecond:
    "ਜੇਕਰ ਤੁਹਾਡੀ ਕੋਈ ਫ਼ੀਡਬੈਕ ਜਾਂ ਸੁਝਾਅ ਹਨ, ਤਾਂ ਬੇਝਿਜਕ ਸਾਨੂੰ homepagemiui{'@'}gmail.com ਉੱਤੇ ਲਿਖੋ। ਤੁਸੀਂ ਕਿਸੇ ਵੇਲੇ ਵੀ ਸੈਟਿੰਗਾਂ ਵਿੱਚ ਜਾ ਕੇ ਆਪਣੇ ਬ੍ਰਾਊਜ਼ਰ ਦਾ ਅਰੰਭਕ ਪੰਨਾ ਬਦਲ ਸਕਦੇ ਹੋ।",
  suggest: 'ਫ਼ੀਡਬੈਕ',
  suggestTip: 'ਫ਼ੀਡਬੈਕ ਅਤੇ ਸੁਝਾਅ ਸ਼ਾਮਲ ਕਰਨ ਲਈ ਟੈਪ ਕਰੋ',
  mailTo: 'ਈਮੇਲ ਪਤਾ',
  send: 'ਸਪੁਰਦ ਕਰੋ',
  sendSuccess: 'ਸਫ਼ਲਤਾਪੂਰਵਕ ਸਪੁਰਦ ਕਰ ਦਿੱਤਾ ਗਿਆ',
  weGet: 'ਜਿੰਨੀ ਛੇਤੀ ਹੋ ਸਕੇ ਅਸੀਂ ਤੁਹਾਡੀ ਫ਼ੀਡਬੈਕ ਉੱਤੇ ਕਾਰਵਾਈ ਕਰਾਂਗੇ',
  ok: 'ਠੀਕ ਹੈ',
  newFuncTitle: 'ਨਵੀਂ ਸਹੂਲਤ',
  newFunc:
    'homepage.miui.com ਵਿੱਚ ਹੈ ਇੱਕ ਬਿਲਕੁਲ ਨਵਾਂ ਅਰੰਭਕ ਪੰਨਾ। ਮੌਸਮ ਅਤੇ ਖ਼ਬਰਾਂ, ਖੋਜ ਅਤੇ ਹੋਰ ਬਹੁਤ ਕੁਝ ਬ੍ਰਾਊਜ਼ ਕਰੋ। ਤੁਸੀਂ ਸੈਟਿੰਗਾਂ ਵਿੱਚ ਜਾ ਕੇ ਪੁਰਾਣੇ ਅਰੰਭਕ ਪੰਨਾ ਉੱਤੇ ਵਾਪਸ ਜਾ ਸਕਦੇ ਹੋ ਜਾਂ ਵਿਅਕਤੀਗਤ ਬਣਾ ਸਕਦੇ ਹੋ।',
  get: 'ਸਮਝ ਲਿਆ',
  searchHistory: 'ਖੋਜ ਇਤਿਹਾਸ',
  cancel: 'ਰੱਦ ਕਰੋ',
  delete: 'ਮਿਟਾਓ',
  deleteHistoryDialogTitle: 'ਖੋਜ ਇਤਿਹਾਸ ਮਿਟਾਓ',
  deleteHistoryDialogContent: "ਕੀ ਹੁਣ ਖੋਜ ਇਤਿਹਾਸ ਨੂੰ ਪੱਕੇ ਤੌਰ 'ਤੇ ਮਿਟਾਉਣਾ ਹੈ?",
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'ਹੋਰ ਪੜ੍ਹੋ',
}
