export default {
  searchPlaceholder: 'शोधा किंवा URL प्रविष्ट करा',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'Elastic Car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'निरभ्र',
  duoyun: 'ढगाळ',
  yin: 'अभ्राच्छादित',
  wu: 'धुके',
  tedabaoyu: 'जोरदार वादळी पाऊस',
  dabaoyu: 'जोरदार वादळ',
  baoyu: 'वादळी वारे',
  leizhenyu: 'वादळ',
  zhenyu: 'पाऊसधारा',
  dayu: 'जोरदार पाऊस',
  zhongyu: 'मध्यम पाऊस',
  xiaoyu: 'हलका पाऊस',
  yujiaxue: 'गारा व पाऊस',
  baoxue: 'हिम वादळ',
  zhenxue: 'बर्फ वृष्टी',
  daxue: 'जोरदार बर्फवृष्टी',
  zhongxue: 'मध्यम बर्फवृष्टी',
  xiaoxue: 'हलके बर्फ',
  qiangshachenbao: 'वाळूचे जोरदार वादळ',
  shachenbao: 'धुळीचे वादळ',
  shachen: 'वाळूचे हलके वादळ',
  yangsha: 'वादळी',
  bingbao: 'गारा',
  fuchen: 'धूळ',
  mai: 'धुके',
  dongyu: 'अतिश‍ीतल पाऊस',
  xiaodaozhongyu: 'हलका ते मध्यम पाऊस',
  zhongdaodayu: 'मध्यम ते जोरदार पाऊस',
  dadaobaoyu: 'मुसळधार ते अति मुसळधार पाऊस',
  baoyudaodabaoyu: 'मुसळधार पाऊस',
  dabaoyudaotedabaoyu: 'जोरदार ते अतिवृष्टी',
  xiaodaozhongxue: 'हलके ते मध्यम बर्फ',
  zhongdaodaxue: 'मध्यम ते जोरदार बर्फ',
  dadaobaoxue: 'हिमवादळ ते जोरदार बर्फ',
  nongwu: 'धुके',
  qiangnongwu: 'दाट धुके',
  middlemai: 'मध्यम धुके',
  zhongdumai: 'प्रचंड धुके',
  yanzhongmai: 'तीव्र धुके',
  dawu: 'दाट धुके',
  teqiangnongwu: 'तीव्र धुके',
  yu: 'पाऊस',
  xue: 'बर्फ',
  hoursForecast: 'हवामान',
  today: 'आज',
  tomorrow: 'उद्या',
  monday: 'सोम',
  tuesday: 'मंगळ',
  wednesday: 'बुध',
  thursday: 'गुरु',
  friday: 'शुक्र',
  saturday: 'शनि',
  sunday: 'रवि',
  now: 'आता',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'फक्त एक सेकंद…',
  laodError: 'डेटा लोड करता आला नाही. पुन्हा प्रयत्न करण्यासाठी रिफ्रेश करा.',
  refresh: 'रिफ्रेश',
  guessULike: 'कदाचित आपल्‍याला आवडेल',
  setting: 'सेटिंग्ज',
  changeHome: 'स्टार्ट पेज बदला',
  changeTitle:
    'तुमचा ब्राउझर तुमचा सर्वस्व बनवण्यासाठी तुमचे स्टार्ट पेज कधीही बदला.',
  changeTip1:
    '1. वरच्या उजव्या कोपऱ्यात, "अधिक" आयकॉन टॅप करा आणि सेटिंग्ज वर जा.',
  changeTip2: '2. प्रगत सेटिंग्जमध्ये स्टार्ट पेज निवडा.',
  changeTip3:
    '3. Chrome स्टार्ट पेज निवडा किंवा ते सानुकूलित करण्यासाठी कोणतेही वेब पेज निवडा.',
  about: 'याबद्दल',
  aboutFirst:
    'homepage.miui.com ही तुमच्या ब्राउझरमधील नॅव्हिगेशन सेवा आहे. हे हवामान, शोध, क्विक लिंक्स, बातम्या आणि बरेच काही प्रदान करते.',
  aboutSecond:
    "तुमचा काही अभिप्राय किंवा सूचना असल्यास, आम्हाला homepagemiui{'@'}gmail.com वर कळवा. तुम्ही तुमचा ब्राउझर स्टार्ट पेज सेटिंग्जमध्ये कधीही बदलू शकता.",
  suggest: 'अभिप्राय',
  suggestTip: 'अभिप्राय आणि सूचना जोडण्यासाठी टॅप करा',
  mailTo: 'ईमेल एड्रेस',
  send: 'सबमिट',
  sendSuccess: 'यशस्वीपणे सबमिट केले',
  weGet: 'आम्ही शक्य तितक्या लवकर तुमच्या अभिप्रायवर प्रक्रिया करू',
  ok: 'ओके',
  newFuncTitle: 'नवीन फीचर',
  newFunc:
    'homepage.miui.com चे अगदी नवीन स्टार्ट पेज आहे. हवामान आणि बातम्या, शोध आणि बरेच काही ब्राउझ करा. तुम्ही जुन्या स्टार्ट पेजवर परत जाऊ शकता किंवा सेटिंग्जमध्ये वैयक्तिकृत करू शकता.',
  get: 'समजले',
  searchHistory: 'सर्च हिस्ट्री',
  cancel: 'रद्द',
  delete: 'डिलीट',
  deleteHistoryDialogTitle: 'सर्च हिस्ट्री डिलीट करा',
  deleteHistoryDialogContent: 'आता सर्च हिस्ट्री कायमस्वरुपी डिलीट करायची?',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'अधिक वाचा',
}
