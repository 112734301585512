import { initializeApp } from 'firebase/app'
import { getRemoteConfig } from 'firebase/remote-config'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { EVENT_TYPE } from '@/consts/eventTrack'
import { LS } from '@mi/global-sdks/lib/storage'
import {
  weatherType,
  searchType,
  sugType,
  pwaType,
  pushType,
} from '@/consts/abTest'
import {
  getQueryParam,
  getMobileType,
  checkRegion,
  // isOutside,
  // isMintnav2024,
  loadTikTokJs,
} from './utils'
import {
  googleStyleABConfig,
  csaRelatedABConfig,
  getMiABResult,
} from '@/utils/miABtest'
import { getCookie } from '@/utils/utils'
class EventTrackManager {
  firebaseApp = null
  firebaseAnalytics = null

  init() {
    const firebaseConfig = {
      apiKey: 'AIzaSyBLg0HYpiDgmLWlAgvfwHjJRz_MiBC9cf8',
      authDomain: 'mi-browser-new.firebaseapp.com',
      projectId: 'mi-browser-new',
      storageBucket: 'mi-browser-new.appspot.com',
      messagingSenderId: '687676053922',
      appId:
        import.meta.env.VITE_APP_ENV === 'prod'
          ? '1:687676053922:web:3ced851ddd311910dffafb'
          : '1:687676053922:web:12c4592e59e704bddffafb',
      measurementId:
        import.meta.env.VITE_APP_ENV === 'prod'
          ? 'G-Z1JWEPV8LC'
          : 'G-Z9XSV4848N',
    }
    this.firebaseApp = initializeApp(firebaseConfig)
    this.firebaseAnalytics = getAnalytics(this.firebaseApp)
    // 若通知功能被关闭，手动注销掉firebase-messaging-sw.js
    if (pushType === 1) {
      navigator.serviceWorker
        ?.getRegistrations()
        .then(function (sws) {
          sws.forEach(function (sw) {
            // 将if判断注释掉，可以同时注销pwa功能的my-sw.js（后面若想重新开启pwa功能，需要将此处 if 注释打开）
            // if (sw.active?.scriptURL.indexOf('firebase-messaging-sw') !== -1) {
            sw.unregister()
            // }
          })
        })
        .catch(() => window.console.log('getRegistrations Error!'))
    } else {
      // 初始化通知功能（会自动启动firebase-messaging-sw.js）
      this.initPush()
    }

    this.remoteConfig = getRemoteConfig(this.firebaseApp)
    this.remoteConfig.settings.minimumFetchIntervalMillis = 1000

    // 加载买量回传pixel代码
    const h5campaign = getQueryParam('h5campaign')
    this.channel = h5campaign && h5campaign.split('-')[1]
    if (
      this.channel === 'tt' &&
      window.location.pathname.indexOf('config') === -1
    ) {
      loadTikTokJs()
    }

    // 初始化公共属性
    this.page_source =
      getQueryParam('source') || (getQueryParam('isPWA') ? 'pwa' : '')
    this.mobile_info = getMobileType()
    this.region = getQueryParam('re') || ''
    // this.is_show_sea = null
    // this.is_show_tre = checkRegion.isID() ? 1 : 0
    this.weather_type = weatherType === 2 ? 'mi_weather' : 'default'
    this.search_type = searchType === 1 ? 'pse' : 'google'
    this.is_sug = sugType === 1 ? 1 : 0
    this.is_pwa = pwaType === 1 ? 1 : 0
    this.is_push = pushType === 2 ? 1 : 0
    this.is_history = checkRegion.isID()

    // this.middleABvalue = getMiABResult(middleABConfig)?.code
    // this.search_ads = getMiABResult(pseABConfig)?.code
    this.googleStyleId = getMiABResult(googleStyleABConfig)?.code
    this.csaRelatedAB = getMiABResult(csaRelatedABConfig)?.code // CSA搜索情况下，对“搜索相关推荐”进行AB实验
    this.is_nf = getQueryParam('nf') === '1' ? 1 : 0
    this.browser_info = [
      'ms-android-xiaomi-rev1',
      'ms-android-xiaomi',
    ].includes(getQueryParam('clientId'))
      ? 'mi browser'
      : 'chrome'

    // 初始化打点
    this.initTrackEvent()
  }

  /**
   * @description GA埋点
   * @param eventName {String} 埋点事件名
   * @param params {Object} 埋点事件参数
   * */
  track(eventName, params = {}) {
    try {
      if (!eventName) {
        return
      }

      const paramData = {
        ...params,
        page_source: this.page_source,
        mobile_info: this.mobile_info,
        region: this.region,
        weather_type: this.weather_type,
        search_source: this.search_type,
        is_sug: this.is_sug,
        is_pwa: this.is_pwa,
        is_push: this.is_push,
        is_history: this.is_history,
        // is_summary: this.middleABvalue,
        browser_info: this.browser_info,
        _ga: getCookie('_ga'),
        is_releated_search:
          this.csaRelatedAB === '1'
            ? 'yes'
            : this.csaRelatedAB === '0'
            ? 'no'
            : 'none',
        is_miui: location.host.indexOf('miui') !== -1 ? 1 : 0,
        is_channel_id: getQueryParam('c') || '',
        is_nf: this.is_nf,
      }

      /* eslint-disable-next-line */
      // window.console.log('GA埋点', eventName, paramData)
      // GA4打点
      window.gtag &&
        window.gtag('event', eventName, {
          ...paramData,
          send_to: window.ga4ID,
        })
      // firebase打点
      // !isOutside && logEvent(this.firebaseAnalytics, eventName, paramData)
      logEvent(this.firebaseAnalytics, eventName, paramData)
    } catch (e) {
      /* eslint-disable-next-line */
      console.error(e)
    }
  }
  // tiktok打点
  ttq_track(eventName, pixelId) {
    // 程序化回传事件的Pixel ID还需要调用track()方法
    if (pixelId) {
      window.ttq?.instance?.(pixelId)?.track(eventName)
    } else {
      window.ttq?.instance?.('CLE9RFRC77U0K4UVNQOG')?.track(eventName)
      window.ttq?.instance?.('7169099470494318593')?.track(eventName)
      window.ttq?.instance?.('CATFG7BC77U5NQUHDT40')?.track(eventName)
      window.ttq?.instance?.('CMBPK2BC77U483ARSRAG')?.track(eventName)
      window.ttq?.instance?.('CMBPJJRC77UE655RNCL0')?.track(eventName)
      window.ttq?.instance?.('CMJT7J3C77UB6TL2V1RG')?.track(eventName)
      window.ttq?.instance?.('CMRG69RC77U1UD4AELS0')?.track(eventName)
      window.ttq?.instance?.('CMVIOHJC77UFF95KFB3G')?.track(eventName)
      window.ttq?.instance?.('CMVINCJC77U3EE0RNK60')?.track(eventName)
      window.ttq?.instance?.('CNA812RC77UBB5H948F0')?.track(eventName)
      window.ttq?.instance?.('CNFVR63C77UC27SBN2KG')?.track(eventName)
      window.ttq?.instance?.('CML27VRC77UE8SFFD7H0')?.track(eventName)
      window.ttq?.instance?.('CNEP5NRC77UE36LJBUFG')?.track(eventName)
      window.ttq?.instance?.('CNJF84BC77U7U7TP7F4G')?.track(eventName)
      window.ttq?.instance?.('CNL7MK3C77U6UIN9DEEG')?.track(eventName)
    }
  }
  // facebook打点
  fbq_track(eventName) {
    window.fbq?.('track', eventName)
  }

  initTrackEvent() {
    const pageStartTime = new Date().getTime()
    window.isUnloading = false
    const that = this
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState == 'hidden') {
        if (!window.isUnloading) {
          const dur_time =
            new Date().getTime() -
            (window.visibleAgainBeginTime || pageStartTime)
          that.track(EVENT_TYPE.DURATION_TIME, {
            dur_time,
          })
        }
      } else if (document.visibilityState == 'visible') {
        window.visibleAgainBeginTime = new Date().getTime()
      }
    })
    window.onbeforeunload = function () {
      window.isUnloading = true
      const dur_time =
        new Date().getTime() - (window.visibleAgainBeginTime || pageStartTime)
      that.track(EVENT_TYPE.DURATION_TIME, {
        dur_time,
      })
    }

    window.addEventListener('load', () => {
      // const that = this
      // 页面加载耗时
      function getLoadTime() {
        try {
          var time = performance.timing
          let loadTime = time.loadEventEnd - time.fetchStart
          if (loadTime < 0) {
            setTimeout(function () {
              getLoadTime()
            }, 200)
            return
          }
          window.load_time = loadTime / 1000
          // that.track(EVENT_TYPE.LOAD_TIME, {
          //   load_time: window.load_time.toFixed(1),
          // })
        } catch (e) {
          /* eslint-disable-next-line */
          window.console.log(e)
          /* eslint-disable-next-line */
          window.console.log(performance.timing)
        }
      }
      getLoadTime()
    })

    // 用来监听pwa弹窗出现的事件，但不准确：弹窗被用户关闭（没有安装应用）后，下次访问页面没有出现弹窗，但此事件依旧被触发了
    // window.addEventListener('beforeinstallprompt', () => {
    //   alert('0000 beforeinstallprompt')
    // })
    if (pwaType === 1) {
      // window.addEventListener('appinstalled', () => {
      //   this.track(EVENT_TYPE.PWA_INSTALLED, {
      //     re: this.region,
      //   })
      // })
    }
  }

  initPush() {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        if (!LS.get('notificationPermission')) {
          LS.set('notificationPermission', 1)
          // this.track(EVENT_TYPE.NOTIFICATION_GRANTED, {})
        }
        try {
          const messaging = getMessaging(this.firebaseApp)
          getToken(messaging, {
            vapidKey:
              'BJ-Z1748UeRArhz6wnhxzkp7PiqKHrbcb_S9RG1Nic9VsLcxOncobAnEUyoJfcLN5WtwgBHkxAAXI33uGlQR0as',
          })
            .then((currentToken) => {
              if (currentToken) {
                window.console.log('currentToken:', currentToken)
              } else {
                window.console.log(
                  'No registration token available. Request permission to generate one.'
                )
              }
            })
            .catch((err) => {
              window.console.log(
                'An error occurred while retrieving token. ',
                err
              )
            })
          // 当页面处于前台时，接收消息后，创建通知
          onMessage(messaging, (payload) => {
            Notification.requestPermission().then((permission) => {
              if (permission === 'granted') {
                const notification = new Notification(
                  payload.notification.title,
                  {
                    body: payload.notification.body,
                  }
                )
                notification.onclick = (event) => {
                  event.preventDefault() // prevent the browser from focusing the Notification's tab
                  window.open(payload.data.link, '_blank')
                }
              }
            })
          })
        } catch {
          window.console.log('FCM not supported!')
        }
      }
    })
  }
}

export default new EventTrackManager()
