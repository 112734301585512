export default {
  searchPlaceholder: 'Cari atau masukkan situs web',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Unduh video',
  mvpGames: 'Games',
  mvpSports: 'Olahraga',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Berita utama',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Mobil Lari',
  siteDefaultGame1: 'Pembunuh buahname',
  siteDefaultGame2: 'Bergegas',
  siteDefaultGame3: 'Mobil elastis',
  siteDefaultGame4: 'Cumi-cumi',
  siteDefaultGame5: 'Melukis',
  qing: 'Cerah',
  duoyun: 'Berawan',
  yin: 'Mendung',
  wu: 'Berkabut',
  tedabaoyu: 'Hujan badai parah',
  dabaoyu: 'Badai hujan lebat',
  baoyu: 'Hujan badai',
  leizhenyu: 'Badai petir',
  zhenyu: 'Hujan lebat',
  dayu: 'Hujan deras',
  zhongyu: 'Hujan sedang',
  xiaoyu: 'Hujan ringan',
  yujiaxue: 'Hujan es salju',
  baoxue: 'Badai salju',
  zhenxue: 'Hujan salju',
  daxue: 'Salju lebat',
  zhongxue: 'Salju sedang',
  xiaoxue: 'Salju ringan',
  qiangshachenbao: 'Badai pasir kuat',
  shachenbao: 'Badai pasir',
  shachen: 'Badai pasir ringan',
  yangsha: 'Berangin',
  bingbao: 'Hujan es',
  fuchen: 'Debu',
  mai: 'Kabut asap',
  dongyu: 'Hujan yang dingin',
  xiaodaozhongyu: 'Hujan ringan hingga sedang',
  zhongdaodayu: 'Hujan sedang hingga deras',
  dadaobaoyu: 'Hujan deras hingga amat deras',
  baoyudaodabaoyu: 'Hujan amat deras hingga lebat',
  dabaoyudaotedabaoyu: 'Hujan lebat hingga hujan badai ekstrim',
  xiaodaozhongxue: 'Salju ringan hingga sedang',
  zhongdaodaxue: 'Salju sedang hingga deras',
  dadaobaoxue: 'Salju lebat hingga badai salju',
  nongwu: 'Kabut',
  qiangnongwu: 'Kabut tebal',
  middlemai: 'Kabut asap sedang',
  zhongdumai: 'Kabut asap tebal',
  yanzhongmai: 'Kabut asap parah',
  dawu: 'Kabut pekat',
  teqiangnongwu: 'Kabut parah',
  yu: 'Hujan',
  xue: 'Salju',
  hoursForecast: 'Cuaca',
  today: 'Hari ini',
  tomorrow: 'Besok',
  monday: 'Sen',
  tuesday: 'Sel',
  wednesday: 'Rab',
  thursday: 'Kam',
  friday: 'Jum',
  saturday: 'Sab',
  sunday: 'Min',
  now: 'Sekarang',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Telur berwarna',
  siteDefaultGame12: 'Kaki yang pedu',
  siteDefaultGame13: 'laman',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'Tunggu sebentar…',
  laodError: 'Tidak dapat memuat data. Segarkan dan coba lagi.',
  refresh: 'Segarkan',
  guessULike: 'Anda mungkin suka',
  setting: 'Setelan',
  changeHome: 'Ganti Halaman awal',
  changeTitle:
    'Ganti Halaman awal kapan saja untuk menjadikan browser Anda sepenuhnya milik Anda.',
  changeTip1:
    '1. Di sudut kanan atas, ketuk ikon "Lainnya" dan pergi ke Setelan.',
  changeTip2: '2. Pilih Halaman awal di Setelan lanjutan.',
  changeTip3:
    '3. Pilih Halaman awal Chrome, atau pilih halaman web apa pun untuk mengkustomisasinya.',
  about: 'Tentang',
  aboutFirst:
    'homepage.miui.com adalah layanan navigasi di browser Anda yang menyediakan informasi cuaca, pencarian, tautan cepat, berita, dan banyak lagi.',
  aboutSecond:
    "Jika Anda memiliki masukan atau saran, jangan ragu untuk menghubungi kami di homepagemiui{'@'}gmail.com. Anda juga dapat mengubah Halaman awal browser Anda kapan saja di Setelan.",
  suggest: 'Masukan',
  suggestTip: 'Ketuk untuk tambahkan masukan dan saran',
  mailTo: 'Alamat email',
  send: 'Kirim',
  sendSuccess: 'Berhasil dikirim',
  weGet: 'Kami akan memproses masukan Anda sesegera mungkin',
  ok: 'Oke',
  newFuncTitle: 'Fitur baru',
  newFunc:
    'homepage.miui.com memiliki Halaman awal terbaru. Telusuri informasi cuaca dan berita, pencarian, dan lainnya. Anda dapat berganti kembali ke Halaman awal yang lama atau mempersonalisasikannya di Setelan.',
  get: 'Mengerti',
  searchHistory: 'Riwayat pencarian',
  cancel: 'Batal',
  delete: 'Hapus',
  deleteHistoryDialogTitle: 'Hapus riwayat pencarian',
  deleteHistoryDialogContent: 'Hapus riwayat pencarian secara permanen?',
  siteCooking: 'memasak',
  siteFashion: 'mode',
  siteFunny: 'lucu',
  siteTravel: 'perjalanan',
  siteQuiz: 'kuis',
  readMore: 'Baca selengkapnya',
}
