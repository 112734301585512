import { getDateStr, getQueryParam, isOutside } from '@/utils/utils'
import { fetchFireBaseRemoteControl } from '@/services'
import { LS } from '@mi/global-sdks/lib/storage'

// 生成AFC广告
export function generateAFCAd(adStr, parentDom) {
  let channel = getQueryParam('c')
  let lastAdStr = adStr
  if (channel && channel.trim() !== '') {
    let channelStr = `data-ad-channel="${channel}"\n`
    let adsArr = adStr.split('data-ad-slot')
    lastAdStr = [adsArr[0], channelStr, 'data-ad-slot', adsArr[1]].join('')
  }
  parentDom.innerHTML = lastAdStr
  const script = document.createElement('script')
  script.innerHTML = '(adsbygoogle = window.adsbygoogle || []).push({});'
  parentDom.appendChild(script)
}

// AFC广告代码
// 正方形
export const list_afc1 = isOutside
  ? `
    <ins class="adsbygoogle"
      style="display:block"
      data-ad-client="ca-pub-2826423230659304"
      data-ad-slot="7057259791"
      data-ad-format="auto"
      data-full-width-responsive="true">
    </ins>
    `
  : `
    <ins class="adsbygoogle"
      style="display:block"
      data-ad-client="ca-pub-2826423230659304"
      data-ad-slot="2218541016"
      data-ad-format="auto"
      data-full-width-responsive="true">
      </ins>
  `
// url带参数nf=1时的广告
export const nf_list_afc1 = `
    <ins class="adsbygoogle"
     style="display:inline-block;width:336px;height:280px"
     data-ad-client="ca-pub-2826423230659304"
     data-ad-slot="7011066253">
    </ins>
`
// 横向
export const list_afc2 = `
  <ins class="adsbygoogle"
    style="display:inline-block;width:728px;height:90px"
    data-ad-client="ca-pub-4062377952200160"
    data-ad-slot="6494494304">
  </ins>
`
// 信息流
export const list_afc3 = `
  <ins class="adsbygoogle"
    style="display:block"
    data-ad-format="fluid"
    data-ad-layout-key="-f8+4e+9j-ev+v"
    data-ad-client="ca-pub-4062377952200160"
    data-ad-slot="6560150902">
  </ins>
`
// 正方形
export const list_afc1_searchPage = `
  <ins class="adsbygoogle"
    style="display:block"
    data-ad-client="ca-pub-4062377952200160"
    data-ad-slot="4713634033"
    data-ad-format="auto"
    data-full-width-responsive="true">
  </ins>
`

// 天气页面广告 banner
export const list_afc1_weatherPage = isOutside
  ? `
    <ins class="adsbygoogle"
    style="display:block"
    data-ad-client="ca-pub-2826423230659304"
    data-ad-slot="5033731681"
    data-ad-format="auto"
    data-full-width-responsive="true"></ins>
    `
  : `
    <ins class="adsbygoogle"
      style="display:block"
      data-ad-client="ca-pub-2826423230659304"
      data-ad-slot="2398504411"
      data-ad-format="auto"
      data-full-width-responsive="true">
    </ins>
  `

// Google PSE AFC
export const list_afc_swp = `
  <ins class="adsbygoogle"
    style="display:block"
    data-ad-client="ca-pub-2826423230659304"
    data-ad-slot="8021992456"
    data-ad-format="auto"
    data-full-width-responsive="true">
  </ins>
`

// 信息流中间页 头部小卡
export const news_middle_afc1 = `
  <ins class="adsbygoogle"
    style="display:inline-block;width:320px;height:50px"
    data-ad-client="ca-pub-2826423230659304"
    data-ad-slot="2244064238">
  </ins>
`
// 信息流中间页 中部大卡
export const news_middle_afc2 = `
  <ins class="adsbygoogle"
    style="display:inline-block;width:336px;height:280px"
    data-ad-client="ca-pub-2826423230659304"
    data-ad-slot="5309991522">
  </ins>
`
// 信息流中间页 底部小卡
export const news_middle_afc3 = `
  <ins class="adsbygoogle"
    style="display:inline-block;width:320px;height:100px"
    data-ad-client="ca-pub-2826423230659304"
    data-ad-slot="9057664840">
  </ins>
`

// 负一屏天气落地页 样式一
export const weathercard_style1_afc = `
  <ins class="adsbygoogle"
     style="display:inline-block;width:368px;height:306px"
     data-ad-client="ca-pub-2826423230659304"
     data-ad-slot="2016728941"></ins>
`
// 负一屏天气落地页 样式二
export const weathercard_style2_afc = `
  <ins class="adsbygoogle"
    style="display:inline-block;width:368px;height:50px"
    data-ad-client="ca-pub-2826423230659304"
    data-ad-slot="9128932201"></ins>
`
// 负一屏天气落地页 样式三 上部小卡
export const weathercard_style3_afc1 = `
  <ins class="adsbygoogle"
    style="display:inline-block;width:368px;height:50px"
    data-ad-client="ca-pub-2826423230659304"
    data-ad-slot="3493462141"></ins>
`
// 负一屏天气落地页 样式三 底部大卡
export const weathercard_style3_afc2 = `
  <ins class="adsbygoogle"
    style="display:inline-block;width:368px;height:306px"
    data-ad-client="ca-pub-2826423230659304"
    data-ad-slot="1250442182"></ins>
`
export const article_ads = {
  'infoseekers2024.com': {
    list_afc: `<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-2826423230659304"
     data-ad-slot="2049450082"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>`,
    detail_afc: `<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-2826423230659304"
     data-ad-slot="7963185964"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>`,
  },
}
export function checkAdsInfo(
  dataArr,
  currentADshowTimes, // 本次会话中广告已出现次数
  ads_frequency,
  ads_position,
  ads_type,
  pageType = ''
) {
  const localItemName = `${pageType}-${getDateStr()}`
  if (LS.get(localItemName) === null) {
    LS.set(localItemName, 0) // 本地化存储 用户当天广告出现了多少次
  }
  if (ads_position !== '' && parseInt(ads_position) !== 0) {
    const adsPositions = ads_position.split(',')
    while (
      parseInt(LS.get(localItemName)) < parseInt(ads_frequency) &&
      currentADshowTimes < adsPositions?.length
    ) {
      const currentADposition = parseInt(adsPositions[currentADshowTimes])

      if (dataArr.length >= currentADposition) {
        let adsStr =
          // parseInt(ads_type) === 1
          //   ? list_afc3
          //   : parseInt(ads_type) === 2
          //   ? pageType === 'searchPage'
          //     ? list_afc1_searchPage
          //     : list_afc1
          //   : list_afc2
          ``
        if (getQueryParam('nf') === '1') {
          adsStr = nf_list_afc1
        }
        if (adsStr && currentADposition > 0) {
          dataArr[currentADposition - 1].showAds = true
          dataArr[currentADposition - 1].adsStr = adsStr
        }
        LS.set(localItemName, parseInt(LS.get(localItemName)) + 1)
        currentADshowTimes++
      } else {
        break
      }
    }
  }
  return { newData: dataArr, adsShowTimes: currentADshowTimes }
}
class AFGManager {
  // 插入广告sdk
  async init() {
    // afg
    window.adsbygoogle = window.adsbygoogle || []
    window.adBreak = window.adConfig = function (o) {
      window.adsbygoogle.push(o)
    }

    window.adConfig({
      preloadAdBreaks: 'on',
      sound: 'off',
    })

    this.adsName = `adsNum-interstitial-${getDateStr()}`
    if (LS.get(this.adsName) === null) {
      LS.set(this.adsName, 0)
    }
    this.lastShowTimeName = `adsTime-interstitial-${getDateStr()}`
    // 插屏广告默认每天总频次为2，时间间隔为10分钟
    const [AD_interstitial_Frequency = '2', AD_interstitial_interval = '10'] =
      isOutside
        ? ['10', '0']
        : (await fetchFireBaseRemoteControl(
            'ad_interstitial_frequency',
            'ad_interstitial_interval'
          )) || []
    this.AD_interstitial_Frequency = parseInt(AD_interstitial_Frequency)
    this.AD_interstitial_interval = parseInt(AD_interstitial_interval)
  }
  // 生成afg广告
  generateAFGAd(type, options = {}) {
    const config = {
      type: type || 'next', // start, next, reward
      name: 'game_x_ads',
      beforeAd: () => {
        // console.log('beforeAd')
      },
      afterAd: () => {
        // console.log('afterAd')
      },
      adBreakDone: (/* placementInfo */) => {
        // console.log('adBreakDone: ', placementInfo)
      },
    }

    if (type === 'reward') {
      Object.assign(config, {
        beforeReward: (/* showAdFn */) => {
          // console.log('beforeReward')
          // showAdFn()
        },
        adDismissed: () => {
          // console.log('adDismissed')
        },
        adViewed: () => {
          // console.log('adViewed')
        },
      })
    }

    Object.assign(config, options)
    LS.set(this.adsName, parseInt(LS.get(this.adsName)) + 1)
    LS.set(this.lastShowTimeName, new Date().getTime())

    window.adBreak(config)
  }
  checkShowTimes() {
    return parseInt(LS.get(this.adsName) || 0) < this.AD_interstitial_Frequency
  }
  checkShowInterval() {
    return (
      !LS.get(this.lastShowTimeName) ||
      new Date().getTime() - parseInt(LS.get(this.lastShowTimeName)) >=
        this.AD_interstitial_interval * 60 * 1000
    )
  }
  checkShow() {
    return this.checkShowTimes() && this.checkShowInterval()
  }
}
export const afgManager = new AFGManager()

//插入谷歌水印 https://support.google.com/programmable-search/answer/10028634?hl=en&ref_topic=4513743&sjid=2562926885426969258-AP
export function insertGoogleWatermark(elementId) {
  if (
    !location.search.includes('re=RU') &&
    !location.search.includes('re=ru')
  ) {
    var myScript = document.createElement('script')
    myScript.src = 'https://www.gstatic.com/prose/brand.js'
    myScript.setAttribute('targetId', elementId)
    document.head.append(myScript)
  }
}
