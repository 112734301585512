import axios from 'axios'
// import { apmTrackServerError } from '@/utils/utils'

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL || '',
  timeout: 30000, // 30s超时
})

instance.interceptors.response.use(
  (response) => {
    const { data, config } = response

    return config.getResponse ? response : data
  },
  (error) => {
    if (error && error.response) {
      // apmTrackServerError({
      //   message: error.message,
      //   code: error.response.status,
      //   extraInfos: error.config,
      // })
    }
    return Promise.reject(error)
  }
)

export default instance
