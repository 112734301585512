import { debounce } from 'lodash'
import eventTrackManager from '@/utils/eventTrackManager'
import { checkRegion } from '@/utils/utils'
import axios from 'axios'
import { generateAFCAd } from '@/utils/ads'
import { EVENT_TYPE } from '@/consts/eventTrack'

let trackItemsList = []

const trackExposureEvent = debounce(() => {
  if (!window.__trackedKeys) {
    window.__trackedKeys = []
  }

  const filteredList = trackItemsList.filter(
    (item) => !window.__trackedKeys.includes(item.key)
  )

  filteredList.forEach((item) => {
    window.__trackedKeys.push(item.key)

    const { eventType, params, impTrackUrl, addTrackUrl, costtime } = item

    eventTrackManager.track(eventType, params)
    if (eventType === EVENT_TYPE.NF_NEWS_ITEM_EXPOSURE) {
      window.mi_api?.report('nf_ex_list_item', '')
      if (params.number === 1) {
        console.log('costtime:', costtime)
        window.mi_api?.report('expose_time', JSON.stringify({ costtime }))
      }
    }

    checkImpTrackUrl(impTrackUrl, addTrackUrl)
  })

  trackItemsList = []
}, 100)

const checkImpTrackUrl = (impTrackUrl, addTrackUrl) => {
  if (!impTrackUrl) return

  if (checkRegion.isES()) {
    axios.get(addTrackUrl).catch((e) => {
      window.console.log('imp error:', e)
    })
  } else if (checkRegion.isRU()) {
    axios.get(impTrackUrl).catch((e) => {
      window.console.log('imp error:', e)
    })
  } else {
    axios.post(impTrackUrl).catch((e) => {
      window.console.log('imp error:', e)
    })
  }
}

export default function initExposureDirective(app) {
  // 添加曝光指令
  app.directive('exposure', {
    mounted(el, binding) {
      const value = binding.value || {}
      const {
        eventType,
        key,
        params,
        impTrackUrl,
        addTrackUrl,
        showAds,
        adsStr,
        adsClassName,
        threshold,
      } = value

      const intersectionObserver = new IntersectionObserver(
        function (entries) {
          entries.forEach((entry) => {
            if (entry.intersectionRatio >= (threshold || 0.3)) {
              if (
                !trackItemsList.find(
                  (item) => item.key === entries[0].target.__exposurePayload.key
                )
              ) {
                trackItemsList.push({
                  ...entries[0].target.__exposurePayload,
                  costtime: new Date().getTime() - window.apiDataTime,
                })
                // if (entries[0].target.__exposurePayload.params.number === 1) {
                //   console.log("exposure: ", new Date().getTime())
                // }
                trackExposureEvent()
                if (
                  showAds &&
                  el.querySelector(`.${adsClassName}`).innerHTML === ''
                ) {
                  generateAFCAd(adsStr, el.querySelector(`.${adsClassName}`))
                }
              }
            }
          })
        },
        { threshold: threshold || 0.3 }
      )

      intersectionObserver.observe(el)

      el.__exposurePayload = {
        eventType,
        key,
        params,
        impTrackUrl,
        addTrackUrl,
      }
    },
    updated(el, binding) {
      const value = binding.value || {}
      const { eventType, key, params, impTrackUrl, addTrackUrl } = value

      el.__exposurePayload = {
        eventType,
        key,
        params,
        impTrackUrl,
        addTrackUrl,
      }
    },
  })
}
