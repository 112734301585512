export default {
  searchPlaceholder: 'ရှာပါ သို့မဟုတ် URL ထည့်ပါ',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'MSN',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'ကြည်လင်',
  duoyun: 'တိမ်ထူ',
  yin: 'အုံ့မှိုင်း',
  wu: 'မြူနှင်း',
  tedabaoyu: 'မိုးသက်မုန်တိုင်းအလွန်ပြင်း',
  dabaoyu: 'မိုးသက်မုန်တိုင်းအပြင်း',
  baoyu: 'မိုးသက်မုန်တိုင်း',
  leizhenyu: 'မိုးကြိုးမုန်းတိုင်း',
  zhenyu: 'မိုးဖွဲ',
  dayu: 'မိုးသည်း',
  zhongyu: 'မိုးအသင့်အတင့်',
  xiaoyu: 'မိုးအနည်းငယ်',
  yujiaxue: 'နှင်းမိုး',
  baoxue: 'နှင်းမုန်တိုင်း',
  zhenxue: 'ဆီးနှင်းဖွဲ',
  daxue: 'နှင်းထူ',
  zhongxue: 'နှင်းအသင့်အတင့်',
  xiaoxue: 'နှင်းအနည်းငယ်',
  qiangshachenbao: 'သဲမုန်တိုင်းကြီး',
  shachenbao: 'သဲမုန်တိုင်း',
  shachen: 'သဲမုန်တိုင်းငယ်',
  yangsha: 'လေထန်',
  bingbao: 'မိုးသီး',
  fuchen: 'ဖုန်ထူ',
  mai: 'မြူကျ',
  dongyu: 'ရေခဲမိုး',
  xiaodaozhongyu: 'မိုးအနည်ငယ်မှ မိုးအသင့်အတင့်',
  zhongdaodayu: 'မိုးအသင့်အတင့်မှ မိုးသည်း',
  dadaobaoyu: 'မိုးသည်းမှ မိုးအလွန်သည်း',
  baoyudaodabaoyu: 'မိုးအလွန်သည်းမှ မိုးသည်းထန်',
  dabaoyudaotedabaoyu: 'မိုးသည်းထန်မှ အားပြင်းမိုးသက်မုန်တိုင်း',
  xiaodaozhongxue: 'နှင်းအနည်ငယ်မှ နှင်းအသင့်အတင့်',
  zhongdaodaxue: 'နှင်းအသင့်အတင့်မှ နှင်းသည်း',
  dadaobaoxue: 'နှင်းသည်းမှ နှင်းမုန်တိုင်း',
  nongwu: 'မြူဆိုင်း',
  qiangnongwu: 'မြူထူ',
  middlemai: 'မြူအသင့်အတင့်',
  zhongdumai: 'မြူသိပ်',
  yanzhongmai: 'မြူပိတ်',
  dawu: 'မြူသိပ်သည်း',
  teqiangnongwu: 'မြူပိတ်',
  yu: 'မိုး',
  xue: 'နှင်း',
  hoursForecast: 'မိုးလေဝသ',
  today: 'ယနေ့',
  tomorrow: 'မနက်ဖြန်',
  monday: 'တနင်္လာ',
  tuesday: 'အင်္ဂါ',
  wednesday: 'ဗုဒ္ဓဟူး',
  thursday: 'ကြာသပတေး',
  friday: 'သောကြာ',
  saturday: 'စနေ',
  sunday: 'တနင်္ဂနွေ',
  now: 'ယခု',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'ခေတ္တစောင့်ပါ…',
  laodError: 'ဒေတာတင်မရပါ။ ရီဖရက်(ရှ်)လုပ်ပြီး ထပ်လုပ်ကြည့်ပါ။',
  refresh: 'ရီဖရက်(ရှ်)လုပ်ရန်',
  guessULike: 'သင်ကြိုက်နိုင်သည်များ',
  setting: 'ဆက်တင်',
  changeHome: 'အစစာမျက်နှာပြောင်းရန်',
  changeTitle:
    'ဘရောက်ဇာကိုစိတ်ကြိုက်လုပ်နိုင်ရန် အစစာမျက်နှာကို အချိန်မရွေးပြောင်းပါ။',
  changeTip1:
    '၁။ ညာအပေါ်ထောင့်တွင် "အခြား" သင်္ကေတကို တို့ပြီး ဆက်တင်သို့သွားပါ။',
  changeTip2: '၂။ အဆင့်မြင့်ဆက်တင်တွင် အစစာမျက်နှာကို ရွေးပါ။',
  changeTip3:
    '၃။ Chrome အစစာမျက်နှာကိုရွေးပါ သို့မဟုတ် စိတ်ကြိုက်လုပ်လိုသော ဝက်ဘဆိုက်စာမျက်နှာကို ရွေးပါ။',
  about: 'အကြောင်း',
  aboutFirst:
    'homepage.miui.com သည့် သင့်ဘရောက်ဇာရှိ ရှာဖွေရေးဝန်ဆောင်မှုဖြစ်သည်။ မိုးလေဝသ၊ ရှာဖွေရေး၊ အမြန်လင့်များ၊ သတင်းများနှင့် အခြားအရာများ ပံ့ပိုးသည်။',
  aboutSecond:
    "တုံ့ပြန်အကြံပြုချက် သို့မဟုတ် အကြံဉာဏ်များရှိပါက homepagemiui{'@'}gmail.com သို့ စာပို့ပါ။ ဘရောက်ဇာအစစာမျက်နှာကိုလည်း ဆက်တင်တွင် အချိန်မရွေးပြောင်းနိုင်သည်။",
  suggest: 'တုံ့ပြန်အကြံပြုချက်',
  suggestTip: 'တုံ့ပြန်အကြံပြုချက်နှင့် အကြံဉာဏ်များထည့်ရန် တို့ပါ',
  mailTo: 'အီးမေး(လ်)လိပ်စာ',
  send: 'တင်ရန်',
  sendSuccess: 'အောင်မြင်စွာတင်ပြီးပါပြီ',
  weGet: 'သင့်တုံ့ပြန်အကြံပြုချက်ကို အမြန်ဆုံး ဆောင်ရွက်ပါမည်',
  ok: 'အိုကေ',
  newFuncTitle: 'အင်္ဂါရပ်အသစ်',
  newFunc:
    'homepage.miui.com သည် အစစာမျက်နှာအသစ်ဖြစ်သည်။ မိုးလေဝသ၊ သတင်း၊ ရှာဖွေရေးနှင့် အခြားအရာများ ရှာကြည့်ပါ။ အစစာမျက်နှာအဟောင်းသို့ ပြန်ပြောင်းနိုင်သည် သို့မဟုတ် ဆက်တင်တွင် စိတ်ကြိုက်လုပ်နိုင်သည်။',
  get: 'ရပါပြီ',
  searchHistory: 'ရှာဖွေမှတ်တမ်း',
  cancel: 'မလုပ်တော့ရန်',
  delete: 'ဖျက်ရန်',
  deleteHistoryDialogTitle: 'ရှာဖွေမှတ်တမ်းဖျက်ရန်',
  deleteHistoryDialogContent: 'ရှာဖွေမှတ်တမ်းကို ယခုအပြီးဖျက်လိုသလား။',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'ပိုမိုဖတ်ရှုရန်',
}
