import { createI18n } from 'vue-i18n'
import zh_CN from './zh_CN'
import en_US from './en_US'
import in_ID from './in_ID'
import pt_BR from './pt_BR'
import ru_RU from './ru_RU'
import vi_VN from './vi_VN'
import es_ES from './es_ES'
import en_GB from './en_GB'
import fr_FR from './fr_FR'
import de_DE from './de_DE'
import it_IT from './it_IT'
import fa_IR from './fa_IR'
import ur_IN from './ur_IN'
import ne_IN from './ne_IN'
import mr_IN from './mr_IN'
import hi_IN from './hi_IN'
import as_IN from './as_IN'
import bn_IN from './bn_IN'
import pa_IN from './pa_IN'
import gu_IN from './gu_IN'
import or_IN from './or_IN'
import ta_IN from './ta_IN'
import te_IN from './te_IN'
import kn_IN from './kn_IN'
import ml_IN from './ml_IN'
import tr_TR from './tr_TR'
import es_rUS from './es_rUS'
import ar from './ar'
import bn from './bn'
import ms from './ms'
import my from './my'
import { checkRegion } from '@/utils/utils'

// 根据浏览器的语言匹配静态多语言文案
const getLocale = () => {
  // 印度国家的小语种类型
  const inLanguagesOption = [
    'ur',
    'ne',
    'mr',
    'hi',
    'as',
    'bn',
    'pa',
    'gu',
    'or',
    'ta',
    'te',
    'kn',
    'ml',
  ]
  // 简单语言是指：从navigator.language中取出来没有歧义的
  const simpleLanguage = [
    'zh',
    'in',
    'ru',
    'vi',
    'fr',
    'de',
    'it',
    'fa',
    'tr',
    'ar',
    'bn',
    'ms',
    'my',
  ]
  // 印度国家进行小语种判断
  if (checkRegion.isIN()) {
    const lang = navigator.language?.split('-')[0]
    return inLanguagesOption.includes(lang) ? `${lang}_IN` : 'en-US'
  }
  // 西班牙语 需区分西班牙国家（es-ES）和其他拉美等国家（es-rUS）
  if (/^es-/i.test(navigator.language)) {
    return navigator.language === 'es-ES' ? 'es-ES' : 'es-rUS'
  }
  // 菲律宾（miui不支持菲律宾，所以没有菲律宾的翻译人员，除了首页搜索框文案全用英文）
  if (/^fil/i.test(navigator.language)) {
    return 'en-US'
  }
  if (/^pt-BR$/i.test(navigator.language)) {
    return 'pt-BR'
  }
  // if (/^pt-PT$/i.test(navigator.language)) {
  //   return 'pt-PT'
  // }
  if (/^en-GB$/i.test(navigator.language)) {
    return 'en-GB'
  }

  const lang = navigator.language?.split('-')[0]
  return simpleLanguage.includes(lang) ? lang : 'en-US'
}

const messages = {
  zh: zh_CN,
  'en-US': en_US,
  in: in_ID,
  'pt-BR': pt_BR, // 葡萄牙语（巴西）
  // 'pt-PT': pt_PT, // 葡萄牙语（葡萄牙）
  ru: ru_RU,
  vi: vi_VN,
  'es-ES': es_ES, // 西班牙语（西班牙）
  'es-rUS': es_rUS, // 西班牙语（拉美等其他国家）
  'en-GB': en_GB, // 英语（英国）
  fr: fr_FR,
  de: de_DE,
  it: it_IT,
  fa: fa_IR,
  ur_IN,
  ne_IN,
  mr_IN,
  hi_IN,
  as_IN,
  bn_IN,
  pa_IN,
  gu_IN,
  or_IN,
  ta_IN,
  te_IN,
  kn_IN,
  ml_IN,
  tr: tr_TR,
  ar,
  bn,
  ms,
  my,
}

const i18n = createI18n({
  locale: getLocale(), // set locale
  fallbackLocale: 'en-US', // set fallback locale
  messages,
})

export default i18n
