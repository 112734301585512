export default {
  searchPlaceholder: 'جستجو یا وارد کردن URL',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'صاف',
  duoyun: 'ابری',
  yin: 'تمام‌ابری',
  wu: 'غبار',
  tedabaoyu: 'رگبار شدید',
  dabaoyu: 'رگبار شدید',
  baoyu: 'رگبار باران',
  leizhenyu: 'رعد و برق',
  zhenyu: 'بارانی',
  dayu: 'باران شدید',
  zhongyu: 'باران ملایم',
  xiaoyu: 'باران سبک',
  yujiaxue: 'برف و باران',
  baoxue: 'کولاک',
  zhenxue: 'بارش برف',
  daxue: 'برف سنگین',
  zhongxue: 'برف شدید',
  xiaoxue: 'برف سبک',
  qiangshachenbao: 'طوفان شدید شن',
  shachenbao: 'طوفان شن',
  shachen: 'طوفان سبک شن',
  yangsha: 'طوفانی',
  bingbao: 'تگرگ',
  fuchen: 'گرد و غبار',
  mai: 'مه',
  dongyu: 'باران یخی',
  xiaodaozhongyu: 'باران خفیف تا متوسط',
  zhongdaodayu: 'باران متوسط تا سنگین',
  dadaobaoyu: 'باران سنگین تا سیل‌آسا',
  baoyudaodabaoyu: 'باران سیل‌آسا تا رگبار',
  dabaoyudaotedabaoyu: 'بارش باران تا طوفان شدید',
  xiaodaozhongxue: 'برف ملایم تا متوسط',
  zhongdaodaxue: 'برف متوسط تا سنگین',
  dadaobaoxue: 'برف سنگین تا کولاک',
  nongwu: 'مه',
  qiangnongwu: 'مه رقیق',
  middlemai: 'مه متوسط',
  zhongdumai: 'مه سنگین',
  yanzhongmai: 'مه شدید',
  dawu: 'مه غلیظ',
  teqiangnongwu: 'مه شدید',
  yu: 'بارانی',
  xue: 'برفی',
  hoursForecast: 'آب و هوا',
  today: 'امروز',
  tomorrow: 'فردا',
  monday: 'دوشنبه',
  tuesday: 'سه‌شنبه',
  wednesday: 'چهارشنبه',
  thursday: 'پنج‌شنبه',
  friday: 'جمعه',
  saturday: 'شنبه',
  sunday: 'یک‌شنبه',
  now: 'اکنون',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'چند لحظه صبر کنید…',
  laodError: 'داده‌ها بارگذاری نشدند. برای تلاش دوباره تازه‌سازی کنید.',
  refresh: 'تازه‌سازی',
  guessULike: 'شاید بپسندید',
  setting: 'تنظیمات',
  changeHome: 'تغییر صفحه آغاز',
  changeTitle:
    'صفحه آغاز خود را هر زمان خواستید تغییر داده تا مرورگر را از آن خودتان کنید.',
  changeTip1:
    '۱. در گوشه سمت راست بالا، روی آیکون «بیشتر» زده و به تنظیمات بروید.',
  changeTip2: '۲. در تنظیمات پیشرفته صفحه آغاز را انتخاب کنید.',
  changeTip3:
    '۳. برای شخصی‌سازی آن، صفحه آغاز Chrome را انتخاب کرده و یا هر تارنمایی را وارد کنید.',
  about: 'درباره',
  aboutFirst:
    'homepage.miui.com یک خدمات ناوبری در مرورگر شماست. به شما خدمات آب و هوا، جستجو، لینک‌های فوری، اخبار و موارد بیشتری ارائه می‌دهد.',
  aboutSecond:
    "در صورت داشتن هرگونه بازخورد و یا پیشنهاد، می‌توانید با نوشتن چند خط و ارسال آن به نشانی homepagemiui{'@'}gmail.com ما را آگاه نمایید. همچنین در هر زمانی می‌توانید صفحه آغاز مرورگر خود را در تنظیمات تغییر دهید.",
  suggest: 'بازخورد',
  suggestTip: 'برای افزودن بازخورد و یا پیشنهادات ضربه بزنید',
  mailTo: 'نشانی ایمیل',
  send: 'ارسال',
  sendSuccess: 'با موفقیت ارسال شد',
  weGet: 'ما در اسرع وقت بازخورد شما را بررسی می‌کنیم',
  ok: 'تایید',
  newFuncTitle: 'ویژگی جدید',
  newFunc:
    'homepage.miui.com یک صفحه آغاز کاملاً جدید دارد. آب و هوا و اخبار، جستجو و موارد دیگر را مرور کنید. در تنظیمات می‌توانید به صفحه آغاز قدیمی باز گشته و یا آن را شخصی‌سازی کنید.',
  get: 'متوجه‌ام',
  searchHistory: 'تاریخچه جستجو',
  cancel: 'لغو',
  delete: 'حذف',
  deleteHistoryDialogTitle: 'حذف تاریخچه جستجو',
  deleteHistoryDialogContent: 'تاریخچه جستجو برای همیشه حذف شود؟',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'اطلاعات بیشتر',
}
