export default {
  searchPlaceholder: 'Search or type web address',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'xe chạy',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'Cây bấc',
  siteDefaultGame3: 'toa',
  siteDefaultGame4: 'Mực ống',
  siteDefaultGame5: 'Sơn',
  qing: 'Quang',
  duoyun: 'Nhiều mây',
  yin: 'Âm u',
  wu: 'Sương mù',
  tedabaoyu: 'Mưa bão dữ dội',
  dabaoyu: 'Mưa bão lớn',
  baoyu: 'Mưa bão',
  leizhenyu: 'Dông',
  zhenyu: 'Mưa rào',
  dayu: 'Mưa lớn',
  zhongyu: 'Mưa vừa',
  xiaoyu: 'Mưa nhỏ',
  yujiaxue: 'Mưa tuyết',
  baoxue: 'Bão tuyết',
  zhenxue: 'Tuyết nhỏ',
  daxue: 'Tuyết rơi dày',
  zhongxue: 'Tuyết rơi vừa',
  xiaoxue: 'Tuyết rơi nhẹ',
  qiangshachenbao: 'Bão cát mạnh',
  shachenbao: 'Bão cát',
  shachen: 'Bão cát nhẹ',
  yangsha: 'Gió',
  bingbao: 'Mưa đá',
  fuchen: 'Bụi',
  mai: 'Khói mù',
  dongyu: 'Mưa đá',
  xiaodaozhongyu: 'Mưa nhỏ đến mưa vừa',
  zhongdaodayu: 'Mưa vừa đến mưa to',
  dadaobaoyu: 'Mưa to đến mưa xối xả',
  baoyudaodabaoyu: 'Mưa xối xả đến mưa như trút nước',
  dabaoyudaotedabaoyu: 'Mưa như trút nước đến mưa bão cực độ',
  xiaodaozhongxue: 'Tuyết rơi nhẹ đến vừa',
  zhongdaodaxue: 'Tuyết rơi vừa đến tuyết dày',
  dadaobaoxue: 'Tuyết dày đến bão tuyết',
  nongwu: 'Sương mù dày đặc',
  qiangnongwu: 'Sương mù dày đặc',
  middlemai: 'Khói mù vừa',
  zhongdumai: 'Khói mù dày',
  yanzhongmai: 'Khói mù nghiêm trọng',
  dawu: 'Sương mù dày',
  teqiangnongwu: 'Sương mù cực dày',
  yu: 'Mưa',
  xue: 'Tuyết rơi',
  hoursForecast: 'Thời tiết',
  today: 'Hôm nay',
  tomorrow: 'Ngày mai',
  monday: 'Th 2',
  tuesday: 'Th 3',
  wednesday: 'Th 4',
  thursday: 'Th 5',
  friday: 'Th 6',
  saturday: 'Th 7',
  sunday: 'Cn',
  now: 'Hiện tại',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'Vui lòng chờ…',
  laodError: 'Không thể tải dữ liệu. Làm mới và thử lại.',
  refresh: 'Làm mới',
  guessULike: 'Bạn có thể thích',
  setting: 'Cài đặt',
  changeHome: 'Thay đổi Trang bắt đầu',
  changeTitle:
    'Thay đổi Trang bắt đầu bất kỳ lúc nào để biến trình duyệt thành của bạn.',
  changeTip1:
    '1. Ở góc trên cùng bên phải, chạm biểu tượng "Thêm" và vào Cài đặt.',
  changeTip2: '2. Chọn Trang bắt đầu trong Cài đặt nâng cao.',
  changeTip3:
    '3. Chọn Trang bắt đầu của Chrome hoặc chọn bất kỳ trang web nào để tùy chỉnh.',
  about: 'Giới thiệu',
  aboutFirst:
    'homepage.miui.com là một dịch vụ điều hướng trên trình duyệt. Dịch vụ này cung cấp thời tiết, tìm kiếm, liên kết nhanh, tin tức, v.v.',
  aboutSecond:
    "Nếu bạn có bất kỳ phản hồi hoặc kiến nghị nào, đừng do dự cho chúng tôi biết tại homepagemiui{'@'}gmail.com. Bạn cũng có thể thay đổi Trang bắt đầu của trình duyệt bất kỳ lúc nào trong Cài đặt.",
  suggest: 'Phản hồi',
  suggestTip: 'Chạm để thêm phản hồi và kiến nghị',
  mailTo: 'Địa chỉ email',
  send: 'Gửi',
  sendSuccess: 'Gửi thành công',
  weGet: 'Chúng tôi sẽ xử lý phản hồi của bạn trong thời gian sớm nhất',
  ok: 'OK',
  newFuncTitle: 'Tính năng mới',
  newFunc:
    'homepage.miui.com sở hữu Trang bắt đầu hoàn toàn mới. Duyệt thời tiết và tin tức, tìm kiếm, v.v. Bạn có thể chuyển về Trang bắt đầu cũ hoặc cá nhân hóa trong Cài đặt.',
  get: 'Tôi biết',
  searchHistory: 'Lịch sử tìm kiếm',
  cancel: 'Hủy',
  delete: 'Xóa',
  deleteHistoryDialogTitle: 'Xóa lịch sử tìm kiếm',
  deleteHistoryDialogContent: 'Xóa vĩnh viễn lịch sử tìm kiếm ngay?',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'Đọc thêm',
}
