function getWindowData(param, defineValue) {
  return window[param] !== undefined ? window[param] : defineValue
}
//配置AB实验时的变量名, 比如window.searchTypeForRU = 1
// window.adType = 1 代表信息流样式，2代表banner正方形，3代表banner横向
export const adType = getWindowData('adType', 2)
//天气卡abTest  weather_type为1显示三方天气卡，为2显示小米天气卡
export const weatherType = getWindowData('weather_type', 2)
//详情页落地页abTest weather_land_type为1显示三方天气卡，为2显示小米天气卡
export const weatherLandType = getWindowData('weather_land_type', 2)
// afg_type=1代表跳转游戏名站和资讯详情二级页时使用子域名（为了出穿插广告），afg_type=2代表跳转游戏名站（第二排名站）和资讯详情二级页时使用原域名并在二级页展示插屏广告
// afg_type=3代表跳转所有名站（第一排+第二排）和资讯详情二级页时使用三方域名并在跳转前展示插屏广告（目前只在越南地区实验）
export const afgType = getWindowData('afg_type', 3)
// 主页类型：news代表资讯流，ytb代表视频流
// export const homeType = getWindowData('home_type', 'ytb')
//搜索源abTest: 1代表PSE，2代表google搜索
export const searchType = getWindowData('search_type', 1)
//搜索词sug的abTest: 1代表有推荐，2代表没有推荐
export const sugType = getWindowData('sug_type', 1)
//pwa: 1代表有pwa功能，2代表没有pwa
export const pwaType = getWindowData('pwa', 2)
//是否具备push能力，1代表不具备，2代表具备
export const pushType = getWindowData('push_type', 1)
