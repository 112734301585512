import ABTest from '@mi/global-sdks/lib/abTest'
import { getCookie, getUUID } from '@/utils/utils'
// 印尼国家 ABTEst中间页效果 value：'1' 代表有中间页，其余值无中间页
export const middleABConfig = {
  debug: true,
  name: '信息流中间页实验',
  objectName: 'middleABTest',
  baseId: getCookie('_ga'),
  path: '',
  // query: 're=ID',
  groups: [
    {
      rate: '10%',
      code: '0',
    },
    {
      rate: '50%',
      code: '1',
    },
    {
      rate: '40%',
      code: '2',
    },
  ],
}
// 非俄地区，PSE搜索 or CSA搜索
/*
export const pseABConfig = {
  name: '搜索搜索实验',
  objectName: 'pseABTest',
  baseId: getCookie('_ga'),
  path: '',
  groups: [
    {
      rate: '10%',
      code: '0',
    },
    {
      rate: '90%',
      code: '1',
    },
  ],
}
*/
// CSA搜索时，是否有搜索推荐 实验
export const csaRelatedABConfig = {
  name: '搜索相关推荐实验',
  objectName: 'csaRelatedABConfig',
  baseId: getCookie('_ga') !== '' ? getCookie('_ga') : getUUID(),
  path: '',
  groups: [
    {
      rate: '100%', // 无搜索推荐
      code: '0',
    },
    {
      rate: '0%', // 有搜索推荐
      code: '1',
    },
  ],
}

// 谷歌样式 实验
export const googleStyleABConfig = {
  name: '谷歌样式实验',
  objectName: 'googleStyleABConfig',
  baseId: getCookie('_ga') !== '' ? getCookie('_ga') : getUUID(),
  path: '',
  groups: [
    {
      rate: '100%', // styleId: 9984443323
      code: '0',
    },
    {
      rate: '0%', // styleId: 4817146887
      code: '1',
    },
  ],
}

export function getMiABResult(abConfig) {
  const bannerStyleABTest = new ABTest(abConfig)
  const res = bannerStyleABTest.getResult()

  return res
}
