export default {
  searchPlaceholder: 'Rechercher ou saisir une URL',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'Dégagé',
  duoyun: 'Nuageux',
  yin: 'Couvert',
  wu: 'Brume',
  tedabaoyu: 'Fortes pluies',
  dabaoyu: 'Forte pluie torrentielle',
  baoyu: 'Pluie torrentielle',
  leizhenyu: 'Orage',
  zhenyu: 'Averses',
  dayu: 'Forte pluie',
  zhongyu: 'Pluie modérée',
  xiaoyu: 'Pluie légère',
  yujiaxue: 'Giboulée',
  baoxue: 'Tempête de neige',
  zhenxue: 'Averses de neige',
  daxue: 'Fortes chutes de neige',
  zhongxue: 'Chutes de neige modérées',
  xiaoxue: 'Neige fine',
  qiangshachenbao: 'Forte tempête de sable',
  shachenbao: 'Tempête de sable',
  shachen: 'Légère tempête de sable',
  yangsha: 'Venteux',
  bingbao: 'Grêle',
  fuchen: 'Poussière',
  mai: 'Brume',
  dongyu: 'Pluie verglaçante',
  xiaodaozhongyu: 'Pluie légère à modérée',
  zhongdaodayu: 'Pluie modérée à forte',
  dadaobaoyu: 'Pluie forte à torrentielle',
  baoyudaodabaoyu: 'Pluie torrentielle à averse',
  dabaoyudaotedabaoyu: 'Averses à pluie extrême',
  xiaodaozhongxue: 'Neige légère à modérée',
  zhongdaodaxue: 'Neige modérée à forte',
  dadaobaoxue: 'Neige forte à blizzard',
  nongwu: 'Brouillard',
  qiangnongwu: 'Brouillard épais',
  middlemai: 'Brume modérée',
  zhongdumai: 'Forte brume',
  yanzhongmai: 'Brume sévère',
  dawu: 'Brouillard dense',
  teqiangnongwu: 'Brouillard intense',
  yu: 'Pluie',
  xue: 'Neige',
  hoursForecast: 'Météo',
  today: `Aujourd'hui`,
  tomorrow: 'Demain',
  monday: 'Lun.',
  tuesday: 'Mar.',
  wednesday: 'Mer.',
  thursday: 'Jeu.',
  friday: 'Ven.',
  saturday: 'Sam.',
  sunday: 'Dim.',
  now: 'Maintenant',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'Un instant…',
  laodError: 'Impossible de charger les données. Actualisez pour réessayer.',
  refresh: 'Actualiser',
  guessULike: 'Vous pourriez aimer',
  setting: 'Paramètres',
  changeHome: "Modifier la Page d'accueil",
  changeTitle:
    'Modifiez votre page d’accueil à tout moment pour rendre votre navigateur entièrement à vous.',
  changeTip1:
    '1. Dans le coin supérieur droit, appuyez sur l\'icône "Plus" et accédez à Paramètres.',
  changeTip2: "2. Sélectionnez Page d'accueil dans Paramètres avancés.",
  changeTip3:
    '3. Sélectionnez la Page d’accueil Chrome ou choisissez une page Web pour la personnaliser.',
  about: 'À propos',
  aboutFirst:
    "homepage.miui.com est un service de navigation dans votre navigateur. Il fournit la météo, la recherche, les liens rapides, l'actualité et plus encore.",
  aboutSecond:
    "Si vous avez des commentaires ou des suggestions, n'hésitez pas à nous écrire à homepagemiui{'@'}gmail.com. Vous pouvez également modifier la Page d’accueil de votre navigateur à tout moment dans Paramètres.",
  suggest: 'Commentaire',
  suggestTip: 'Appuyez pour ajouter des commentaires et des suggestions',
  mailTo: 'Adresse e-mail',
  send: 'Soumettre',
  sendSuccess: 'Soumis avec succès',
  weGet: 'Nous traiterons vos commentaires dès que possible',
  ok: 'OK ',
  newFuncTitle: 'Nouvelle fonctionnalité',
  newFunc:
    "homepage.miui.com a une toute nouvelle Page d'accueil. Parcourez la météo et l'actualité, la recherche et plus encore. Vous pouvez revenir à l’ancienne Page d'accueil ou la personnaliser dans Paramètres.",
  get: "C'est compris",
  searchHistory: 'Historique de recherche',
  cancel: 'Annuler',
  delete: 'Supprimer',
  deleteHistoryDialogTitle: "Supprimer l'historique de recherche",
  deleteHistoryDialogContent:
    "Supprimer définitivement l'historique de recherche maintenant ?",
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'Lire plus',
}
