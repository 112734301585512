export default {
  searchPlaceholder: 'Введите URL или поисковый запрос',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'Википедия',
  siteMsn: 'MSN',
  siteGames: 'Игры',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'YouTube',
  siteNetflix: 'Netflix',
  siteTiktok: 'TikTok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Яндекс',
  siteDzen: 'Дзен',
  siteVK: 'ВКонтакте',
  siteMail: 'Mail.ru',
  siteOK: 'Одноклассники',
  siteOzon: 'OZON',
  siteAvito: 'Авито',
  siteYa: 'Яндекс',
  mvpVideoDW: 'Загрузка видео',
  mvpGames: 'Игры',
  mvpSports: 'Спорт',
  siteLazada: 'lazada',
  siteYandexSearch: 'Поиск',
  hotNews: 'Популярно',
  siteGame1: 'отруб',
  siteGame2: 'вот так',
  siteGame3: 'оккупац',
  siteGame4: 'корол',
  siteGame5: 'Площадь',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'Ясно',
  duoyun: 'Облачно',
  yin: 'Пасмурно',
  wu: 'Дымка',
  tedabaoyu: 'Сильный ливень',
  dabaoyu: 'Очень сильный ливень',
  baoyu: 'Ливень',
  leizhenyu: 'Гроза',
  zhenyu: 'Ливни',
  dayu: 'Сильный дождь',
  zhongyu: 'Умеренный дождь',
  xiaoyu: 'Небольшой дождь',
  yujiaxue: 'Мокрый снег',
  baoxue: 'Метель',
  zhenxue: 'Снегопад',
  daxue: 'Сильный снег',
  zhongxue: 'Умеренный снег',
  xiaoxue: 'Небольшой снег',
  qiangshachenbao: 'Сильная пыльная буря',
  shachenbao: 'Пыльная буря',
  shachen: 'Небольшая пыльная буря',
  yangsha: 'Ветрено',
  bingbao: 'Град',
  fuchen: 'Пыль',
  mai: 'Смог',
  dongyu: 'Изморозь',
  xiaodaozhongyu: 'От небольшого до умеренного дождя',
  zhongdaodayu: 'От умеренного до сильного дождя',
  dadaobaoyu: 'От сильного до проливного дождя',
  baoyudaodabaoyu: 'От проливного дождя до сильного ливня',
  dabaoyudaotedabaoyu: 'От сильного ливня до очень сильного ливня',
  xiaodaozhongxue: 'От небольшого до умеренного снега',
  zhongdaodaxue: 'От умеренного до сильного снега',
  dadaobaoxue: 'От сильного снега до метели',
  nongwu: 'Туман',
  qiangnongwu: 'Плотный туман',
  middlemai: 'Умеренная дымка',
  zhongdumai: 'Сильная дымка',
  yanzhongmai: 'Очень сильная дымка',
  dawu: 'Густой туман',
  teqiangnongwu: 'Сильный туман',
  yu: 'Дождь',
  xue: 'Снег',
  hoursForecast: 'Погода',
  today: 'Сегодня',
  tomorrow: 'Завтра',
  monday: 'ПН',
  tuesday: 'ВТ',
  wednesday: 'СР',
  thursday: 'ЧТ',
  friday: 'ПТ',
  saturday: 'СБ',
  sunday: 'ВС',
  now: 'Сейчас',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'Секунду…',
  laodError: 'Не удалось загрузить данные. Обновите, чтобы повторить попытку.',
  refresh: 'Обновить',
  guessULike: 'Возможно вам понравится',
  setting: 'Настройки',
  changeHome: 'Измените главную страницу',
  changeTitle:
    'Измените главную страницу, чтобы персонализировать свой браузер.',
  changeTip1:
    '1. Нажмите значок "Еще" в верхнем правом углу и перейдите в Настройки.',
  changeTip2:
    '2. Откройте "Расширенные настройки" и выберите "Главная страница".',
  changeTip3:
    '3. Выберите главную страницу Chrome или другую веб-страницу, которую хотите настроить.',
  about: 'Сведения',
  aboutFirst:
    'homepage.miui.com — служба навигации браузера. Она предоставляет информацию о погоде, поиск, быстрые ссылки, новости и другие функции.',
  aboutSecond:
    'Если вы хотите оставить отзыв или поделиться своим предложением, напишите нам на эл. почту homepagemiui{\'@\'}gmail.com. Вы также можете в любое время изменить главную страницу своего браузера в разделе "Настройки".',
  suggest: 'Отзыв',
  suggestTip: 'Нажмите, чтобы добавить отзыв или предложение',
  mailTo: 'Адрес эл. почты',
  send: 'Отправить',
  sendSuccess: 'Отправлено',
  weGet: 'Мы обработаем ваш отзыв в кратчайшие сроки',
  ok: 'OK',
  newFuncTitle: 'Новая функция',
  newFunc:
    'У homepage.miui.com появилась новая главная страница. Теперь вы можете просматривать новости, информацию о погоде и многое другое. Вы можете вернуться к использованию старой версии главной страницы или настроить ее в Настройках.',
  get: 'Понятно',
  searchHistory: 'История поиска',
  cancel: 'Отмена',
  delete: 'Удалить',
  deleteHistoryDialogTitle: 'Очистить историю поиска',
  deleteHistoryDialogContent: 'Удалить записи истории поиска? ',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'Подробнее',
}
