export default {
  searchPlaceholder: 'URL ସନ୍ଧାନ କରନ୍ତୁ ବା ଦିଅନ୍ତୁ',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'Elastic Car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'ସଫା',
  duoyun: 'ମେଘୁଆ',
  yin: 'ମେଘାଛନ୍ନ',
  wu: 'ମିଷ୍ଟ',
  tedabaoyu: 'ପ୍ରଚଣ୍ଡ ଝଡ଼ବର୍ଷା',
  dabaoyu: 'ଭାରି ଝଡ଼ବର୍ଷା',
  baoyu: 'ଝଡ଼ବର୍ଷା',
  leizhenyu: 'ବିଜୁଳି ସହ ଘଡ଼ଘଡ଼ି ବର୍ଷା',
  zhenyu: 'ହାଲୁକା ବର୍ଷା',
  dayu: 'ପ୍ରବଳ ବର୍ଷା',
  zhongyu: 'ମଧ୍ୟମ ବର୍ଷା',
  xiaoyu: 'ଅଳ୍ପ ବର୍ଷା',
  yujiaxue: 'କୁଆପଥର ବର୍ଷା',
  baoxue: 'ପବନ ସହିତ ତୁଷାରପାତ',
  zhenxue: 'ହାଲୁକା ତୁଷାରପାତ',
  daxue: 'ପ୍ରବଳ ତୁଷାରପାତ',
  zhongxue: 'ମଧ୍ୟମ ତୁଷାରପାତ',
  xiaoxue: 'ଅଳ୍ପ ତୁଷାରପାତ',
  qiangshachenbao: 'ପ୍ରଚଣ୍ଡ ଧୂଳିଝଡ଼',
  shachenbao: 'ଧୂଳିଝଡ଼',
  shachen: 'ହାଲୁକା ଧୂଳିଝଡ଼',
  yangsha: 'ପ୍ରବଳ ପବନ',
  bingbao: 'କରକାପାତ',
  fuchen: 'ଧୂଳି',
  mai: 'କୁହୁଡ଼ି',
  dongyu: 'ହିମ ବର୍ଷା',
  xiaodaozhongyu: 'ଅଳ୍ପରୁ ମଧ୍ୟମ ବର୍ଷା',
  zhongdaodayu: 'ମଧ୍ୟମରୁ ପ୍ରବଳ ବର୍ଷା',
  dadaobaoyu: 'ପ୍ରବଳରୁ ମୂଷଳାଧାର ବର୍ଷା',
  baoyudaodabaoyu: 'ମୂଷଳାଧାରାରୁ ଅତିପ୍ରବଳ ବର୍ଷା',
  dabaoyudaotedabaoyu: 'ଅତିପ୍ରବଳରୁ ଅତି ଝଡ଼ବର୍ଷା',
  xiaodaozhongxue: 'ଅଳ୍ପରୁ ମଧ୍ୟମ ତୁଷାରପାତ',
  zhongdaodaxue: 'ମଧ୍ୟମରୁ ପ୍ରବଳ ତୁଷାରପାତ',
  dadaobaoxue: 'ପ୍ରବଳ ତୁଷାରପାତରୁ ପବନ ସହ ତୁଷାରପାତ',
  nongwu: 'କୁହୁଡ଼ି',
  qiangnongwu: 'ଘନ କୁହୁଡ଼ି',
  middlemai: 'ମଧ୍ୟମ କୁହୁଡ଼ି',
  zhongdumai: 'ପ୍ରବଳ କୁହୁଡ଼ି',
  yanzhongmai: 'ଅତିପ୍ରବଳ କୁହୁଡ଼ି',
  dawu: 'ଘନ କୁହୁଡ଼ି',
  teqiangnongwu: 'ଅତିମାତ୍ରାରେ କୁହୁଡ଼ି',
  yu: 'ବର୍ଷା',
  xue: 'ତୁଷାରପାତ',
  hoursForecast: 'ପାଣିପାଗ',
  today: 'ଆଜି',
  tomorrow: 'ଆସନ୍ତାକାଲି',
  monday: 'ସୋମ',
  tuesday: 'ମଙ୍ଗଳ',
  wednesday: 'ବୁଧ',
  thursday: 'ଗୁରୁ',
  friday: 'ଶୁକ୍ର',
  saturday: 'ଶନି',
  sunday: 'ରବି',
  now: 'ବର୍ତ୍ତମାନ',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'ଗୋଟିଏ ସେକେଣ୍ଡ…',
  laodError: 'ଡାଟା ଲୋଡ ହୋଇପାରିଲା ନାହିଁ। ପୁଣି ଚେଷ୍ଟା କରିବାକୁ ରିଫ୍ରେସ କରନ୍ତୁ।',
  refresh: 'ରିଫ୍ରେସ କରନ୍ତୁ',
  guessULike: 'ଆପଣ ପସନ୍ଦ କରିପାରନ୍ତି',
  setting: 'ସେଟିଂସ୍‌',
  changeHome: 'ଆରମ୍ଭ ପୃଷ୍ଠା ବଦଳାନ୍ତୁ',
  changeTitle:
    'ଆପଣଙ୍କ ବ୍ରାଉଜରକୁ ସମ୍ପୂର୍ଣ୍ଣ ରୂପେ ନିଜର କରିବାକୁ ଯେ କୌଣସି ସମୟରେ ଆପଣଙ୍କ ଆରମ୍ଭ ପୃଷ୍ଠା ବଦଳାନ୍ତୁ।',
  changeTip1:
    '1. ଉପର ଡାହାଣ କଣର ଉପରେ "ଅଧିକ" ଆଇକନକୁ ଟ୍ୟାପ କରନ୍ତୁ ଏବଂ ସେଟିଂସକୁ ଯାଆନ୍ତୁ।',
  changeTip2: '2. ଉନ୍ନତ ସେଟିଂସରେ ଆରମ୍ଭ ପୃଷ୍ଠା ଚୟନ କରନ୍ତୁ।',
  changeTip3:
    '3. Chrome ଆରମ୍ଭ ପୃଷ୍ଠା ଚୟନ କରନ୍ତୁ ବା ତାକୁ କଷ୍ଟମାଇଜ କରିବାକୁ ଯେ କୌଣସି ୱେବ ପୃଷ୍ଠା ବାଛନ୍ତୁ।',
  about: 'ବିଷୟରେ',
  aboutFirst:
    'ଆପଣଙ୍କ ବ୍ରାଉଜରରେ homepage.miui.com ହେଉଛି ଏକ ନେଭିଗେସନ ସେବା। ଏହା ପାଣିପାଗ, ସନ୍ଧାନ, କୁଇକ ଲିଙ୍କ, ନ୍ୟୁଜ ଏବଂ ଅନେକ କିଛି ପ୍ରଦାନ କରିଥାଏ।',
  aboutSecond:
    "ଆପଣଙ୍କର ଯଦି କୌଣସି ଫିଡବ୍ୟାକ ବା ପରାମର୍ଶ ଅଛି, ତେବେ homepagemiui{'@'}gmail.comକୁ ଲେଖନ୍ତୁ। ଆପଣ ସେଟିଂସରେ ଯେ କୌଣସି ସମୟରେ ଆପଣଙ୍କ ବ୍ରାଉଜର ଆରମ୍ଭ ପୃଷ୍ଠା ମଧ୍ୟ ବଦଳାଇପାରିବେ।",
  suggest: 'ଫିଡବ୍ୟାକ୍',
  suggestTip: 'ଫିଡବ୍ୟାକ୍ ଏବଂ ପରାମର୍ଶ ଯୋଗ କରିବାକୁ ଟ୍ୟାପ କରନ୍ତୁ',
  mailTo: 'ଇମେଲ ଠିକଣା',
  send: 'ଦାଖଲ କରନ୍ତୁ',
  sendSuccess: 'ସଫଳତାର ସହିତ ଦାଖଲ ହେଲା',
  weGet: 'ଯେତେ ଶୀଘ୍ର ସମ୍ଭବ ଆମେ ଆପଣଙ୍କ ଫିଡବ୍ୟାକ୍ ପ୍ରୋସେସ କରିବୁ',
  ok: 'ଓକେ',
  newFuncTitle: 'ନୂତନ ଫିଚର୍',
  newFunc:
    'homepage.miui.comର ଏକ ନୂତନ ଆରମ୍ଭ ପୃଷ୍ଠା ଅଛି। ପାଣିପାଗ ଏବଂ ନ୍ୟୁଜ ବ୍ରାଉଜ କରନ୍ତୁ, ସନ୍ଧାନ କରନ୍ତୁ ଏବଂ ଅନେକ କିଛି କରନ୍ତୁ। ଆପଣ ପୁରୁଣା ଆରମ୍ଭ ପୃଷ୍ଠାକୁ ସୁଇଚ ବ୍ୟାକ କରିପାରିବେ ବା ତାକୁ ସେଟିଂସରେ ବ୍ୟକ୍ତିଗତକରଣ କରିପାରିବେ।',
  get: 'ଜାଣିଗଲି',
  searchHistory: 'ସନ୍ଧାନ ଇତିହାସ',
  cancel: 'ବାତିଲ କରନ୍ତୁ',
  delete: 'ଡିଲିଟ୍',
  deleteHistoryDialogTitle: 'ସନ୍ଧାନ ଇତିହାସ ଡିଲିଟ କରନ୍ତୁ',
  deleteHistoryDialogContent: 'ବର୍ତ୍ତମାନ ସନ୍ଧାନ ଇତିହାସ ସ୍ଥାୟୀରୂପେ ଡିଲିଟ କରିବେ?',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'ଅଧିକ ପଢ଼ନ୍ତୁ',
}
