<template>
  <router-view v-slot="{ Component }">
    <keep-alive exclude="middle">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script setup></script>

<style scoped lang="scss"></style>
