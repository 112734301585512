<template>
  <img class="lazyload" ref="imgRef" :data-src="src" alt="" />
</template>
<script>
export default {
  name: 'LazyloadImage',
}
</script>
<script setup>
import { ref, onMounted } from 'vue'
import LazyLoad from './lazyload.js'

defineProps({
  src: {
    type: [String, URL],
    default: '',
  },
})

const imgRef = ref(null)

onMounted(() => {
  new LazyLoad([imgRef.value])
})
</script>
