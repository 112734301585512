import { UUID_KEY } from '@/consts/storageKeys'
import { REQUEST_PKG } from '@/consts'
import eventTrackManager from '@/utils/eventTrackManager'
import { EVENT_TYPE } from '@/consts/eventTrack'
import { LS } from '@mi/global-sdks/lib/storage'

const defaultCharset =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
/**
 * @description 生成n位随机id
 * @param {Number} length 生成id长度
 * @param {String} charset 生成id所需字符集
 * @returns {String} 随机id
 */
export function generateId(length = 0, charset = defaultCharset) {
  let result = []

  for (let i = length; i > 0; --i) {
    result.push(charset.charAt(Math.floor(Math.random() * charset.length)))
  }

  return result.join('')
}

// 接口参数uuid字符集及长度
const deviceHashCharset = defaultCharset
const deviceHashLength = 36
// 接口参数uuid字符集及长度traceId
const traceIdCharset = '0123456789ABCDEF'
const traceIdLength = 32
let traceId = ''

// 设备唯一标识
export function getUUID() {
  function generateUUID() {
    try {
      const tempUrl = URL.createObjectURL(new Blob())
      const result = tempUrl.substring(tempUrl.length - 36, tempUrl.length)
      URL.revokeObjectURL(tempUrl)

      return result
    } catch (e) {
      return generateId(deviceHashLength, deviceHashCharset)
    }
  }

  try {
    let result = LS.get(UUID_KEY)

    if (!result) {
      result = generateUUID()
      LS.set(UUID_KEY, result)
    }

    return result
  } catch (e) {
    return generateUUID()
  }
}

// 获取traceId
export function getTraceId() {
  if (!traceId) {
    traceId = generateId(traceIdLength, traceIdCharset)
  }

  return traceId
}

/**
 * @description 获取接口公共参数
 * @returns {Object} 接口公共参数
 */
export function getApiDefaultParams(version_name) {
  return {
    l: 'in', // 写死
    client_info:
      'F1eteUYCNzW1QMyXF%252FreYI4FhID1IH7nLwb0u7vdN3QdH4ANJlsTANmyufJi9zB3',
    c: 'Vivo',
    d: 'joyeuse',
    pkg: REQUEST_PKG,
    version_code: '202205263',
    server_code: '100',
    n: '4g',
    r: 'ID',
    version_name: version_name || '13.6.0-gn',
    v: 'Redmi',
    miui_version: 'V12.0.12.0.QCRIDXM',
    traceId: getTraceId(),
    timestamp: new Date().getTime(),
  }
}

/**
 * @description 获取当前url中的参数
 * @param {string} name
 * @returns {string} 当前url中name对应的参数值
 */
export function getQueryParam(name) {
  const params = {}
  window.location.search
    .substring(1)
    .split('&')
    .forEach((item) => {
      const [key, value] = item.split('=')
      params[key] = value || ''
    })
  return params[name]
}

export function hasQueryParam(name) {
  return getQueryParam(name) !== undefined && getQueryParam(name) !== ''
}

/**
 * @description 获取元素距页面顶部距离
 * @param {HTMLElement} ele dom元素
 * @returns {Number} 元素距页面顶部距离
 */
export function getOffsetTop(ele) {
  if (!ele) {
    return 0
  }

  let result = ele.offsetTop
  let parent = ele.offsetParent

  while (parent) {
    result += parent.offsetTop
    parent = parent.offsetParent
  }

  return result
}

// 检查是否为对应地区
export const checkRegion = {
  // 是否为巴西，pt-BR
  isBR: () =>
    hasQueryParam('re')
      ? ['BR', 'br'].includes(getQueryParam('re'))
      : /^pt-BR$/i.test(navigator.language),
  // 是否为印尼，id-ID|id
  isID: () => ['ID', 'id'].includes(getQueryParam('re')),
  // 是否为俄罗斯，ru
  isRU: () => ['RU', 'ru'].includes(getQueryParam('re')),
  // 是否为越南，VN
  isVN: () =>
    hasQueryParam('re')
      ? ['VN', 'vn'].includes(getQueryParam('re'))
      : /^vi/i.test(navigator.language),
  // 是否为西班牙，es
  isES: () =>
    hasQueryParam('re')
      ? ['ES', 'es'].includes(getQueryParam('re'))
      : /^es-ES/i.test(navigator.language),
  // 是否为英国 GB
  isGB: () =>
    hasQueryParam('re')
      ? ['GB', 'gb'].includes(getQueryParam('re'))
      : /^en/i.test(navigator.language),
  // 是否为法国 FR
  isFR: () =>
    hasQueryParam('re')
      ? ['FR', 'fr'].includes(getQueryParam('re'))
      : /^fr/i.test(navigator.language),
  // 是否为德国 DE
  isDE: () =>
    hasQueryParam('re')
      ? ['DE', 'de'].includes(getQueryParam('re'))
      : /^de/i.test(navigator.language),
  // 是否为意大利 IT
  isIT: () =>
    hasQueryParam('re')
      ? ['IT', 'it'].includes(getQueryParam('re'))
      : /^it/i.test(navigator.language),
  // 是否为印度 IN
  isIN: () =>
    hasQueryParam('re')
      ? ['IN', 'in'].includes(getQueryParam('re'))
      : /^en/i.test(navigator.language),
  //是否为伊朗 IR
  isIR: () =>
    hasQueryParam('re')
      ? ['IR', 'ir'].includes(getQueryParam('re'))
      : /^fa/i.test(navigator.language),
  //是否为土耳其 TR
  isTR: () => ['TR', 'tr'].includes(getQueryParam('re')),
  //是否为墨西哥 MX
  isMX: () =>
    hasQueryParam('re')
      ? ['MX', 'mx'].includes(getQueryParam('re'))
      : /^es-MX$/i.test(navigator.language),
  //是否为菲律宾 PH
  isPH: () =>
    hasQueryParam('re')
      ? ['PH', 'ph'].includes(getQueryParam('re'))
      : /^fil/i.test(navigator.language),
  // chrome缅甸语：my，马来语：ms，阿拉伯语：ar，西班牙语（拉美很多国家）：es-XX，孟加拉语：bn
  // 中文：zh，中文简体：zh-CN, 中文香港：zh-HK，中文繁体：zh-TW
  // 英语：en，英语美国：en-US
  //是否为欧洲 EU
  isEU: () => ['EU', 'eu'].includes(getQueryParam('re')),
}

// 获取手机类型
export function getMobileType() {
  const matchRes = window.navigator.userAgent.match(/\((.+?)\)/)
  if (matchRes === null) return ''

  const res = matchRes[1]?.split(';') || []
  return res[res.length - 1]?.trim() || ''
}

export function getDateString(timestring) {
  const dateObj = new Date(timestring)
  const y = dateObj.getFullYear()
  const m = dateObj.getMonth() + 1
  const d = dateObj.getDate()
  return (
    y +
    '-' +
    (m < 10 ? '0' + m : m) +
    '-' +
    (d < 10 ? '0' + d : d) +
    ' ' +
    dateObj.toTimeString().substr(0, 8)
  )
}

// 获取今天的日期字符串
export function getDateStr() {
  var d = new Date()
  return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
}

// 用apm上报服务端报错
export function apmTrackServerError(options = {}) {
  const $apm = window.$apm
  const { code = 500, message = '', extraInfos = {} } = options
  const { method, params, data, baseURL = '', url = '' } = extraInfos
  /* eslint-disable-next-line */
  const errorMsg = `<<<服务端接口报错>>>: code=${code};url=${baseURL}${url};message=${message};params=${
    method === 'get' ? JSON.stringify(params) || '' : JSON.stringify(data) || ''
  }`
  $apm.captureError && $apm.captureError(new Error(errorMsg))
}

export function apmTrackAdsError(options = {}) {
  const $apm = window.$apm
  const errorMsg = `<<广告错误>>: ${options.text}`
  $apm.captureError && $apm.captureError(new Error(errorMsg))
}

// 检测当前应用网络情况
/**
 * wifi
 * 4g
 * 3g
 * 2g
 * offline
 * unknown
 * other
 */
export function getNetInfo() {
  const connection =
    window.navigator.connection ||
    window.navigator.mozConnection ||
    window.navigator.webkitConnection ||
    {}
  // https://caniuse.com/#feat=netinfo
  // https://wicg.github.io/netinfo/
  // https://www.davidbcalhoun.com/2010/using-navigator-connection-android/
  // https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/effectiveType

  let type = 'unknown'

  if (!connection) {
    type = 'unknown'
  }
  if (!navigator.onLine) {
    type = 'offline'
  } else if (connection && connection.type) {
    const netTypesMaps = {
      bluetooth: 'other',
      cellular: connection.effectiveType,
      ethernet: connection.effectiveType,
      none: 'none',
      wifi: 'wifi',
      wimax: 'other',
      other: 'other',
      unknown: 'unknown',
    }
    type = netTypesMaps[connection.type]
  } else if (connection.effectiveType) {
    type = connection.effectiveType
  }
  if (!type) {
    type = 'unknown'
  }
  type = type && type.toLowerCase()
  const networks = [
    'wifi',
    '2g',
    '3g',
    '4g',
    'unknown',
    'other',
    'offline',
    'none',
  ]
  for (let i = 0; i < networks.length; i++) {
    if (type.indexOf(networks[i]) >= 0) {
      return networks[i]
    }
  }
}

// 引入第三方天气
export function insertWeather() {
  // var beginTime = new Date().getTime()
  var s = document.getElementById('app')
  var weatherSkeletonDom = document.createElement('div')
  weatherSkeletonDom.setAttribute('class', 'weather-skeleton')
  weatherSkeletonDom.innerHTML = '<div></div><div></div><div></div><div></div>'
  s.parentNode.insertBefore(weatherSkeletonDom, s)

  var a = {
    t: 'horizontal',
    lang: 'en',
    sl_lpl: 1,
    ids: [],
    font: 'Arial',
    sl_ics: 'one_a',
    sl_sot: 'celsius',
    cl_bkg: 'image',
    cl_font: '#000000',
    cl_cloud: '#FFFFFF',
    cl_persp: '#81D4FA',
    cl_sun: '#FFC107',
    cl_moon: '#FFC100',
    cl_thund: '#FF5722',
    el_phw: 3,
    el_whr: 3,
  }
  a.lang = checkRegion.isRU()
    ? 'ru'
    : checkRegion.isID()
    ? 'id'
    : checkRegion.isBR()
    ? 'pt'
    : checkRegion.isVN()
    ? 'vi'
    : 'en'

  var weatherDom = document.createElement('div')
  weatherDom.setAttribute('id', 'ww_6ee9a706b999b')
  weatherDom.setAttribute('a', JSON.stringify(a))
  weatherDom.setAttribute('v', '1.3')
  weatherDom.setAttribute('loc', 'auto')
  // const location = window.location
  // const isLoaded = sessionStorage.getItem('isLoaded')
  weatherDom.onclick = function () {
    eventTrackManager.track(EVENT_TYPE.WEATHER_CLICK, {
      name: 'default',
      is_loaded: 0,
    })
    window.open('https://weather.com')
    /* if (isLoaded === 'true') {
      window.open(`${location.origin}/weather${location.search}`)
    } else {
      window.open('https://weather.com')
    } */
  }
  weatherDom.innerHTML = `<a href="https://weatherwidget.org/pl/" id="ww_6ee9a706b999b_u" target="_blank" style="opacity: 0">Widżet Prognoza pogody dla strony internetowej</a>`
  s.parentNode.insertBefore(weatherDom, s)

  var hm = document.createElement('script')
  hm.setAttribute('async', true)
  hm.setAttribute(
    'src',
    'https://app1.weatherwidget.org/js/?id=ww_6ee9a706b999b'
  )
  s.parentNode.insertBefore(hm, s)

  var check = setInterval(() => {
    if (document.getElementsByClassName('ww_temp').length !== 0) {
      // var load_time_wea = (
      //   (new Date().getTime() - beginTime) / 1000 +
      //   window.load_time
      // ).toFixed(1)
      // eventTrackManager.track(EVENT_TYPE.WEATHER_EXPOSURE, {
      //   load_time_wea,
      //   name: 'default',
      // })

      setTimeout(() => {
        var widgetService = document.getElementById('ww_6ee9a706b999b')
        var widgetServiceClone = widgetService.cloneNode(true)
        widgetService.replaceWith(widgetServiceClone)
        widgetService = document.getElementById('ww_6ee9a706b999b')
        widgetService.addEventListener('click', function () {
          eventTrackManager.track(EVENT_TYPE.WEATHER_CLICK, {
            name: 'default',
            is_loaded: 1,
          })
          window.open('https://weather.com')
        })
      }, 2000)
      /*  const hrefDom = document.querySelectorAll('.ww-box div')
      hrefDom[0].onclick = function (e) {
        e.stopPropagation()
        e.preventDefault()
        window.open(`${location.origin}/weather${location.search}`)
      } */
      /*  if (weatherLandType !== 1 && isLoaded === 'true') {
        setTimeout(() => {
          var widgetService = document.getElementById('ww_6ee9a706b999b')
          var widgetServiceClone = widgetService.cloneNode(true)
          widgetService.replaceWith(widgetServiceClone)
          widgetService = document.getElementById('ww_6ee9a706b999b')
          widgetService.addEventListener('click', function () {
            eventTrackManager.track(EVENT_TYPE.WEATHER_CLICK, {
              name: 'mi_weather',
              is_loaded: 1,
            })
            window.open(`${location.origin}/weather${location.search}`)
          })
        }, 2000)
      } */
      clearInterval(check)
    }
  }, 100)
}

//从当前时间起的后几个星期日期
export function getDayTime() {
  const nowTime = new Date().getTime()
  const oneDayTime = 24 * 60 * 60 * 1000
  const dateOptions = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ]
  const result = ['today', 'tomorrow']
  for (let i = 0; i < 5; i++) {
    //显示周一
    const ShowTime = nowTime + (i + 1) * oneDayTime
    //初始化日期时间
    const myDate = new Date(ShowTime)
    const dateTime = dateOptions[myDate.getDay()]
    result.push(dateTime)
  }
  return result
}

//获取今天星期日期
export function getTodayTime() {
  const dateOptions = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ]
  return dateOptions[new Date().getDay()]
}

//从当前时间起的24小时时间
export function getHourTime() {
  const nowTime = new Date().getHours()
  const result = ['now']
  for (let i = 1; i < 24; i++) {
    const showTime = (i + nowTime) % 24
    if (showTime > 9) {
      result.push(`${showTime}:00`)
    } else {
      result.push(`0${showTime}:00`)
    }
  }
  return result
}

/**
 * @param {*} target a标签点击地址
 */
export function aClick(target) {
  const aEle = document.createElement('a')
  aEle.href = target
  document.body.appendChild(aEle)
  aEle.click()
  document.body.removeChild(aEle)
}

// 将毫秒转换为时分秒
export function formatDuring(mss) {
  const hours = parseInt(mss / (1000 * 60 * 60))
  const hoursStr = hours > 0 ? `${hours}:` : ''
  var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60))
  const minutesStr =
    minutes > 0 ? (minutes < 10 ? `0${minutes}:` : `${minutes}:`) : '00:'
  var seconds = (mss % (1000 * 60)) / 1000
  const secondsStr = seconds >= 10 ? seconds : `0${seconds}`

  return hoursStr + minutesStr + secondsStr
}
// 判断是否支持webp图片格式
export function supportWebp() {
  const inBrowser = typeof window !== 'undefined' && window !== null
  if (!inBrowser) return false
  let support = true
  try {
    const elem = document.createElement('canvas')
    if (elem.getContext && elem.getContext('2d')) {
      support = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
    }
  } catch (err) {
    support = false
  }
  return support
}

// 读取cookie
export function getCookie(c_name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + '=')
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1
      let c_end = document.cookie.indexOf(';', c_start)
      if (c_end == -1) c_end = document.cookie.length
      return unescape(document.cookie.substring(c_start, c_end))
    }
  }
  return ''
}
export function insertScriptToHead(scriptUrl, asyncOrDefer = undefined) {
  var myScript = document.createElement('script')
  myScript.src = scriptUrl
  asyncOrDefer === 'async' && myScript.setAttribute('async', true)
  asyncOrDefer === 'defer' && myScript.setAttribute('defer', true)

  document.head.append(myScript)
}
// 判断是否是外投版
export const isOutside = window.location.host.indexOf('mintnav') !== -1
export const isMintnav2024 = window.location.host.indexOf('mintnav2024') !== -1

// 加载tiktok的pixel代码
export function loadTikTokJs(pixelId) {
  window.isLoadTikTokJs = true
  !(function (w, d, t) {
    w.TiktokAnalyticsObject = t
    var ttq = (w[t] = w[t] || [])
    ;(ttq.methods = [
      'page',
      'track',
      'identify',
      'instances',
      'debug',
      'on',
      'off',
      'once',
      'ready',
      'alias',
      'group',
      'enableCookie',
      'disableCookie',
    ]),
      (ttq.setAndDefer = function (t, e) {
        t[e] = function () {
          t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
        }
      })
    for (var i = 0; i < ttq.methods.length; i++)
      ttq.setAndDefer(ttq, ttq.methods[i])
    ;(ttq.instance = function (t) {
      for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
        ttq.setAndDefer(e, ttq.methods[n])
      return e
    }),
      (ttq.load = function (e, n) {
        var i = 'https://analytics.tiktok.com/i18n/pixel/events.js'
        ;(ttq._i = ttq._i || {}),
          (ttq._i[e] = []),
          (ttq._i[e]._u = i),
          (ttq._t = ttq._t || {}),
          (ttq._t[e] = +new Date()),
          (ttq._o = ttq._o || {}),
          (ttq._o[e] = n || {})
        n = document.createElement('script')
        ;(n.type = 'text/javascript'),
          (n.async = !0),
          (n.src = i + '?sdkid=' + e + '&lib=' + t)
        e = document.getElementsByTagName('script')[0]
        e.parentNode.insertBefore(n, e)
      })
    if (pixelId) {
      ttq.load(pixelId)
    } else {
      ttq.load('CLE9RFRC77U0K4UVNQOG')
      ttq.load('7169099470494318593')
      ttq.load('CATFG7BC77U5NQUHDT40') // 广米账户Pixel ID2：程序化回传事件
      ttq.load('CMBPK2BC77U483ARSRAG')
      ttq.load('CMBPJJRC77UE655RNCL0')
      ttq.load('CMJT7J3C77UB6TL2V1RG')
      ttq.load('CMRG69RC77U1UD4AELS0')
      ttq.load('CMVIOHJC77UFF95KFB3G')
      ttq.load('CMVINCJC77U3EE0RNK60')
      ttq.load('CNA812RC77UBB5H948F0')
      ttq.load('CNFVR63C77UC27SBN2KG')
      ttq.load('CML27VRC77UE8SFFD7H0')
      ttq.load('CNEP5NRC77UE36LJBUFG')
      ttq.load('CNJF84BC77U7U7TP7F4G')
      ttq.load('CNL7MK3C77U6UIN9DEEG')
    }

    ttq.page()
  })(window, document, 'ttq')
}
// 加载facebook的pixel代码
export function loadFacebookJs(pixelId) {
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  )
  window.fbq && window.fbq('init', pixelId)
  window.fbq && window.fbq('track', 'PageView')
}

// 标识是否为移动端设备
export const isMobile = !!navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
)
