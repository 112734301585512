export default {
  searchPlaceholder: '搜索或者输入网站',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: '',
  siteDefaultGame2: '',
  siteDefaultGame3: '',
  siteDefaultGame4: '',
  siteDefaultGame5: '',
  qing: '晴',
  duoyun: '多云',
  yin: '阴',
  wu: '雾',
  tedabaoyu: '特大暴雨',
  dabaoyu: '大暴雨',
  baoyu: '暴雨',
  leizhenyu: '雷阵雨',
  zhenyu: '阵雨',
  dayu: '大雨',
  zhongyu: '中雨',
  xiaoyu: '小雨',
  yujiaxue: '雨夹雪',
  baoxue: '暴雪',
  zhenxue: '阵雪',
  daxue: '大雪',
  zhongxue: '中雪',
  xiaoxue: '小雪',
  qiangshachenbao: '强沙尘暴',
  shachenbao: '沙尘暴',
  shachen: '沙尘',
  yangsha: '扬沙',
  bingbao: '冰雹',
  fuchen: '浮尘',
  mai: '霾',
  dongyu: '冻雨',
  xiaodaozhongyu: '小到中雨',
  zhongdaodayu: '中到大雨',
  dadaobaoyu: '大到暴雨',
  baoyudaodabaoyu: '暴雨到大暴雨',
  dabaoyudaotedabaoyu: '大暴雨到特大暴雨',
  xiaodaozhongxue: '小到中雪',
  zhongdaodaxue: '中到大雪',
  dadaobaoxue: '大到暴雪',
  nongwu: '浓雾',
  qiangnongwu: '强浓雾',
  middlemai: '中度霾',
  zhongdumai: '重度霾',
  yanzhongmai: '严重霾',
  dawu: '大雾',
  teqiangnongwu: '特强浓雾',
  yu: '雨',
  xue: '雪',
  hoursForecast: '小时天气',
  today: '今天',
  tomorrow: '明天',
  monday: '星期一',
  tuesday: '星期二',
  wednesday: '星期三',
  thursday: '星期四',
  friday: '星期五',
  saturday: '星期六',
  sunday: '星期日',
  now: '现在',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: '加载中…',
  laodError: '不能加载数据，尝试再次刷新',
  refresh: '刷新',
  guessULike: '猜你喜欢',
  setting: 'Settings',
  changeHome: 'Change Start page',
  changeTitle:
    'Change your Start page any time to make your browser all yours.',
  changeTip1:
    "1. In the top right corner, tap the 'More' icon and go to Settings.",
  changeTip2: '2. Select Start page in Advanced settings.',
  changeTip3:
    '3. Select the Chrome Start page, or choose any web page to customize it.',
  about: 'About',
  aboutFirst:
    'homepage.miui.com is a navigation service in your browser. It provides weather, search, quick links, news, and more.',
  aboutSecond:
    "If you have any feedback or suggestions, feel free to drop us a line at homepagemiui{'@'}gmail.com You can also change your browser Start page at any time in Settings.",
  suggest: 'Feedback',
  suggestTip: 'Tap to add feedback and suggestions',
  mailTo: 'Email address',
  send: 'Submit',
  sendSuccess: 'Submitted successfully',
  weGet: "We'll process your feedback as soon as possible",
  ok: 'OK',
  newFuncTitle: 'New feature',
  newFunc:
    'homepage.miui.com has a brand new Start page. Browse weather and news, search, and more. You can switch back to the old Start page or personalize it in Settings.',
  get: 'Got it',
  searchHistory: '搜索历史',
  cancel: '取消',
  delete: '删除',
  deleteHistoryDialogTitle: '删除历史记录',
  deleteHistoryDialogContent: '是否删除全部搜索历史记录，删除后不能恢复',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: '继续阅读',
}
