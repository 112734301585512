export default {
  searchPlaceholder: 'खोजें या यूआरएल डालें',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'फ्रूट किलरName',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'एलास्टिक कार',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'खिली धूप',
  duoyun: 'बादल',
  yin: 'घिरे बादल',
  wu: 'कुहासा',
  tedabaoyu: 'भारी वर्षा',
  dabaoyu: 'भारी वर्षा के साथ तूफान',
  baoyu: 'तूफानी बारिश',
  leizhenyu: 'गरज के साथ तूफान',
  zhenyu: 'फुहार',
  dayu: 'भारी वर्षा',
  zhongyu: 'सामान्य वर्षा',
  xiaoyu: 'हल्की बारिश',
  yujiaxue: 'ओलावृष्टि',
  baoxue: 'बर्फ़ानी तूफ़ान',
  zhenxue: 'बर्फबारी',
  daxue: 'भारी बर्फबारी',
  zhongxue: 'सामान्य बर्फबारी',
  xiaoxue: 'हल्की बर्फबारी',
  qiangshachenbao: 'भीषण रेतीली आंधी',
  shachenbao: 'रेतीली आंधी',
  shachen: 'हल्की रेतीली आंधी',
  yangsha: 'तेज़ हवा',
  bingbao: 'ओला',
  fuchen: 'धूल',
  mai: 'धुंध',
  dongyu: 'हिम वर्षा',
  xiaodaozhongyu: 'हल्की से सामान्य वर्षा',
  zhongdaodayu: 'सामान्य से भारी वर्षा',
  dadaobaoyu: 'भारी से मूसलाधार वर्षा',
  baoyudaodabaoyu: 'मूसलाधार से झमाझम वर्षा',
  dabaoyudaotedabaoyu: 'झमाझम वर्षा से भारी तूफानी बारिश',
  xiaodaozhongxue: 'हल्की से सामान्य बर्फबारी',
  zhongdaodaxue: 'सामान्य से भारी बर्फबारी',
  dadaobaoxue: 'बर्फ़ीली तूफ़ान से भारी हिमपात',
  nongwu: 'कोहरा',
  qiangnongwu: 'गहरा कोहरा',
  middlemai: 'सामान्य धुंध',
  zhongdumai: 'भारी धुंध',
  yanzhongmai: 'घोर धुंध',
  dawu: 'घना कोहरा',
  teqiangnongwu: 'घोर कोहरा',
  yu: 'वर्षा',
  xue: 'बर्फ़',
  hoursForecast: 'मौसम',
  today: 'आज',
  tomorrow: 'कल',
  monday: 'सोम',
  tuesday: 'मंग',
  wednesday: 'बुध',
  thursday: 'गुरू',
  friday: 'शुक्र',
  saturday: 'शनि',
  sunday: 'रवि',
  now: 'अभी',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'रंग एग',
  siteDefaultGame12: 'क्रैरी फुट',
  siteDefaultGame13: 'होमस्केप्स',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'बस एक सेकंड…',
  laodError: 'डेटा लोड नहीं किया जा सका. रीफ़्रेश करके फिर से कोशिश करें.',
  refresh: 'रीफ़्रेश करें',
  guessULike: 'शायद आपको पसंद आए',
  setting: 'सेटिंग्स',
  changeHome: 'प्रारंभ पेज बदलें',
  changeTitle:
    'प्रारंभ पेज कभी भी बदलें और अपने ब्राउज़र को पूरी तरह से अपना बनाएं',
  changeTip1:
    '1. सबसे ऊपर दाएं कोने में, "और भी" आइकन पर टैप करें और सेटिंग्स में जाएं.',
  changeTip2: '2. उन्नत सेटिंग्स में प्रारंभ पेज चुनें.',
  changeTip3:
    '3. Chrome प्रारंभ पेज चुनें, या इसे कस्टमाइज़ करने के लिए कोई भी वेब पेज चुनें.',
  about: 'इसके बारे में',
  aboutFirst:
    'homepage.miui.com आपके ब्राउज़र में एक नेविगेशन सेवा है. इसमें आपको मिलता है मौसम, खोज, क्विक लिंक्स, समाचार और भी बहुत कुछ.',
  aboutSecond:
    "अगर आप कोई फ़ीडबैक या सुझाव देना चाहते हैं, तो बेझिझक हमें homepagemiui{'@'}gmail.com पर लिखें. आप किसी भी समय सेटिंग्स में जाकर अपने ब्राउज़र का प्रारंभ पेज भी बदल सकते हैं.",
  suggest: 'फ़ीडबैक',
  suggestTip: 'फ़ीडबैक और सुझाव जोड़ने के लिए टैप करें',
  mailTo: 'ईमेल पता',
  send: 'सबमिट करें',
  sendSuccess: 'सबमिट कर दिया गया है',
  weGet: 'हम आपके फ़ीडबैक पर जल्द से जल्द कार्रवाई करेंगे',
  ok: 'ठीक',
  newFuncTitle: 'नया फ़ीचर',
  newFunc:
    'homepage.miui.com में है एक बिल्कुल नया प्रारंभ पेज. मौसम और समाचार ब्राउज़ करें, खोजें, इसके अलावा और भी बहुत कुछ करें. आप सेटिंग्स में जाकर पुराने प्रारंभ पेज पर वापस जा सकते हैं या निजीकृत कर सकते हैं.',
  get: 'ठीक है',
  searchHistory: 'खोज इतिहास',
  cancel: 'रद्द करें',
  delete: 'मिटाएं',
  deleteHistoryDialogTitle: 'सर्च हिस्ट्री को मिटाएं',
  deleteHistoryDialogContent: 'सर्च हिस्ट्री को हमेशा के लिए मिटाएं',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'और पढ़ें',
}
