export default {
  searchPlaceholder: 'یو آر ایل تلاش کریں یا داخل کریں',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'Elastic Car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'صاف',
  duoyun: 'ابرآلود',
  yin: 'گھنے بادل',
  wu: 'دھند',
  tedabaoyu: 'شدید طوفانی بارش',
  dabaoyu: 'بھاری طوفانی بارش',
  baoyu: 'طوفانی بارش',
  leizhenyu: 'طوفان برق وباد',
  zhenyu: 'بوچھار',
  dayu: 'بھاری بارش',
  zhongyu: 'معتدل بارش',
  xiaoyu: 'ہلکی بارش',
  yujiaxue: 'ژالہ باری',
  baoxue: 'برفانی طوفان',
  zhenxue: 'برف کی بوچھار',
  daxue: 'بھاری برفباری',
  zhongxue: 'معتدل برفباری',
  xiaoxue: 'ہلکی برفباری',
  qiangshachenbao: 'شدید ریت کا طوفان',
  shachenbao: 'ریت کا طوفان',
  shachen: 'ہلکا ریت کا طوفان',
  yangsha: 'تیز ہوا',
  bingbao: 'اولہ',
  fuchen: 'دھول',
  mai: 'گردو غبار',
  dongyu: 'منجمد بارش',
  xiaodaozhongyu: 'ہلکی سے معتدل بارش',
  zhongdaodayu: 'معتدل سے بھاری بارش',
  dadaobaoyu: 'بھاری سے طوفانی بارش',
  baoyudaodabaoyu: 'طوفانی بارش سے موسلا دھار بارش',
  dabaoyudaotedabaoyu: 'موسلا دھار بارش سے انتہائی طوفانی بارش',
  xiaodaozhongxue: 'ہلکی سے معتدل برفباری',
  zhongdaodaxue: 'معتدل سے بھاری برفباری',
  dadaobaoxue: 'بھاری برفباری سے برفانی طوفان',
  nongwu: 'کہرا',
  qiangnongwu: 'گہرا کہرا',
  middlemai: 'معتدل گرد و غبار',
  zhongdumai: 'بھاری گردوغبار',
  yanzhongmai: 'شدید گردوغبار',
  dawu: 'گھنا کہرا',
  teqiangnongwu: 'شدید کہرا',
  yu: 'بارش',
  xue: 'برفباری',
  hoursForecast: 'موسم',
  today: 'آج',
  tomorrow: 'کل',
  monday: 'پیر',
  tuesday: 'منگل',
  wednesday: 'بدھ',
  thursday: 'جمعرات',
  friday: 'جمعہ',
  saturday: 'سنیچر',
  sunday: 'اتوار',
  now: 'ابھی',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'بس ایک سیکنڈ…',
  laodError: 'ڈیٹا لوڈ نہیں کرسکا۔ دوبارہ کوشش کرنے کے لیے ریفریش کریں۔',
  refresh: 'ریفریش کریں',
  guessULike: 'آپ پسند کر سکتے ہیں',
  setting: 'ترتیبات',
  changeHome: 'صفحہ آغاز تبدیل کریں',
  changeTitle:
    'اپنے براؤزر کو اپنا بنانے کے لیے کسی بھی وقت اپنا صفحہ آغاز تبدیل کریں۔',
  changeTip1:
    '1. اوپری دائیں کونے میں, "مزید" آئیکن پر ٹیپ کریں اور ترتیبات میں جائیں۔',
  changeTip2: '2. اعلیٰ ترتیبات میں صفحہ آغاز منتخب کریں۔',
  changeTip3:
    '3. Chrome صفحہ آغاز منتخب کریں, یا کسٹمائز کرنے کے لیے کوئی بھی ویب صفحہ چنیں۔',
  about: 'تعارف',
  aboutFirst:
    'homepage.miui.com آپ کے براؤزر میں ایک نیویگیشن سروس ہے۔ یہ موسم, تلاش, فوری لنکس, خبریں, اور مزید فراہم کرتی ہے۔',
  aboutSecond:
    "اگر آپ کے کوئی فیڈ بیک یا تجاویز ہیں, تو بلا جھجک homepagemiui{'@'}gmail.com پر ہمیں لکھیں۔ آپ کبھی بھی ترتیبات میں اپنے براؤزر کا صفحہ آغاز بھی تبدیل کر سکتے ہیں۔",
  suggest: 'فیڈبیک',
  suggestTip: 'فیڈ بیک اور تجاویز شامل کرنے کے لیے ٹیپ کریں',
  mailTo: 'ای میل پتہ',
  send: 'جمع کریں',
  sendSuccess: 'کامیابی سے جمع کیا گیا',
  weGet: 'ہم جلد سے جلد آپ کے فیڈ بیک کو پراسیس کریں گے',
  ok: 'ٹھیک',
  newFuncTitle: 'نیا فیچر',
  newFunc:
    'homepage.miui.com میں ایک بالکل نیا صفحہ آغاز ہے۔ موسم اور خبریں, تلاش, اور مزید براؤز کریں۔ آپ پرانے صفحہ آغاز پر واپس جا سکتے یا ترتیبات میں اسے نجی بنا سکتے ہیں۔',
  get: 'سمجھ گئے',
  searchHistory: 'ہسٹری تلاش کریں',
  cancel: 'منسوخ کریں',
  delete: 'حذف کریں',
  deleteHistoryDialogTitle: 'تلاش ہسٹری حذف کریں',
  deleteHistoryDialogContent: 'ابھی تلاش ہسٹری مستقل طور پر حذف کریں؟',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'مزید پڑھیں',
}
