export default {
  searchPlaceholder: 'Cerca o inserisci un URL',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'Sereno',
  duoyun: 'Nuvoloso',
  yin: 'Coperto',
  wu: 'Foschia',
  tedabaoyu: 'Forte temporale',
  dabaoyu: 'Temporale intenso',
  baoyu: 'Temporale',
  leizhenyu: 'Temporale',
  zhenyu: 'Rovesci',
  dayu: 'Pioggia intensa',
  zhongyu: 'Pioggia moderata',
  xiaoyu: 'Pioggia leggera',
  yujiaxue: 'Nevischio',
  baoxue: 'Bufera di neve',
  zhenxue: 'Nevicate',
  daxue: 'Neve intensa',
  zhongxue: 'Neve moderata',
  xiaoxue: 'Nevicata leggera',
  qiangshachenbao: 'Tempesta di sabbia intensa',
  shachenbao: 'Tempesta di sabbia',
  shachen: 'Tempesta di sabbia leggera',
  yangsha: 'Ventoso',
  bingbao: 'Grandine',
  fuchen: 'Polvere',
  mai: 'Foschia',
  dongyu: 'Pioggia gelata',
  xiaodaozhongyu: 'Pioggia da lieve a moderata',
  zhongdaodayu: 'Pioggia da moderata a forte',
  dadaobaoyu: 'Pioggia da forte a torrenziale',
  baoyudaodabaoyu: 'Da pioggia torrenziale ad acquazzone',
  dabaoyudaotedabaoyu: 'Da acquazzone a temporale estremo',
  xiaodaozhongxue: 'Nevicata da lieve a moderata',
  zhongdaodaxue: 'Nevicata da moderata a forte',
  dadaobaoxue: 'Da forte nevicata a bufera',
  nongwu: 'Nebbia',
  qiangnongwu: 'Nebbia fitta',
  middlemai: 'Foschia moderata',
  zhongdumai: 'Foschia fitta',
  yanzhongmai: 'Foschia molto fitta',
  dawu: 'Nebbia densa',
  teqiangnongwu: 'Nebbia critica',
  yu: 'Pioggia',
  xue: 'Neve',
  hoursForecast: 'Meteo',
  today: 'Oggi',
  tomorrow: 'Domani',
  monday: 'Lun',
  tuesday: 'Mar',
  wednesday: 'Mer',
  thursday: 'Gio',
  friday: 'Ven',
  saturday: 'Sab',
  sunday: 'Dom',
  now: 'Ora',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'Solo un secondo…',
  laodError: 'Impossibile caricare i dati. Aggiorna e riprova.',
  refresh: 'Aggiorna',
  guessULike: 'Potrebbe piacerti',
  setting: 'Impostazioni',
  changeHome: 'Cambia Pagina iniziale',
  changeTitle:
    'Cambia la tua pagina iniziale in qualsiasi momento per personalizzare il tuo browser.',
  changeTip1:
    '1. Nell\'angolo in alto a destra, tocca l\'icona "Altro" e vai su Impostazioni.',
  changeTip2: '2. Seleziona Pagina iniziale in Impostazioni avanzate.',
  changeTip3:
    '3. Seleziona la Pagina iniziale di Chrome o scegli qualsiasi altra pagina web per personalizzarla.',
  about: 'Informazioni',
  aboutFirst:
    'homepage.miui.com è un servizio di navigazione nel tuo browser che offre previsioni meteo, ricerca, collegamenti rapidi, notizie e altro ancora.',
  aboutSecond:
    "Per feedback o suggerimenti, non esitare a scriverci a homepagemiui{'@'}gmail.com. Puoi anche modificare la Pagina iniziale del browser in qualsiasi momento in Impostazioni.",
  suggest: 'Feedback',
  suggestTip: 'Tocca per aggiungere feedback e suggerimenti',
  mailTo: 'Indirizzo e-mail',
  send: 'Invia',
  sendSuccess: 'Invio avvenuto con successo',
  weGet: 'Elaboreremo il tuo feedback il prima possibile',
  ok: 'Ok',
  newFuncTitle: 'Nuova funzionalità',
  newFunc:
    'minitrav.com ha una nuova Pagina iniziale. Sfoglia le previsioni meteo e le notizie, usa la funzione di ricerca e scopri tanto altro. Puoi tornare alla vecchia Pagina iniziale o personalizzarla in Impostazioni.',
  get: 'Ho capito',
  searchHistory: 'Cronologia delle ricerche',
  cancel: 'Annulla',
  delete: 'Elimina',
  deleteHistoryDialogTitle: 'Elimina la cronologia delle ricerche',
  deleteHistoryDialogContent:
    'Vuoi eliminare definitivamente e subito la cronologia delle ricerche?',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'Ulteriori informazioni',
}
