export default {
  searchPlaceholder: 'ইউ আর এল সার্চ করুন বা লিখুন',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'Elastic Car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'আকাশ পরিষ্কার',
  duoyun: 'মেঘলা',
  yin: 'মেঘাচ্ছন্ন',
  wu: 'মিস্ট',
  tedabaoyu: 'মারাত্মক ঝড়বৃষ্টি',
  dabaoyu: 'ব্যাপক বৃষ্টিঝড়',
  baoyu: 'ঝড়বৃষ্টি',
  leizhenyu: 'বজ্রবিদ্যুতসহ ঝড়',
  zhenyu: 'বর্ষণ',
  dayu: 'প্রচন্ড বৃষ্টিপাত',
  zhongyu: 'মোটামুটি বৃষ্টি',
  xiaoyu: 'হালকা বৃষ্টি',
  yujiaxue: 'শিলাবৃষ্টি',
  baoxue: 'তুষার ঝড়',
  zhenxue: 'তুষারপাত',
  daxue: 'প্রবল তুষার',
  zhongxue: 'মোটামুটি তুষারপাত',
  xiaoxue: 'হালকা তুষার',
  qiangshachenbao: 'প্রচন্ড বালিঝড়',
  shachenbao: 'বালুঝড়',
  shachen: 'হালকা বালুঝড়',
  yangsha: 'ঝড়ো',
  bingbao: 'শিলাবৃষ্টি',
  fuchen: 'ধুলো',
  mai: 'কুয়াশা',
  dongyu: 'তুষার ও বৃষ্টি',
  xiaodaozhongyu: 'হালকা থেকে মাঝারি বৃষ্টি',
  zhongdaodayu: 'মাঝারি থেকে প্রবল বৃষ্টি',
  dadaobaoyu: 'প্রবল থেকে ভারি বৃষ্টি',
  baoyudaodabaoyu: 'প্রবল বৃষ্টি থেকে মুষলধারে বৃষ্টি',
  dabaoyudaotedabaoyu: 'মুষলধারে বৃষ্টি থেকে প্রবল বৃষ্টি ঝড়',
  xiaodaozhongxue: 'হালকা থেকে মাঝারি তুষার',
  zhongdaodaxue: 'মাঝারি থেকে প্রবল তুষার',
  dadaobaoxue: 'ভারি তুষার থেকে তুষার ঝড়',
  nongwu: 'কুয়াশা',
  qiangnongwu: 'ঘন কুয়াশা',
  middlemai: 'মোটামুটি কুয়াশা',
  zhongdumai: 'প্রচন্ড কুয়াশা',
  yanzhongmai: 'সাংঘাতিক কুয়াশা',
  dawu: 'গাঢ় কুয়াশা',
  teqiangnongwu: 'সাংঘাতিক কুয়াশা',
  yu: 'বৃষ্টি',
  xue: 'তুষারপাত',
  hoursForecast: 'আবহাওয়া',
  today: 'আজকে',
  tomorrow: 'আগামীকাল',
  monday: 'সোম',
  tuesday: 'মঙ্গল',
  wednesday: 'বুধ',
  thursday: 'বৃহ',
  friday: 'শুক্র',
  saturday: 'শনি',
  sunday: 'রবি',
  now: 'এখন',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'এক মুহূর্ত অপেক্ষা করুন…',
  laodError: 'ডাটা লোড করা যায় নি। রিফ্রেশ করে আবার চেষ্টা করুন।',
  refresh: 'রিফ্রেশ করুন',
  guessULike: 'আপনার এটাও ভাল লাগতে পারে',
  setting: 'সেটিংস',
  changeHome: 'স্টার্ট পেজস্টার্ট পেজে পরিবর্তন করুন',
  changeTitle:
    'আপনার স্টার্ট পেজ যে কোনও সময়ে পরিবর্তন করে আপনার ব্রাউজার নিজের মতন করুন।',
  changeTip1: '1. উপরের ডান দিকের কোণে "আরও" আইকনে ট্যাপ করে সেটিংসে যান।',
  changeTip2: '2. উন্নত সেটিংস থেকে স্টার্ট পেজ বেছে নিন।',
  changeTip3:
    '3. Chrome স্টার্ট পেজ, বেছে নিন বা সেটা নিজের মতন করতে কোনও একটি পেজ বেছে নিন।',
  about: 'বিষয়',
  aboutFirst:
    'homepage.miui.com আপনার ব্রাউজারের জন্য একটি নেভিগেশন পরিষেবা। এতে আবহাওয়া, সার্চ, কুইক লিঙ্ক, খবর, ও আরও অনেক কিছু দেখতে পাবেন।',
  aboutSecond:
    "আপনার কোনও মতামত বা সাজেশন থাকলে আমাদেরকে homepagemiui{'@'}gmail.com এ লিখে জানান। আপনি যে কোনও সময়ে সেটিংস থেকে আপনার ব্রাউজার স্টার্ট পেজ পরিবর্তন করতে পারবেন।",
  suggest: 'মতামত',
  suggestTip: 'মতামত ও সাজেশন যোগ করতে ট্যাপ করুন',
  mailTo: 'ইমেল ঠিকানা',
  send: 'পাঠান',
  sendSuccess: 'সফলভাবে জমা দেওয়া হয়েছে',
  weGet: 'আপনার মতামত যত শিঘ্র সম্ভব আমরা প্রসেস করব',
  ok: 'ঠিক আছ',
  newFuncTitle: 'নতুন ফিচার',
  newFunc:
    'homepage.miui.com এর একটি নতুন স্টার্ট পেজ আছে। আবহাওয়া, খবর, সার্চ ও আরও অনেক কিছু ব্রাউজ করুন। আপনি সেটিংস থেকে নিজের মতন করে নিতে পারেন বা আগের স্টার্ট পেজে চলে যেতে পারেন।',
  get: 'বুঝেছি',
  searchHistory: 'সার্চ ইতিহাস',
  cancel: 'বাতিল করুন',
  delete: 'মুছুন',
  deleteHistoryDialogTitle: 'সার্চ ইতিহাস মুছে ফেলুন',
  deleteHistoryDialogContent: 'এখন সার্চ ইতিহাস চিরকালের জন্য মুছে ফেলতে চান?',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'আরো জানুন',
}
