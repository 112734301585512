export default {
  searchPlaceholder: 'URL શોધો અથવા દાખલ કરો',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'Elastic Car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'સાફ કરો',
  duoyun: 'વાદળછાયું',
  yin: 'ઓવરકાસ્ટ',
  wu: 'મિસ્ટ',
  tedabaoyu: 'અતિથી અતિ ભારે વાવાઝોડું',
  dabaoyu: 'ભારે વરસાદી તોફાન',
  baoyu: 'વરસાદ સાથે વાવાઝોડું',
  leizhenyu: 'ગાજવીજ સાથે વાવાઝોડું',
  zhenyu: 'ઝાપટું',
  dayu: 'ભારે વરસાદ',
  zhongyu: 'મધ્યમસરનો વરસાદ',
  xiaoyu: 'હળવો વરસાદ',
  yujiaxue: 'કરા સાથે વરસાદ',
  baoxue: 'બરફવર્ષા',
  zhenxue: 'બરફ વર્ષા',
  daxue: 'ભારે હિમવર્ષા',
  zhongxue: 'મધ્યમસરની હિમવર્ષા',
  xiaoxue: 'હળવી હિમવર્ષા',
  qiangshachenbao: 'જોરદાર આંધી',
  shachenbao: 'આંધી',
  shachen: 'હળવી આંધી',
  yangsha: 'વાવંટોળવાળું',
  bingbao: 'કરા',
  fuchen: 'ધૂળ',
  mai: 'ધુમ્મસ',
  dongyu: 'થીજવી નાંખે એવો વરસાદ',
  xiaodaozhongyu: 'હળવોથી મધ્યમ વરસાદ',
  zhongdaodayu: 'મધ્યમથી ભારે વરસાદ',
  dadaobaoyu: 'ભારે થી મુશળધાર વરસાદ',
  baoyudaodabaoyu: 'ધોધમાર વરસાદ',
  dabaoyudaotedabaoyu: 'ભારે વરસાદી વાવાઝોડા સુધી ધોધમાર વરસાદ',
  xiaodaozhongxue: 'હળવાથી મધ્યમ બરફ',
  zhongdaodaxue: 'મધ્યમથી ભારે બરફ',
  dadaobaoxue: 'હિમવર્ષાથી ભારે બરફ',
  nongwu: 'ધુમ્મસ',
  qiangnongwu: 'ગાઢ ધુમ્મસ',
  middlemai: 'મધ્યમ ઝાકળ',
  zhongdumai: 'ભારે ઝાકળ',
  yanzhongmai: 'ગંભીર ઝાકળ',
  dawu: 'ગાઢ ધુમ્મસ',
  teqiangnongwu: 'તીવ્ર ધુમ્મસ',
  yu: 'વરસાદ',
  xue: 'હિમવર્ષા',
  hoursForecast: 'હવામાન',
  today: 'આજે',
  tomorrow: 'આવતીકાલે',
  monday: 'સોમ',
  tuesday: 'મંગળ',
  wednesday: 'બુધ',
  thursday: 'ગુરુ',
  friday: 'શુક્ર',
  saturday: 'શનિ',
  sunday: 'રવિ',
  now: 'હમણાં',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'બસ એક સેકન્ડ…',
  laodError: 'ડેટા લોડ કરી શક્યાં નથી, રિફ્રેશ કરો, ફરી પ્રયાસ કરો.',
  refresh: 'રિફ્રેશ કરો',
  guessULike: 'તમને કદાચ ગમશે',
  setting: 'સેટિંગ્સ',
  changeHome: 'સ્ટાર્ટ પેજ બદલો',
  changeTitle:
    'તમારા બ્રાઉઝરને તમારું બનાવવા માટે કોઈપણ સમયે તમારું સ્ટાર્ટ પેજ બદલો.',
  changeTip1:
    '1. ઉપરના જમણા ખૂણામાં, "વધુ" આયકનને ટેપ કરો અને સેટિંગ્સ પર જાઓ.',
  changeTip2: '2. એડવાન્સ્ડ સેટિંગ્સમાં સ્ટાર્ટ પેજ પસંદ કરો.',
  changeTip3:
    '3. Chrome નું સ્ટાર્ટ પેજ પસંદ કરો અથવા તેને કસ્ટમાઇઝ કરવા માટે કોઈપણ વેબ પેજ પસંદ કરો.',
  about: 'વિશે',
  aboutFirst:
    'homepage.miui.com તમારા બ્રાઉઝરમાં નેવિગેશન સેવા છે. તે હવામાન, શોધ, ઝડપી લિંક્સ, સમાચાર અને વધુ પ્રદાન કરે છે.',
  aboutSecond:
    "જો તમારી પાસે કોઈ પ્રતિસાદ અથવા સૂચનો હોય, તો અમને homepagemiui{'@'}gmail.com પર લાઈન મોકલવા માટે નિઃસંકોચ. તમે સેટિંગ્સમાં કોઈપણ સમયે તમારા બ્રાઉઝરનું સ્ટાર્ટ પેજ પણ બદલી શકો છો.",
  suggest: 'પ્રતિસાદ',
  suggestTip: 'પ્રતિસાદ અને સૂચનો ઉમેરવા માટે ટૅપ કરો',
  mailTo: 'ઇમેઇલ એડ્રેસ',
  send: 'સબમિટ કરો',
  sendSuccess: 'સફળતાપૂર્વક સબમિટ કર્યું',
  weGet: 'અમે શક્ય તેટલી વહેલી તકે તમારા પ્રતિસાદ પર પ્રક્રિયા કરીશું',
  ok: 'ઓકે',
  newFuncTitle: 'નવી સુવિધા',
  newFunc:
    'homepage.miui.com પાસે એકદમ નવું સ્ટાર્ટ પેજ છે. હવામાન અને સમાચાર, શોધ અને વધુ બ્રાઉઝ કરો. તમે જૂના સ્ટાર્ટ પેજ પર પાછા સ્વિચ કરી શકો છો અથવા સેટિંગ્સમાં તેને વ્યક્તિગત કરી શકો છો.',
  get: 'સમજાઇ ગયું',
  searchHistory: 'શોધનો ઇતિહાસ',
  cancel: 'રદ્દ કરો',
  delete: 'ડિલીટ કરો',
  deleteHistoryDialogTitle: 'શોધનો ઇતિહાસ ડિલીટ કરો',
  deleteHistoryDialogContent: 'હવે શોધ ઇતિહાસ કાયમ માટે ડિલીટ કરીએ?',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'વધુ વાંચો',
}
