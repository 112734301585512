export default {
  searchPlaceholder: 'Ara veya URL gir',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'MSN',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Meyve Katil',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'Elastik araba',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'Açık',
  duoyun: 'Bulutlu',
  yin: 'Kapalı',
  wu: 'Sis',
  tedabaoyu: 'Şiddetli sağanak',
  dabaoyu: 'Şiddetli yağmur fırtınası',
  baoyu: 'Sağanak',
  leizhenyu: 'Gök gürültülü sağanak',
  zhenyu: 'Sağanak',
  dayu: 'Şiddetli yağış',
  zhongyu: 'Orta derecede yağmur',
  xiaoyu: 'Hafif yağmur',
  yujiaxue: 'Sulu kar',
  baoxue: 'Kar fırtınası',
  zhenxue: 'Kar sağanağı',
  daxue: 'Yoğun karlı',
  zhongxue: 'Orta karlı',
  xiaoxue: 'Hafif karlı',
  qiangshachenbao: 'Güçlü kum fırtınası',
  shachenbao: 'Kum fırtınası',
  shachen: 'Hafif kum fırtınası',
  yangsha: 'Rüzgarlı',
  bingbao: 'Dolu',
  fuchen: 'Toz',
  mai: 'Puslu',
  dongyu: 'Dondurucu yağmur',
  xiaodaozhongyu: 'Hafif-orta yağış',
  zhongdaodayu: 'Orta-şiddetli yağış',
  dadaobaoyu: 'Şiddetli-sel gibi yağış',
  baoyudaodabaoyu: 'Sel gibi yağış-sağanak',
  dabaoyudaotedabaoyu: 'Sağanak-aşırı yağmur',
  xiaodaozhongxue: 'Hafif-orta kar',
  zhongdaodaxue: 'Orta-şiddetli kar',
  dadaobaoxue: 'Şiddetli kar-kar fırtınası',
  nongwu: 'Sis',
  qiangnongwu: 'Yoğun sis',
  middlemai: 'Orta pus',
  zhongdumai: 'Yoğun pus',
  yanzhongmai: 'Şiddetli pus',
  dawu: 'Yoğun sis',
  teqiangnongwu: 'Şiddetli sis',
  yu: 'Yağmur',
  xue: 'Kar',
  hoursForecast: 'Hava durumu',
  today: 'Bugün',
  tomorrow: 'Yarın',
  monday: 'Paz',
  tuesday: 'Sal',
  wednesday: 'Çrş',
  thursday: 'Prş',
  friday: 'Cum',
  saturday: 'Cmt',
  sunday: 'Paz',
  now: 'Şimdi',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Renk Yumurtaları',
  siteDefaultGame12: 'Crary Foot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'Yalnızca bir sn…',
  laodError: 'Veri yüklenemedi. Tekrar denemek için yenileyin.',
  refresh: 'Yenile',
  guessULike: 'Bunları da beğenebilirsiniz',
  setting: 'Ayarlar',
  changeHome: 'Başlangıç sayfasını değiştir',
  changeTitle:
    'Tarayıcınızın tamamen size ait olması için başlangıç sayfasını istediğiniz zaman değiştirin.',
  changeTip1:
    "1. Sağ üst köşede ''Daha'' simgesine dokunun ve Ayarlar'a gidin.",
  changeTip2: '2. Gelişmiş ayarlarda başlangıç sayfasını seçin.',
  changeTip3:
    '2. Chrome Başlangıç sayfasını seçin veya özelleştirmek için herhangi bir internet sayfasını seçin.',
  about: 'Hakkında',
  aboutFirst:
    'homepage.miui.com tarayıcınızda bir navigasyon sistemidir. Hava durumu, arama, hızlı bağlantılar, haberler ve daha fazlasını sağlar.',
  aboutSecond:
    "Herhangi bir geri bildiriminiz veya öneriniz varsa homepagemiui{'@'}gmail.com adresinden bize yazmaktan çekinmeyin. Ayrıca tarayıcı başlangıç sayfanızı istediğiniz zaman Ayarlar'dan değiştirebilirsiniz.",
  suggest: 'Geri bildirim',
  suggestTip: 'Geri bildirim ve öneri eklemek için dokunun',
  mailTo: 'E-posta adresi',
  send: 'Gönder',
  sendSuccess: 'Başarıyla gönderildi',
  weGet: 'Geri bildiriminizi en kısa sürede işleme koyacağız',
  ok: 'Tamam',
  newFuncTitle: 'Yeni özellik',
  newFunc:
    "homepage.miui.com'un yepyeni bir başlangıç sayfası var. Hava durumunu ve haberleri, aramayı ve daha fazlasını tarayın. Ayarlar'dan eski başlangıç sayfasına geçebilir veya onu kişiselleştirebilirsiniz.",
  get: 'Anladım',
  searchHistory: 'Arama geçmişi',
  cancel: 'İptal',
  delete: 'Sil',
  deleteHistoryDialogTitle: 'Arama geçmişini sil',
  deleteHistoryDialogContent: 'Arama geçmişi şimdi kalıcı olarak silinsin mi?',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'Daha fazla oku',
}
