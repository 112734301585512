export default {
  searchPlaceholder: 'Pesquisar ou inserir URL',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'tempo',
  siteWiki: 'wiki',
  siteMsn: 'MSN',
  siteGames: 'Jogos',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'YouTube',
  siteNetflix: 'Netflix',
  siteTiktok: 'TikTok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Carro Elástico',
  siteSquidGame: 'jogo de Lula',
  siteColorFill: 'Preenchimento de cor',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Baixar vídeos',
  mvpGames: 'Games',
  mvpSports: 'Esportes',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Destaques',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'carro correndo',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'Pressa',
  siteDefaultGame3: 'Carro',
  siteDefaultGame4: 'Lula',
  siteDefaultGame5: 'Pintar',
  qing: 'Limpo',
  duoyun: 'Nublado',
  yin: 'Muito nublado',
  wu: 'Névoa',
  tedabaoyu: 'Tempestade intensa',
  dabaoyu: 'Tempestade intensa',
  baoyu: 'Tempestade chuvosa',
  leizhenyu: 'Tempestade de raios',
  zhenyu: 'Chuva',
  dayu: 'Chuva intensa',
  zhongyu: 'Chuva moderada',
  xiaoyu: 'Chuva fraca',
  yujiaxue: 'Granizo',
  baoxue: 'Nevasca',
  zhenxue: 'Chuva com neve',
  daxue: 'Neve intensa',
  zhongxue: 'Neve moderada',
  xiaoxue: 'Neve fraca',
  qiangshachenbao: 'Forte tempestade de areia',
  shachenbao: 'Tempestade de areia',
  shachen: 'Leve tempestade de areia',
  yangsha: 'Ventania',
  bingbao: 'Granizo',
  fuchen: 'Poeira',
  mai: 'Névoa',
  dongyu: 'Chuva congelada',
  xiaodaozhongyu: 'Chuva leve a moderada',
  zhongdaodayu: 'Chuva moderada a intensa',
  dadaobaoyu: 'Chuva intensa a torrencial',
  baoyudaodabaoyu: 'Chuva torrencial a tempestade',
  dabaoyudaotedabaoyu: 'Tempestade a tempestade extrema',
  xiaodaozhongxue: 'Neve fraca a moderada',
  zhongdaodaxue: 'Neve moderada a intensa',
  dadaobaoxue: 'Neve intensa a nevasca',
  nongwu: 'Nevoeiro',
  qiangnongwu: 'Névoa espessa',
  middlemai: 'Nevoeiro moderado',
  zhongdumai: 'Nevoeiro intenso',
  yanzhongmai: 'Nevoeiro severo',
  dawu: 'Névoa densa',
  teqiangnongwu: 'Névoa severa',
  yu: 'Chuva',
  xue: 'Neve',
  hoursForecast: 'Clima',
  today: 'Hoje',
  tomorrow: 'Amanhã',
  monday: 'Seg.',
  tuesday: 'Ter.',
  wednesday: 'Qua.',
  thursday: 'Qui.',
  friday: 'Sex.',
  saturday: 'Sáb.',
  sunday: 'Dom.',
  now: 'Now',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'Aguarde…',
  laodError: 'Não foi possível carregar os dados. Atualize e tente novamente.',
  refresh: 'Recarregar',
  guessULike: 'Você poderá gostar de',
  setting: 'Configurações',
  changeHome: 'Alterar Página inicial',
  changeTitle:
    'Altere a sua Página inicial a qualquer momento para personalizar o seu navegador.',
  changeTip1:
    '1. No canto superior direito, toque no ícone "Mais" e vá em Configurações.',
  changeTip2: '2. Selecione Página inicial em Configurações avançadas.',
  changeTip3:
    '3. Selecione a Página inicial do chrome, ou escolha qualquer página web para personalizá-lo.',
  about: 'Sobre',
  aboutFirst:
    'homepage.miui.com é um serviço de navegação no seu navegador. Ele fornece previsão do tempo, pesquisa, links rápidos, notícias e mais.',
  aboutSecond:
    "Caso tenha algum feedback ou sugestões, fique à vontade para entrar em contato conosco no homepagemiui{'@'}gmail.com. Você também pode alterar a Página inicial do seu navegador a qualquer momento nas Configurações.",
  suggest: 'Feedback',
  suggestTip: 'Toque para adicionar feedback e sugestões',
  mailTo: 'Endereço de e-mail',
  send: 'Enviar',
  sendSuccess: 'Enviado com sucesso',
  weGet: 'Processaremos seu feedback o mais rápido possível',
  ok: 'OK',
  newFuncTitle: 'Novo recurso',
  newFunc:
    'O homepage.miui.com tem uma nova Página inicial. Veja a previsão do tempo e notícias, faça buscas e mais. Você pode voltar para a Página inicial antiga ou personalizá-la nas Configurações.',
  get: 'Entendido',
  searchHistory: 'Histórico de pesquisa',
  cancel: 'Cancelar',
  delete: 'Excluir',
  deleteHistoryDialogTitle: 'Excluir histórico de pesquisa',
  deleteHistoryDialogContent:
    'Excluir permanentemente o histórico de pesquisa agora?',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'Leia mais',
}
