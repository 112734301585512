import { ApmVuePlugin } from '@elastic/apm-rum-vue'
import { checkRegion } from '@/utils/utils'
/**
 * 初始化elastic-apm, https://kibana-sgp.inf.miui.com/
 * @param app vue提供的createApp方法创建的app对象
 * @param router 路由对象
 * */
export function initElasticApm(app, router) {
  // 是否为本地开发环境
  const isDevelopment = import.meta.env.VITE_APP_ENV === 'dev'
  let apmServerUrl = 'https://apm-rum-sgp.inf.miui.com'

  if (checkRegion.isRU()) {
    apmServerUrl = 'https://apm-rum-ru.inf.miui.com'
  } else if (
    checkRegion.isES() ||
    checkRegion.isGB() ||
    checkRegion.isFR() ||
    checkRegion.isDE() ||
    checkRegion.isIT() ||
    checkRegion.isTR() ||
    checkRegion.isIR()
  ) {
    apmServerUrl = 'https://apm-rum-eu.inf.miui.com'
  } else if (checkRegion.isIN()) {
    apmServerUrl = 'https://apm-rum-ind.inf.miui.com'
  }

  app.use(ApmVuePlugin, {
    router,
    config: {
      serverUrl: apmServerUrl,
      serviceName: 'AFS',
      serviceVersion: __APP_VERSION__,
      environment:
        import.meta.env.VITE_APP_ENV === 'prod' ? 'production' : 'develop',
      // 本地开发环境不上报错误
      active: !isDevelopment,
      transactionSampleRate: 0.1,
    },
  })
}
