export default {
  searchPlaceholder: 'URL തിരയുക അല്ലെങ്കിൽ നൽകുക',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'Elastic Car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'തെളിഞ്ഞത്',
  duoyun: 'മേഘാവൃതം',
  yin: 'ഇരുള്‍ മൂടിയത്',
  wu: 'മഞ്ഞ്',
  tedabaoyu: 'കൊടുങ്കാറ്റോടുകൂടിയ കടുത്ത പേമാരി',
  dabaoyu: 'ശക്തമായ കാറ്റും മഴയും',
  baoyu: 'കടുത്ത പേമാരി',
  leizhenyu: 'ഇടിയും മിന്നലുമുള്ള കൊടുങ്കാറ്റ്‌',
  zhenyu: 'ചാറ്റല്‍മഴ',
  dayu: 'കനത്ത മഴ',
  zhongyu: 'മിതമായ മഴ',
  xiaoyu: 'ചാറ്റൽ മഴ',
  yujiaxue: 'ആലിപ്പഴം പൊഴിയൽ',
  baoxue: 'ഹിമവാതം',
  zhenxue: 'മഞ്ഞ്‌ മഴ',
  daxue: 'കനത്ത മഞ്ഞ്',
  zhongxue: 'മിതമായ മഞ്ഞ്',
  xiaoxue: 'നേരിയ മഞ്ഞ്',
  qiangshachenbao: 'കടുത്ത പൊടിക്കാറ്റ്',
  shachenbao: 'പൊടിക്കാറ്റ്',
  shachen: 'ചെറിയ പൊടിക്കാറ്റ്',
  yangsha: 'കാറ്റുള്ള',
  bingbao: 'ആലിപ്പഴം',
  fuchen: 'പൊടി',
  mai: 'മൂടൽമഞ്ഞ്',
  dongyu: 'മരവിപ്പിക്കുന്ന മഴ',
  xiaodaozhongyu: 'നേരിയതോ മിതമായതോ ആയ മഴ',
  zhongdaodayu: 'ഇടത്തരം മുതൽ കനത്ത മഴ',
  dadaobaoyu: 'കനത്ത മഴ മുതൽ ചാറ്റൽ മഴ വരെ',
  baoyudaodabaoyu: 'ചാറ്റൽ മഴ മുതൽ പെരുമഴ വരെ',
  dabaoyudaotedabaoyu: 'അതിശക്തമായ മഴ മുതൽ ചാറ്റൽമഴ വരെ',
  xiaodaozhongxue: 'നേരിയതോ മിതമായതോ ആയ മഞ്ഞ്',
  zhongdaodaxue: 'ഇടത്തരം മുതൽ കനത്ത മഞ്ഞ് വരെ',
  dadaobaoxue: 'കനത്ത മഞ്ഞ് മുതൽ ഹിമപാതം വരെ',
  nongwu: 'മൂടൽമഞ്ഞ്',
  qiangnongwu: 'കട്ടിയുള്ള മൂടൽമഞ്ഞ്',
  middlemai: 'മിതമായ മൂടല്‍മഞ്ഞ്',
  zhongdumai: 'കനത്ത മൂടല്‍മഞ്ഞ്',
  yanzhongmai: 'തീവ്രമായ മൂടല്‍മഞ്ഞ്',
  dawu: 'നിബിഡമായ മൂടൽമഞ്ഞ്',
  teqiangnongwu: 'കടുത്ത മൂടൽമഞ്ഞ്',
  yu: 'മഴ',
  xue: 'മഞ്ഞ്',
  hoursForecast: 'കാലാവസ്ഥ',
  today: 'ഇന്ന്',
  tomorrow: 'നാളെ',
  monday: 'തിങ്കൾ',
  tuesday: 'ചൊവ്വ',
  wednesday: 'ബുധൻ',
  thursday: 'വ്യാഴം',
  friday: 'വെള്ളി',
  saturday: 'ശനി',
  sunday: 'ഞായർ',
  now: 'ഇപ്പോൾ',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'ഒരു നിമിഷം…',
  laodError: 'ഡാറ്റ ലോഡുചെയ്യാനായില്ല. വീണ്ടും ശ്രമിക്കാൻ റിഫ്രഷ് ചെയ്യുക.',
  refresh: 'റിഫ്രഷ് ചെയ്യുക',
  guessULike: 'നിങ്ങൾക്ക് ഇഷ്ടമായേക്കാം',
  setting: 'ക്രമീകരണം',
  changeHome: 'പ്രാരംഭ പേജ് മാറ്റുക',
  changeTitle:
    'നിങ്ങളുടെ ബ്രൗസർ എല്ലാം നിങ്ങളുടേതാക്കാൻ ഏത് സമയത്തും നിങ്ങളുടെ പ്രാരംഭ പേജ് മാറ്റുക.',
  changeTip1:
    '1. മുകളിൽ വലത് കോണിൽ, "കൂടുതൽ" ഐക്കൺ ടാപ്പുചെയ്‌ത് ക്രമീകരണത്തിലേക്ക് പോകുക.',
  changeTip2: '2. വിപുലമായ ക്രമീകരണത്തിൽ പ്രാരംഭ പേജ് തിരഞ്ഞെടുക്കുക.',
  changeTip3:
    '3. Chrome പ്രാരംഭ പേജ് തിരഞ്ഞെടുക്കുകയോ അത് ഇഷ്ടാനുസൃതമാക്കാൻ ഏതെങ്കിലും വെബ് പേജ് തിരഞ്ഞെടുക്കുകയോ ചെയ്യുക.',
  about: 'വിവരം',
  aboutFirst:
    'നിങ്ങളുടെ ബ്രൗസറിലെ ഒരു നാവിഗേഷൻ സേവനമാണ് homepage.miui.com. ഇത് കാലാവസ്ഥ, തിരയൽ, ദ്രുത ലിങ്കുകൾ, വാർത്തകൾ എന്നിവയും മറ്റും നൽകുന്നു.',
  aboutSecond:
    "നിങ്ങൾക്ക് എന്തെങ്കിലും ഫീഡ്‌ബാക്കോ നിർദ്ദേശങ്ങളോ ഉണ്ടെങ്കിൽ, homepagemiui{'@'}gmail.com എന്ന വിലാസത്തിൽ ഞങ്ങളെ അറിയിക്കാം. നിങ്ങൾക്ക് എപ്പോൾ വേണമെങ്കിലും ക്രമീകരണത്തിൽ ബ്രൗസർ പ്രാരംഭ പേജ് മാറ്റാം.",
  suggest: 'ഫീഡ്‌ബാക്ക്',
  suggestTip: 'ഫീഡ്‌ബാക്കും നിർദ്ദേശങ്ങളും ചേർക്കാൻ ടാപ്പുചെയ്യുക',
  mailTo: 'ഇമെയിൽ വിലാസം',
  send: 'സമർപ്പിക്കുക',
  sendSuccess: 'സമർപ്പിച്ചു',
  weGet: 'നിങ്ങളുടെ ഫീഡ്‌ബാക്ക് ഞങ്ങൾ എത്രയും വേഗം പ്രോസസ്സ് ചെയ്യും',
  ok: 'ശരി',
  newFuncTitle: 'പുതിയ ഫീച്ചർ',
  newFunc:
    'homepage.miui.com-ന് ഒരു പുതിയ പ്രാരംഭ പേജ് ഉണ്ട്. കാലാവസ്ഥയും വാർത്തകളും, തിരയലും മറ്റും ബ്രൗസ് ചെയ്യുക. നിങ്ങൾക്ക് പഴയ പ്രാരംഭ പേജിലേക്ക് മടങ്ങുകയോ ക്രമീകരണത്തിൽ വ്യക്തിഗതമാക്കുകയോ ചെയ്യാം.',
  get: 'മനസിലായി',
  searchHistory: 'തിരയൽ ചരിത്രം',
  cancel: 'റദ്ദാക്കുക',
  delete: 'മായ്ക്കുക',
  deleteHistoryDialogTitle: 'തിരയൽ ചരിത്രം മായ്ക്കുക',
  deleteHistoryDialogContent: 'ഇപ്പോൾ തിരയൽ ചരിത്രം ശാശ്വതമായി മായ്ക്കണോ?',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'കൂടുതൽ വായിക്കുക',
}
