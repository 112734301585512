export default {
  searchPlaceholder: 'Suchen oder URL eingeben',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'Wolkenlos',
  duoyun: 'Bewölkt',
  yin: 'Bedeckter Himmel',
  wu: 'Nebel',
  tedabaoyu: 'Heftiger Regenschauer',
  dabaoyu: 'Starker Regenschauer',
  baoyu: 'Regenschauer',
  leizhenyu: 'Gewitter',
  zhenyu: 'Regenschauer',
  dayu: 'Starker Regen',
  zhongyu: 'Mäßiger Regen',
  xiaoyu: 'Leichter Regen',
  yujiaxue: 'Graupel',
  baoxue: 'Schneesturm',
  zhenxue: 'Schneeschauer',
  daxue: 'Starker Schneefall',
  zhongxue: 'Mäßiger Schneefall',
  xiaoxue: 'Leichter Schneefall',
  qiangshachenbao: 'Starker Sandsturm',
  shachenbao: 'Sandsturm',
  shachen: 'Leichter Sandsturm',
  yangsha: 'Windig',
  bingbao: 'Hagel',
  fuchen: 'Staub',
  mai: 'Dunst',
  dongyu: 'Gefrierender Regen',
  xiaodaozhongyu: 'Leichter bis mäßiger Regen',
  zhongdaodayu: 'Mäßiger bis starker Regen',
  dadaobaoyu: 'Starker bis sintflutartiger Regen',
  baoyudaodabaoyu: 'Sintflut- bis wolkenbruchartiger Regen',
  dabaoyudaotedabaoyu: 'Wolkenbruch bis Regenunwetter',
  xiaodaozhongxue: 'Leichter bis mäßiger Schneefall',
  zhongdaodaxue: 'Mäßiger bis starker Schneefall',
  dadaobaoxue: 'Starker Schneefall bis Schneesturm',
  nongwu: 'Nebel',
  qiangnongwu: 'Dicker Nebel',
  middlemai: 'Mäßger Dunst',
  zhongdumai: 'Starker Dunst',
  yanzhongmai: 'Heftiger Nebel',
  dawu: 'Dichter Nebel',
  teqiangnongwu: 'Starker Nebel',
  yu: 'Regen',
  xue: 'Schnee',
  hoursForecast: 'Wetter',
  today: 'Heute',
  tomorrow: 'Morgen',
  monday: 'Mo',
  tuesday: 'Di',
  wednesday: 'Mi',
  thursday: 'Do',
  friday: 'Fr',
  saturday: 'Sa',
  sunday: 'So',
  now: 'Jetzt',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'Einen Moment…',
  laodError:
    'Daten konnten nicht geladen werden. Aktualisiere und versuche es erneut.',
  refresh: 'Aktualisieren',
  guessULike: 'Das könnte dir gefallen',
  setting: 'Einstellungen',
  changeHome: 'Startseite ändern',
  changeTitle:
    'Ändere deine Startseite jederzeit, um deinen Browser ganz auf dich einzustellen.',
  changeTip1:
    '1. Tippe oben rechts auf das „Mehr“-Symbol und gehe zu den Einstellungen.',
  changeTip2: '2. Wähle die Startseite unter „Erweiterte Einstellungen“.',
  changeTip3:
    '3. Wähle die Chrome-Startseite oder eine beliebige andere Webseite, um sie zu bearbeiten.',
  about: 'Über',
  aboutFirst:
    'homepage.miui.com ist ein Navigationsdienst in deinem Browser. Es bietet Wetterdaten, eine Suchfunktion, Schnelllinks, Nachrichten und mehr.',
  aboutSecond:
    "Wenn du Feedback oder Vorschläge hast, schreibe uns unter homepagemiui{'@'}gmail.com. Du kannst deine Browser-Startseite auch jederzeit in den Einstellungen ändern.",
  suggest: 'Feedback',
  suggestTip: 'Tippen, um Feedback und Vorschläge hinzuzufügen',
  mailTo: 'E-Mail-Adresse',
  send: 'Absenden',
  sendSuccess: 'Erfolgreich gesendet',
  weGet: 'Wir bearbeiten dein Feedback so schnell wie möglich',
  ok: 'OK',
  newFuncTitle: 'Neue Funktion',
  newFunc:
    'homepage.miui.com hat eine brandneue Startseite. Sieh dir Wetterdaten und Nachrichten, die Suche und mehr an. In den Einstellungen kannst du zur alten Startseite zurückkehren oder sie anpassen.',
  get: 'Verstanden',
  searchHistory: 'Suchverlauf',
  cancel: 'Abbrechen',
  delete: 'Löschen',
  deleteHistoryDialogTitle: 'Suchverlauf löschen',
  deleteHistoryDialogContent: 'Suchverlauf jetzt endgültig löschen?',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'Weiterlesen',
}
