export default {
  searchPlaceholder: 'Busca o introduce una URL',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Juegos',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'coche corriente',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'Hurdle Rush',
  siteDefaultGame3: 'Elastic Car',
  siteDefaultGame4: 'Squid Game',
  siteDefaultGame5: 'House Paint Puzzle',
  qing: 'Despejado',
  duoyun: 'Nublado',
  yin: 'Cubierto',
  wu: 'Neblina',
  tedabaoyu: 'Tormenta severa',
  dabaoyu: 'Tormenta fuerte',
  baoyu: 'Tormenta',
  leizhenyu: 'Tormenta eléctrica',
  zhenyu: 'Chubascos',
  dayu: 'Lluvia intensa',
  zhongyu: 'Lluvia moderada',
  xiaoyu: 'Lluvia ligera',
  yujiaxue: 'Aguanieve',
  baoxue: 'Ventisca',
  zhenxue: 'Chubascos con nieve',
  daxue: 'Nieve intensa',
  zhongxue: 'Nieve moderada',
  xiaoxue: 'Nieve ligera',
  qiangshachenbao: 'Fuerte tormenta de arena',
  shachenbao: 'Tormenta de arena',
  shachen: 'Ligera tormenta de arena',
  yangsha: 'Con viento',
  bingbao: 'Granizo',
  fuchen: 'Polvo',
  mai: 'Neblina',
  dongyu: 'Lluvia helada',
  xiaodaozhongyu: 'De lluvia ligera a moderada',
  zhongdaodayu: 'De lluvia moderada a fuerte',
  dadaobaoyu: 'De lluvia intensa a torrencial',
  baoyudaodabaoyu: 'De lluvia torrencial a aguacero',
  dabaoyudaotedabaoyu: 'De aguacero a tormenta extrema',
  xiaodaozhongxue: 'De nieve ligera a moderada',
  zhongdaodaxue: 'De nieve moderada a intensa',
  dadaobaoxue: 'De nieve intensa a ventisca',
  nongwu: 'Niebla',
  qiangnongwu: 'Niebla espesa',
  middlemai: 'Neblina moderada',
  zhongdumai: 'Neblina intensa',
  yanzhongmai: 'Neblina severa',
  dawu: 'Niebla densa',
  teqiangnongwu: 'Niebla severa',
  yu: 'Lluvia',
  xue: 'Nieve',
  hoursForecast: 'Tiempo',
  today: 'Hoy',
  tomorrow: 'Mañana',
  monday: 'Lun.',
  tuesday: 'Mar.',
  wednesday: 'Mié.',
  thursday: 'Jue.',
  friday: 'Vie.',
  saturday: 'Sáb.',
  sunday: 'Dom.',
  now: 'Ahora',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'Un momento…',
  laodError: 'No se pudo cargar los datos. Actualiza y vuelve a intentarlo.',
  refresh: 'Actualizar',
  guessULike: 'Podría gustarte',
  setting: 'Ajustes',
  changeHome: 'Cambiar Página de inicio',
  changeTitle:
    'Cambia tu Página de inicio en cualquier momento para que tu navegador solo tuyo.',
  changeTip1:
    '1. En la esquina superior derecha, pulsa el icono "Más" y ve a Ajustes.',
  changeTip2: '2. Selecciona Página de inicio en Ajustes avanzados.',
  changeTip3:
    '3. Selecciona la página de inicio de Chrome o elige cualquier página web para personalizarla.',
  about: 'Acerca de',
  aboutFirst:
    'homepage.miui.com es un servicio de navegación en tu navegador. Proporciona información meteorológica, de búsqueda, enlaces rápidos, noticias y mucho más.',
  aboutSecond:
    "Si tienes algún comentario o sugerencia, no dudes en enviarnos un mensaje a homepagemiui{'@'}gmail.com. También puedes cambiar la página de inicio de tu navegador en cualquier momento en Ajustes.",
  suggest: 'Comentarios',
  suggestTip: 'Pulsa para añadir comentarios y sugerencias',
  mailTo: 'Dirección de correo electrónico',
  send: 'Enviar',
  sendSuccess: 'Enviado con éxito',
  weGet: 'Procesaremos tus comentarios lo antes posible',
  ok: 'OK',
  newFuncTitle: 'Nueva función',
  newFunc:
    'homepage.miui.com tiene una nueva Página de inicio. Descubre el tiempo y noticias, navega y mucho más. Puedes volver a la Página de inicio anterior o personalizarla en Ajustes.',
  get: 'Entendido',
  searchHistory: 'Historial de búsqueda',
  cancel: 'Cancelar',
  delete: 'Eliminar',
  deleteHistoryDialogTitle: 'Eliminar historial de búsqueda',
  deleteHistoryDialogContent:
    '¿Eliminar ahora permanentemente el historial de búsqueda?',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'Leer más',
}
