export default {
  searchPlaceholder: 'Cari atau masukkan URL',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'MSN',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'Cerah',
  duoyun: 'Berawan',
  yin: 'Mendung',
  wu: 'Kabus',
  tedabaoyu: 'Hujan lebat yang teruk',
  dabaoyu: 'Ribut hujan lebat',
  baoyu: 'Hujan ribut',
  leizhenyu: 'Ribut petir',
  zhenyu: 'Hujan renyai',
  dayu: 'Hujan lebat',
  zhongyu: 'Hujan sederhana',
  xiaoyu: 'Hujan renyai',
  yujiaxue: 'Hujan ais',
  baoxue: 'Ribut salji',
  zhenxue: 'Hujan salji',
  daxue: 'Salji lebat',
  zhongxue: 'Salji sederhana',
  xiaoxue: 'Salji ringan',
  qiangshachenbao: 'Ribut pasir yang kuat',
  shachenbao: 'Ribut pasir',
  shachen: 'Ribut pasir yang ringan',
  yangsha: 'Berangin',
  bingbao: 'Hujan batu',
  fuchen: 'Berhabuk',
  mai: 'Jerebu',
  dongyu: 'Hujan beku',
  xiaodaozhongyu: 'Hujan ringan hingga sederhana',
  zhongdaodayu: 'Hujan sederhana hingga deras',
  dadaobaoyu: 'Hujan deras hingga amat deras',
  baoyudaodabaoyu: 'Hujan amat deras hingga lebat',
  dabaoyudaotedabaoyu: 'Hujan lebat hingga ribut hujan yang ekstrem',
  xiaodaozhongxue: 'Salji ringan hingga sederhana',
  zhongdaodaxue: 'Salji sederhana hingga deras',
  dadaobaoxue: 'Salji lebat hingga ribut salji',
  nongwu: 'Kabus',
  qiangnongwu: 'Kabus tebal',
  middlemai: 'Jerebu sederhana',
  zhongdumai: 'Jerebu tebal',
  yanzhongmai: 'Jerebu teruk',
  dawu: 'Kabus tebal',
  teqiangnongwu: 'Kabus merbahaya',
  yu: 'Hujan',
  xue: 'Salji',
  hoursForecast: 'Cuaca',
  today: 'Hari Ini',
  tomorrow: 'Esok',
  monday: 'Isn',
  tuesday: 'Sel',
  wednesday: 'Rab',
  thursday: 'Kha',
  friday: 'Jum',
  saturday: 'Sab',
  sunday: 'Ahd',
  now: 'Sekarang',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'Sebentar…',
  laodError: 'Tidak dapat memuatkan data. Muat semula untuk mencuba lagi.',
  refresh: 'Muat semula',
  guessULike: 'Anda mungkin suka',
  setting: 'Tetapan',
  changeHome: 'Tukar Laman Mula',
  changeTitle:
    'Tukar Laman Mula anda pada bila-bila masa untuk menjadikan pelayar anda milik anda.',
  changeTip1:
    '1. Di penjuru kanan sebelah atas, klik ikon "Lagi" dan pergi ke Tetapan.',
  changeTip2: '2. Pilih Laman Mula dalam Tetapan lanjutan.',
  changeTip3:
    '3. Pilih Laman Mula Chrome atau pilih mana-mana laman web untuk menyesuaikannya.',
  about: 'Mengenai',
  aboutFirst:
    'homepage.miui.com ialah perkhidmatan navigasi dalam pelayar anda. Ia menyediakan cuaca, carian, pautan pantas, berita dan banyak lagi.',
  aboutSecond:
    "Jika anda mempunyai sebarang maklum balas atau cadangan, sila hubungi kami di homepagemiui{'@'}gmail.com. Anda juga boleh menukar Laman Mula pelayar anda pada bila-bila masa dalam Tetapan.",
  suggest: 'Maklum balas',
  suggestTip: 'Klik untuk menambah maklum balas dan cadangan',
  mailTo: 'Alamat E-mel',
  send: 'Hantar',
  sendSuccess: 'Berjaya dihantar',
  weGet: 'Kami akan memproses maklum balas anda secepat mungkin',
  ok: 'Ok',
  newFuncTitle: 'Ciri baru',
  newFunc:
    'homepage.miui.com mempunyai Laman Mula yang serba baharu. Layari cuaca dan berita, carian dan banyak lagi. Anda boleh bertukar kembali ke Laman Mula yang lama atau memperibadikannya dalam Tetapan.',
  get: 'Ok faham',
  searchHistory: 'Sejarah carian',
  cancel: 'Batal',
  delete: 'Padam',
  deleteHistoryDialogTitle: 'Padam sejarah carian',
  deleteHistoryDialogContent: 'Padamkan sejarah carian secara kekal sekarang?',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'Baca lebih lanjut',
}
