export default {
  searchPlaceholder: 'खोज्नुहोस् वा URL प्रविष्ट गर्नुहोस्',
  siteGoogle: 'Google',
  siteGlobo: 'globo',
  siteYahoo: 'Yahoo',
  siteUol: 'UOL',
  siteWeather: 'weather',
  siteWiki: 'wiki',
  siteMsn: 'Msn',
  siteGames: 'Games',
  siteGmail: 'Gmail',
  siteCaixa: 'caixa',
  siteAmazon: 'Amazon',
  siteYoutube: 'Youtube',
  siteNetflix: 'Netflix',
  siteTiktok: 'Tiktok',
  siteFacebook: 'Facebook',
  siteTwitter: 'Twitter',
  siteInstagram: 'Instagram',
  siteElasticCar: 'Elastic Car',
  siteSquidGame: 'Squid game',
  siteColorFill: 'Color fill',
  siteYandex: 'Yandex',
  siteDzen: 'dzen',
  siteVK: 'VK',
  siteMail: 'Mail.ru',
  siteOK: 'ok.ru',
  siteOzon: 'ozon.ru',
  siteAvito: 'avito.ru',
  siteYa: 'ya.ru',
  mvpVideoDW: 'Download videos',
  mvpGames: 'Games',
  mvpSports: 'Sports',
  siteLazada: 'lazada',
  siteYandexSearch: 'Yandex',
  hotNews: 'Headlines',
  siteGame1: 'siteGame1',
  siteGame2: 'siteGame2',
  siteGame3: 'siteGame3',
  siteGame4: 'siteGame4',
  siteGame5: 'siteGame5',
  siteGameCar: 'Running Car',
  siteDefaultGame1: 'Fruit Killer',
  siteDefaultGame2: 'rush',
  siteDefaultGame3: 'Elastic Car',
  siteDefaultGame4: 'Squid',
  siteDefaultGame5: 'paint',
  qing: 'घमाइलो',
  duoyun: 'बादल लागेको',
  yin: 'बादल लागेको',
  wu: 'कुहिरो',
  tedabaoyu: 'तेज तूफान',
  dabaoyu: 'भारी वर्षा रआँधी',
  baoyu: 'तूफानी वर्षा',
  leizhenyu: 'चट्याङ् पर्दै',
  zhenyu: 'हल्का वर्षा',
  dayu: 'भारी बर्षा',
  zhongyu: 'मध्यम वर्षा',
  xiaoyu: 'हल्का वर्षा',
  yujiaxue: 'असिना सहितको बर्षा',
  baoxue: 'सिरेटो',
  zhenxue: 'हिमपात र वर्षा',
  daxue: 'भारी हिमपात',
  zhongxue: 'सामान्य हिमपात',
  xiaoxue: 'हल्का हिमपात',
  qiangshachenbao: 'धुलोसहितको तेज तूफान',
  shachenbao: 'धुलो सहितको तूफान',
  shachen: 'हलुका धुलो सहितको तूफान',
  yangsha: 'हावा चलेको',
  bingbao: 'असिना',
  fuchen: 'धुलो',
  mai: 'कुहिरो',
  dongyu: 'कठ्याँग्रिने वर्षा',
  xiaodaozhongyu: 'हल्का देखि सामान्य वर्षा',
  zhongdaodayu: 'सामान्यदेखि भारी वर्षा',
  dadaobaoyu: 'भारीदेखि मुसलधारे वर्षा',
  baoyudaodabaoyu: 'मुसलधारेदेखि एकदमै भारी वर्षा',
  dabaoyudaotedabaoyu: 'एकदमै भारी वर्षादेखि आँधीवर्षा',
  xiaodaozhongxue: 'हल्कादेखि सामान्य हिमपात',
  zhongdaodaxue: 'सामान्यदेखि भारी हिमपात',
  dadaobaoxue: 'भारी हिमपातदेखि अझ भारी हिमपात',
  nongwu: 'कुहिरो',
  qiangnongwu: 'घना तुवालो',
  middlemai: 'मध्यम तुवालो',
  zhongdumai: 'भारी तुवालो',
  yanzhongmai: 'एकदमै भारी तुवालो',
  dawu: 'घना कुहिर',
  teqiangnongwu: 'एकदमै घना कुहिरो',
  yu: 'वर्षा',
  xue: 'हिउँ',
  hoursForecast: 'मौसम',
  today: 'आज',
  tomorrow: 'भोली',
  monday: 'सोम',
  tuesday: 'मंगल',
  wednesday: 'बुध',
  thursday: 'बिहि',
  friday: 'शुक्र',
  saturday: 'शनि',
  sunday: 'आइत',
  now: 'अहिले',
  siteMarca: 'marca.com',
  siteElmundo: 'elmundo.es',
  siteDefaultGame6: 'RunSausageRun',
  siteDefaultGame8: 'Funny Road',
  siteDefaultGame9: 'Shots',
  siteDefaultGame10: 'Parking Block',
  siteDefaultGame11: 'Color Eggs',
  siteDefaultGame12: 'CraryFoot',
  siteDefaultGame13: 'Homescapes',
  siteBBC: 'BBC',
  siteEbay: 'Ebay',
  siteOrange: 'Orange',
  siteLeboncoin: 'Leboncoin',
  sitetonLine: 't-online',
  siteDiretta: 'Diretta',
  siteAnsa: 'Ansa',
  siteAajTak: 'Aaj Tak',
  siteCricbuzz: 'Cricbuzz',
  siteCarRaceMaster: 'Car Race Master',
  siteTyphoonKiller: 'Typhoon Killer',
  siteAs: 'As.com',
  site20Minustos: '20minutos',
  siteElespanol: 'Elespanol.com',
  siteMundo: 'Mundodeportivo.com',
  siteSports: 'Sports.es',
  siteOkdiario: 'Okdiario.com',
  siteLefigaro: 'Lefigaro.fr',
  siteLeparisien: 'Leparisien.fr',
  siteFree: 'Free.fr',
  siteVoici: 'Voici.fr',
  siteGala: 'Gala.fr',
  siteEcosia: 'Ecosia.org',
  siteOtto: 'Otto.de',
  siteZdf: 'Zdf.de',
  siteKicker: 'Kicker.de',
  siteIdealo: 'Idealo.de',
  siteGazzetta: 'Gazzetta.it',
  siteMediasetinfinity: 'Mediasetinfinity',
  siteSky: 'Sky.it',
  siteFanpage: 'Fanpage.it',
  sitePoste: 'Poste.it',
  siteGov: 'Gov.uk',
  siteSkyGB: 'Sky.com',
  siteItv: 'Itv.com',
  siteThesun: 'Thesun',
  siteImdb: 'Imdb.com',
  siteService: 'Service-public',
  siteChip: 'Chip.de',
  siteFandom: 'Fandom',
  siteVirgilio: 'Virgilio',
  siteMiWeather: 'Weather',
  siteReddit: 'Reddit',
  laoding: 'एक क्षण पर्खनुहोस्…',
  laodError: 'डाटा लोड गर्न सकेन। फेरि प्रयास गर्न ताजा गर्नुहोस्',
  refresh: 'ताजा गर्नुहोस्',
  guessULike: 'तपाईंलाई मनपर्न सक्छ',
  setting: 'सेटिंग्स',
  changeHome: 'सुरुवात पृष्ठ परिवर्तन गर्नुहोस्',
  changeTitle:
    'तपाइँको ब्राउजर तपाईँको बनाउनका लागि तपाईँको सुरु पृष्ठ कुनै पनि समय परिवर्तन गर्नुहोस्।',
  changeTip1:
    '1. शीर्ष दायाँ कुनामा, "थप" प्रतिमा ट्याप गर्नुहोस् र सेटिंग्समा जानुहोस्।',
  changeTip2: '2. उन्नत सेटिंग्समा सुरु पृष्ठ चयन गर्नुहोस्।',
  changeTip3:
    '3. Chrome सुरु पृष्ठ चयन गर्नुहोस्, वा यसलाई अनुकूलन गर्न कुनै पनि वेब पृष्ठ छान्नुहोस्।',
  about: 'बारेमा',
  aboutFirst:
    'homepage.miui.com तपाईंको ब्राउजरमा नेभिगेसन सेवा हो। यसले मौसम, खोज, द्रुत लिङ्क, समाचार, र थप प्रदान गर्दछ।',
  aboutSecond:
    "यदि तपाईंसँग कुनै प्रतिक्रिया वा सुझावहरू छन् भने, हामीलाई homepagemiui{'@'}gmail.com मा एक लाइन छोड्न नहिचकिचाउनुहोस्। तपाईं सेटिंग्समा कुनै पनि समय आफ्नो ब्राउजर सुरु पृष्ठ परिवर्तन गर्न सक्नुहुन्छ।",
  suggest: 'प्रतिक्रिया',
  suggestTip: 'प्रतिक्रिया र सुझावहरू थप्न ट्याप गर्नुहोस्',
  mailTo: 'इमेल ठेगाना',
  send: 'पेश गर्नुहोस्',
  sendSuccess: 'सफलतापूर्वक पेस गरियो',
  weGet: 'हामी तपाईंको प्रतिक्रियालाई सकेसम्म चाँडो प्रशोधन गर्नेछौं',
  ok: 'ठिक छ',
  newFuncTitle: 'नयाँ फिचर',
  newFunc:
    'homepage.miui.com सँग एकदम नयाँ सुरु पृष्ठ छ। मौसम र समाचार, खोज, र थप ब्राउज गर्नुहोस्। तपाईं पुरानो सुरु पृष्ठमा फर्कन सक्नुहुन्छ वा सेटिंग्समा यसलाई निजीकृत गर्न सक्नुहुन्छ।',
  get: 'बुझें',
  searchHistory: 'खोज इतिहास',
  cancel: 'रद्द गर्नुहोस्',
  delete: 'मेटाउनुहोस्',
  deleteHistoryDialogTitle: 'खोज इतिहास मेटाउनुहोस्',
  deleteHistoryDialogContent: 'खोज इतिहास अब स्थायी रूपमा मेटाउने हो?',
  siteCooking: 'Cooking',
  siteFashion: 'Fashion',
  siteFunny: 'Funny',
  siteTravel: 'Travel',
  siteQuiz: 'Quiz',
  readMore: 'थप पढ्नुहोस्',
}
